export const environment = {
    production: false,
    apiUrl: 'https://imo-crm.karyaoptima.com/api/cms',
    assetUrl: 'https://imo-crm.karyaoptima.com/uploads/', //Always add slash at end of the line
    appName: '_imc.dev',
    appKey: '8YHksYi5rHZgXOwN4qjLrCydnhvQLEAe',
    firebase: {
        apiKey: "AIzaSyBwK0pdzQCOnpWrYroyo3YAQA1dpeKgR_Q",
        authDomain: "imo-crm-bd134.firebaseapp.com",
        projectId: "imo-crm-bd134",
        storageBucket: "imo-crm-bd134.appspot.com",
        messagingSenderId: "1016394216189",
        appId: "1:1016394216189:web:5c18c25a9397612e9c0494",
        vapidKey: 'BO8TRxd2w8p1FqwExneFVUSVxKJbhKI1V3CnIbqcYXDEButUgO9Z-jBfLYQQ_RDjiAeH9ouQognGrwpPJzdv5K8'
    }
}
