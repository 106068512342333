import { Location } from '@angular/common';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, NgForm } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject } from 'rxjs';
import { DatePickerParserService } from 'src/app/services/date-picker-parser.service';
import { HttpService } from 'src/app/services/http.service';
import { LoadingService } from 'src/app/services/loading.service';

@Component({
    selector: 'app-pr-vendor-add',
    templateUrl: './pr-vendor-add.component.html',
    styleUrls: ['./pr-vendor-add.component.scss']
})
export class PrVendorAddComponent implements OnInit {

    constructor(
        private http: HttpService,
        private el: ElementRef,
        private location: Location,
        public loading: LoadingService,
        private dateParse: DatePickerParserService,
        private toastr: ToastrService) { }
    @ViewChild('theForm') theForm: NgForm | undefined;
    private apiPath = 'pr-vendors';
    stayPage: boolean = false;
    formGroup = new FormGroup({
        contract_id: new FormControl(),
        purchase_order_id: new FormControl(),
        po_site_id: new FormControl(),
        requested_at: new FormControl(),
        notes: new FormControl()
    });
    formChanged = false;
    contractList: any[] = [];
    poList: any[] = [];
    siteList: any[] = [];
    details: any[] = [];
    vendorList = new BehaviorSubject<any[]>([]);

    submitForm(form: any): void {
        if (!form.valid) {
            this.el.nativeElement.querySelectorAll('[formcontrolname].ng-invalid')?.[0]?.scrollIntoView({ behavior: "smooth", block: "center" });
            return;
        }
        if (!this.details.length) {
            this.toastr.error("Please select the Site that have SoW", 'Select Site');
            return;
        }
        const emptied = this.details.filter((e: any) => !e.vendor_id);
        if (emptied.length) {
            this.toastr.error("Please select Vendor Name", 'Select Vendor');
            this.el.nativeElement.querySelectorAll('#vendor-' + emptied[0].id + ' input[type="text"]')?.[0]?.focus();
            return;
        }
        form.value.requested_at = this.dateParse.toYMD(form.value.requested_at);
        form.value.details = this.details.map((e: any) => {
            return {
                vendor_boq_id: e.vendor_boq_id,
                qty: e.qty,
                vendor_id: e.vendor_id,
                remaining_qty: 0
            }
        });
        let fdata = new FormData();
        fdata.append('data', JSON.stringify(form.value));
        form.disable();
        this.http.Post(this.apiPath + '/create', fdata).then((r: any) => {
            form.enable();
            if (r.success) {
                this.toastr.success('Data saved successfully', 'Success');
                this.back();
            } else {
                if (r.response && r.response.wrong) {
                    Object.keys(r.response.wrong).forEach((key) => {
                        if (key != 'id') {
                            form.get(key)?.setErrors({ serverError: r.response.wrong[key][0] });
                            this.el.nativeElement.querySelectorAll('[formcontrolname="' + key + '"]')?.[0]?.focus();
                        }
                    });
                }
            }
        });
    }

    back(): void {
        this.location.back();
    }

    async getContract(): Promise<void> {
        const r = await this.http.Get('contracts', { filter: { status: 1 }, forceView: true });
        this.contractList = r.response?.result?.data || [];
    }

    async getPO(): Promise<void> {
        const contract_id = this.formGroup.get('contract_id')?.value;
        if (!contract_id) {
            this.formGroup.get('purchase_order_id')?.reset();
            this.formGroup.get('po_site_id')?.reset();
        }
        const r = await this.http.Get('purchase-orders', { filter: { contract_id: contract_id, status: 1 }, vendor_boq: 1, forceView: true });
        this.poList = r.response?.result?.data || [];
    }

    async getSite(): Promise<void> {
        const po_id = this.formGroup.get('purchase_order_id')?.value;
        if (!po_id) {
            this.formGroup.get('po_site_id')?.reset();
        }
        const r = await this.http.Get('sites/' + po_id, { forceView: true });
        this.siteList = r.response?.result?.data || [];
    }

    async getVendor(vendor_boq: any[]): Promise<void> {
        this.vendorList.next([]);
        const r = await this.http.Get('vendor-prices/' + this.formGroup.get('purchase_order_id')?.value, { filter: { status: 1 }, type: 'vendors', has_vendor_boq: vendor_boq.join(','), forceView: true });
        this.vendorList.next(r.response?.result?.data || []);
    }

    async setVendorBoq(): Promise<void> {
        const po_id = this.formGroup.get('purchase_order_id')?.value;
        const site_id = this.formGroup.get('po_site_id')?.value;
        this.details = [];
        if (!po_id || !site_id) {
            return;
        }
        const r = await this.http.Get('vendor-boq/' + po_id + '/' + site_id, { forceView: true });
        this.details = (r.response?.result?.data || [])?.map((e: any) => {
            return {
                vendor_boq_id: e.id,
                sow_name: e.sow_name,
                qty: e.qty,
                uom: e.uom,
                vendor_id: null,
                vendor_price: null
            }
        });
        if (!this.details.length) {
            this.toastr.error("This Site is doesn't have SoW", 'Select Site');
        }
    }

    setVendor(item: any): void {
        if (!item.vendor_id) {
            return;
        }
        const vendor = (this.vendorList.getValue()).find((e: any) => e.vendor_id == item.vendor_id) || {};
        if (!vendor.id) {
            this.toastr.error("This Vendor is doesn't have price for SoW : " + item.sow_name, 'Select another Vendor');
            item.vendor_id = null;
            return;
        }
        item.vendor_price = vendor.details?.[0]?.price;
    }

    ngOnInit(): void {
        this.getContract();
        const oldValue = this.formGroup.value;
        this.formGroup.valueChanges.subscribe((e: any) => {
            this.formChanged = (oldValue != e);
        });
    }

}
