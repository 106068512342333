<div class="row mb-4">
    <div class="col-sm-12 mx-auto">
        <div class="card">
            <div class="card-body">
                <div class="row">
                    <h4 class="card-title col-12 mb-4">Add Purchase Order</h4>
                    <div class="col-md-12">
                        <div class="d-flex justify-content-center mb-4">
                            <div class="text-center d-flex justify-content-center flex-wrap step-container" [ngClass]="{'active': formStep >= 0}">
                                <div style="width: 32px;height: 32px;cursor: pointer;" (click)="changeStep(0)" class="rounded-circle text-white d-flex justify-content-center align-items-center icon-step">
                                    <span class="material-icons fs-5">adjust</span>
                                </div>
                                <div class="w-100 small">
                                    PO Information
                                </div>
                            </div>
                            <div class="text-center d-flex justify-content-center flex-wrap step-container" [ngClass]="{'active': formStep > 0}">
                                <div style="width: 32px;height: 32px;cursor: pointer;" (click)="changeStep(1)" class="rounded-circle text-white d-flex justify-content-center align-items-center icon-step">
                                    <span class="material-icons fs-5">adjust</span>
                                </div>
                                <div class="w-100 small">
                                    Customer BOQ
                                </div>
                            </div>
                            <div class="text-center d-flex justify-content-center flex-wrap step-container" [ngClass]="{'active': formStep > 1}">
                                <div style="width: 32px;height: 32px;cursor: pointer;" (click)="changeStep(2)" class="rounded-circle text-white d-flex justify-content-center align-items-center icon-step">
                                    <span class="material-icons fs-5">adjust</span>
                                </div>
                                <div class="w-100 small">
                                    Materials
                                </div>
                            </div>
                            <div class="text-center d-flex justify-content-center flex-wrap step-container" [ngClass]="{'active': formStep > 2}">
                                <div style="width: 32px;height: 32px;cursor: pointer;" (click)="changeStep(3)" class="rounded-circle text-white d-flex justify-content-center align-items-center icon-step">
                                    <span class="material-icons fs-5">adjust</span>
                                </div>
                                <div class="w-100 small">
                                    Vendor BOQ
                                </div>
                            </div>
                            <div class="text-center d-flex justify-content-center flex-wrap step-container" [ngClass]="{'active': formStep > 3}">
                                <div style="width: 32px;height: 32px;cursor: pointer;" (click)="changeStep(4)" class="rounded-circle text-white d-flex justify-content-center align-items-center icon-step">
                                    <span class="material-icons fs-5">adjust</span>
                                </div>
                                <div class="w-100 small">
                                    Milestone Customer
                                </div>
                            </div>
                            <div class="text-center d-flex justify-content-center flex-wrap step-container" [ngClass]="{'active': formStep > 4}">
                                <div style="width: 32px;height: 32px;cursor: pointer;" (click)="changeStep(5)" class="rounded-circle text-white d-flex justify-content-center align-items-center icon-step">
                                    <span class="material-icons fs-5">adjust</span>
                                </div>
                                <div class="w-100 small">
                                    Milestone Vendor
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-12">
                        <form #theForm="ngForm" (ngSubmit)="submitForm()" novalidate class="row">
                            <div class="col-12 fg-0" [class.d-none]="formStep!=0" [formGroup]="formGroup[0]">
                                <div class="h5 mb-3">PO Information</div>
                                <div class="form-floating mb-4">
                                    <ng-select class="form-control" placeholder="-" formControlName="customer_id" (ngModelChange)="getContract()" loadingText="Loading..." [class.is-invalid]="formGroup[0].get('customer_id')?.invalid && (theForm.submitted || formGroup[0].get('customer_id')?.touched)" required>
                                        <ng-option *ngFor="let item of customerList" [value]="item.id">{{item.name}}</ng-option>
                                    </ng-select>
                                    <label class="required">Customer</label>
                                    <div class="text-danger position-absolute text-end w-100" *ngIf="formGroup[0].get('customer_id')?.invalid && (theForm.submitted || formGroup[0].get('customer_id')?.touched)">
                                        <small *ngIf="formGroup[0].get('customer_id')?.errors?.required">Please select customer</small>
                                        <small *ngIf="formGroup[0].get('customer_id')?.errors?.serverError">{{ formGroup[0].get("customer_id")?.errors?.serverError }}</small>
                                    </div>
                                </div>
                                <div class="form-floating mb-4">
                                    <ng-select class="form-control" placeholder="-" formControlName="contract_id" loadingText="Loading..." [class.is-invalid]="formGroup[0].get('contract_id')?.invalid && (theForm.submitted || formGroup[0].get('contract_id')?.touched)" required>
                                        <ng-option *ngFor="let item of contractList" [value]="item.id">
                                            {{item.contract_number}} - {{item.customer?.name}} / {{item.project_name}}
                                        </ng-option>
                                    </ng-select>
                                    <label class="required">Contract</label>
                                    <div class="text-danger position-absolute text-end w-100" *ngIf="formGroup[0].get('contract_id')?.invalid && (theForm.submitted || formGroup[0].get('contract_id')?.touched)">
                                        <small *ngIf="formGroup[0].get('contract_id')?.errors?.required">Please select contract</small>
                                        <small *ngIf="formGroup[0].get('contract_id')?.errors?.serverError">{{ formGroup[0].get("contract_id")?.errors?.serverError }}</small>
                                    </div>
                                </div>
                                <div class="form-floating mb-4">
                                    <input type="text" formControlName="number" [class.is-invalid]="formGroup[0].get('number')?.invalid && (theForm.submitted || formGroup[0].get('number')?.touched)" placeholder="..." class="form-control" required>
                                    <label>PO Number</label>
                                    <div class="text-danger position-absolute text-end w-100" *ngIf="formGroup[0].get('number')?.invalid && (theForm.submitted || formGroup[0].get('number')?.touched)">
                                        <small *ngIf="formGroup[0].get('number')?.errors?.required">Please type number</small>
                                        <small *ngIf="formGroup[0].get('number')?.errors?.serverError">{{ formGroup[0].get("number")?.errors?.serverError }}</small>
                                    </div>
                                </div>
                                <div class="form-check form-switch mb-4">
                                    <input class="form-check-input" type="checkbox" formControlName="status" id="status" />
                                    <label class="form-check-label" for="status">Status : <strong>{{ formGroup[0].get("status")?.value ? "Active" : "Inactive" }}</strong></label>
                                </div>
                            </div>
                            <div class="col-12 fg-1" [class.d-none]="formStep!=1" [formGroup]="formGroup[1]">
                                <div class="h5 mb-3">Customer BOQ</div>
                                <table class="table">
                                    <thead>
                                        <tr>
                                            <th class="text-center">#</th>
                                            <th style="width:30%;">SoW</th>
                                            <th>Price</th>
                                            <th style="width:15%">UoM</th>
                                            <th>Qty</th>
                                            <th>Notes</th>
                                            <th class="text-center">&times;</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <ng-container formArrayName="customer_boqs">
                                            <tr *ngFor="let customer_boq of customerBoqs.get().controls as FormArray;index as i;first as isFirst;last as isLast;" [formGroupName]="i">
                                                <td class="text-center">
                                                    {{i+1}}
                                                </td>
                                                <td>
                                                    <input type="text" formControlName="sow_name" [class.is-invalid]="customer_boq.get('sow_name')?.invalid && (theForm.submitted || customer_boq.get('sow_name')?.touched)" class="form-control my-2" required>
                                                    <div class="text-danger position-absolute" style="margin-top: -11px;" *ngIf="customer_boq.get('sow_name')?.invalid && (theForm.submitted || customer_boq.get('sow_name')?.touched)">
                                                        <small *ngIf="customer_boq.get('sow_name')?.errors?.required">Please type SoW</small>
                                                        <small *ngIf="customer_boq.get('sow_name')?.errors?.serverError">{{ customer_boq.get("sow_name")?.errors?.serverError }}</small>
                                                    </div>
                                                </td>
                                                <td>
                                                    <input type="text" ngxNumberFormat="###,###,###,###,###" formControlName="price" [class.is-invalid]="customer_boq.get('price')?.invalid && (theForm.submitted || customer_boq.get('price')?.touched)" class="form-control my-2" required>
                                                    <div class="text-danger position-absolute" style="margin-top: -11px;" *ngIf="customer_boq.get('price')?.invalid && (theForm.submitted || customer_boq.get('price')?.touched)">
                                                        <small *ngIf="customer_boq.get('price')?.errors?.required">Please type price</small>
                                                        <small *ngIf="customer_boq.get('price')?.errors?.serverError">{{ customer_boq.get("price")?.errors?.serverError }}</small>
                                                    </div>
                                                </td>
                                                <td>
                                                    <ng-select class="form-control my-2" [clearable]="false" formControlName="uom" loadingText="Loading..." [class.is-invalid]="customer_boq.get('uom')?.invalid && (theForm.submitted || customer_boq.get('uom')?.touched)" required>
                                                        <ng-option *ngFor="let item of (uomList | async)" [value]="item">{{item}}</ng-option>
                                                    </ng-select>
                                                    <div class="text-danger position-absolute" style="margin-top: -11px;" *ngIf="customer_boq.get('uom')?.invalid && (theForm.submitted || customer_boq.get('uom')?.touched)">
                                                        <small *ngIf="customer_boq.get('uom')?.errors?.required">Please select uom</small>
                                                        <small *ngIf="customer_boq.get('uom')?.errors?.serverError">{{ customer_boq.get("uom")?.errors?.serverError }}</small>
                                                    </div>
                                                </td>
                                                <td>
                                                    <input type="text" ngxNumberFormat="###,###,###,###,###" formControlName="qty" [class.is-invalid]="customer_boq.get('qty')?.invalid && (theForm.submitted || customer_boq.get('qty')?.touched)" class="form-control my-2" required>
                                                    <div class="text-danger position-absolute" style="margin-top: -11px;" *ngIf="customer_boq.get('qty')?.invalid && (theForm.submitted || customer_boq.get('qty')?.touched)">
                                                        <small *ngIf="customer_boq.get('qty')?.errors?.required">Please type qty</small>
                                                        <small *ngIf="customer_boq.get('qty')?.errors?.serverError">{{ customer_boq.get("qty")?.errors?.serverError }}</small>
                                                    </div>
                                                </td>
                                                <td>
                                                    <input type="text" formControlName="notes" [class.is-invalid]="customer_boq.get('notes')?.invalid && (theForm.submitted || customer_boq.get('notes')?.touched)" class="form-control my-2">
                                                    <div class="text-danger position-absolute" style="margin-top: -11px;" *ngIf="customer_boq.get('notes')?.invalid && (theForm.submitted || customer_boq.get('notes')?.touched)">
                                                        <small *ngIf="customer_boq.get('qty')?.errors?.serverError">{{ customer_boq.get("notes")?.errors?.serverError }}</small>
                                                    </div>
                                                </td>
                                                <td class="text-center">
                                                    <button type="button" (click)="customerBoqs.remove(i);" class="btn btn-sm btn-danger my-2">
                                                        <i class="material-icons">close</i>
                                                    </button>
                                                </td>
                                            </tr>
                                        </ng-container>
                                    </tbody>
                                </table>
                                <div class="mb-4">
                                    <button class="btn btn-sm btn-secondary" type="button" (click)="customerBoqs.add()">
                                        <i class="material-icons">add</i>&nbsp;Add Item
                                    </button>
                                </div>
                            </div>
                            <div class="col-12 fg-1" [class.d-none]="formStep!=2" [formGroup]="formGroup[2]">
                                <div class="h5 mb-3">Materials</div>
                                <table class="table">
                                    <thead>
                                        <tr>
                                            <th class="text-center">#</th>
                                            <th style="width:30%;">Material Name</th>
                                            <th>Reference Price</th>
                                            <th>UoM</th>
                                            <th>Target Price</th>
                                            <th>Qty</th>
                                            <th class="text-center">&times;</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <ng-container formArrayName="materials">
                                            <tr *ngFor="let material of materials.get().controls as FormArray;index as i;first as isFirst;last as isLast;" [formGroupName]="i">
                                                <td class="text-center">
                                                    {{i+1}}
                                                </td>
                                                <td>
                                                    <ng-select [items]="materialList | async" bindLabel="name" bindValue="id" formControlName="material_id" class="form-control my-2" placeholder="Search material..." [loading]="loading.status" typeToSearchText="Please enter 1 or more characters" [typeahead]="qMaterial" (change)="setMaterial(material, i);" [class.is-invalid]="material.get('material_id')?.invalid && (theForm.submitted || material.get('material_id')?.touched)" required>
                                                        <ng-template class="d-flex" ng-option-tmp let-item="item">
                                                            <div class="text-wrap">{{item.code}} - {{item.name}}</div>
                                                        </ng-template>
                                                    </ng-select>
                                                    <div class="text-danger position-absolute" style="margin-top: -11px;" *ngIf="material.get('material_id')?.invalid && (theForm.submitted || material.get('material_id')?.touched)">
                                                        <small *ngIf="material.get('material_id')?.errors?.required">Please select material</small>
                                                        <small *ngIf="material.get('material_id')?.errors?.serverError">{{ material.get("material_id")?.errors?.serverError }}</small>
                                                    </div>
                                                </td>
                                                <td>
                                                    <input type="text" ngxNumberFormat="###,###,###,###,###" formControlName="ref_price" class="form-control my-2" readonly>
                                                </td>
                                                <td>
                                                    <input type="text" formControlName="uom" class="form-control my-2" readonly>
                                                </td>
                                                <td>
                                                    <input type="text" ngxNumberFormat="###,###,###,###,###" formControlName="price" [class.is-invalid]="material.get('price')?.invalid && (theForm.submitted || material.get('price')?.touched)" class="form-control my-2" required>
                                                    <div class="text-danger position-absolute" style="margin-top: -11px;" *ngIf="material.get('price')?.invalid && (theForm.submitted || material.get('price')?.touched)">
                                                        <small *ngIf="material.get('price')?.errors?.required">Please type price</small>
                                                        <small *ngIf="material.get('price')?.errors?.serverError">{{ material.get("price")?.errors?.serverError }}</small>
                                                    </div>
                                                </td>
                                                <td>
                                                    <input type="text" ngxNumberFormat="###,###,###,###,###" [min]="1" formControlName="qty" [class.is-invalid]="material.get('qty')?.invalid && (theForm.submitted || material.get('qty')?.touched)" class="form-control my-2" required>
                                                    <div class="text-danger position-absolute" style="margin-top: -11px;" *ngIf="material.get('qty')?.invalid && (theForm.submitted || material.get('qty')?.touched)">
                                                        <small *ngIf="material.get('qty')?.errors?.required">Please type qty</small>
                                                        <small *ngIf="material.get('qty')?.errors?.serverError">{{ material.get("qty")?.errors?.serverError }}</small>
                                                    </div>
                                                </td>
                                                <td class="text-center">
                                                    <button type="button" (click)="materials.remove(i);" class="btn btn-sm btn-danger my-2">
                                                        <i class="material-icons">close</i>
                                                    </button>
                                                </td>
                                            </tr>
                                        </ng-container>
                                    </tbody>
                                </table>
                                <div class="mb-4">
                                    <button class="btn btn-sm btn-secondary" type="button" (click)="materials.add()">
                                        <i class="material-icons">add</i>&nbsp;Add Item
                                    </button>
                                </div>
                            </div>
                            <div class="col-12 fg-3" [class.d-none]="formStep!=3" [formGroup]="formGroup[3]">
                                <div class="h5 mb-3">Vendor BOQ</div>
                                <table class="table">
                                    <thead>
                                        <tr>
                                            <th class="text-center">#</th>
                                            <th style="width:25%;">Customer SoW Ref.</th>
                                            <th style="width:25%;">Vendor SoW</th>
                                            <th>Target Price</th>
                                            <th style="width:15%;">UoM</th>
                                            <th>Qty</th>
                                            <th>Notes</th>
                                            <th class="text-center">&times;</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <ng-container formArrayName="vendor_boqs">
                                            <tr *ngFor="let vendor_boq of vendorBoqs.get().controls as FormArray;index as i;first as isFirst;last as isLast;" [formGroupName]="i">
                                                <td class="text-center">
                                                    {{i+1}}
                                                </td>
                                                <td>
                                                    <ng-select class="form-control my-2" [clearable]="false" formControlName="customer_ref" loadingText="Loading..." [class.is-invalid]="vendor_boq.get('customer_ref')?.invalid && (theForm.submitted || vendor_boq.get('customer_ref')?.touched)" required>
                                                        <ng-option *ngFor="let item of getCustomerSow()" [value]="item.ref_id">{{item.sow_name}}</ng-option>
                                                    </ng-select>
                                                    <div class="text-danger position-absolute" style="margin-top: -11px;" *ngIf="vendor_boq.get('customer_ref')?.invalid && (theForm.submitted || vendor_boq.get('customer_ref')?.touched)">
                                                        <small *ngIf="vendor_boq.get('customer_ref')?.errors?.required">Please select customer SoW ref.</small>
                                                        <small *ngIf="vendor_boq.get('customer_ref')?.errors?.serverError">{{ vendor_boq.get("customer_ref")?.errors?.serverError }}</small>
                                                    </div>
                                                </td>
                                                <td>
                                                    <input type="text" formControlName="sow_name" [class.is-invalid]="vendor_boq.get('sow_name')?.invalid && (theForm.submitted || vendor_boq.get('sow_name')?.touched)" class="form-control my-2" required>
                                                    <div class="text-danger position-absolute" style="margin-top: -11px;" *ngIf="vendor_boq.get('sow_name')?.invalid && (theForm.submitted || vendor_boq.get('sow_name')?.touched)">
                                                        <small *ngIf="vendor_boq.get('sow_name')?.errors?.required">Please type SoW</small>
                                                        <small *ngIf="vendor_boq.get('sow_name')?.errors?.serverError">{{ vendor_boq.get("sow_name")?.errors?.serverError }}</small>
                                                    </div>
                                                </td>
                                                <td>
                                                    <input type="text" ngxNumberFormat="###,###,###,###,###" formControlName="price" [class.is-invalid]="vendor_boq.get('price')?.invalid && (theForm.submitted || vendor_boq.get('price')?.touched)" class="form-control my-2" required>
                                                    <div class="text-danger position-absolute" style="margin-top: -11px;" *ngIf="vendor_boq.get('price')?.invalid && (theForm.submitted || vendor_boq.get('price')?.touched)">
                                                        <small *ngIf="vendor_boq.get('price')?.errors?.required">Please type price</small>
                                                        <small *ngIf="vendor_boq.get('price')?.errors?.serverError">{{ vendor_boq.get("price")?.errors?.serverError }}</small>
                                                    </div>
                                                </td>
                                                <td>
                                                    <ng-select class="form-control my-2" [clearable]="false" formControlName="uom" loadingText="Loading..." [class.is-invalid]="vendor_boq.get('uom')?.invalid && (theForm.submitted || vendor_boq.get('uom')?.touched)" required>
                                                        <ng-option *ngFor="let item of (uomList | async)" [value]="item">{{item}}</ng-option>
                                                    </ng-select>
                                                    <div class="text-danger position-absolute" style="margin-top: -11px;" *ngIf="vendor_boq.get('uom')?.invalid && (theForm.submitted || vendor_boq.get('uom')?.touched)">
                                                        <small *ngIf="vendor_boq.get('uom')?.errors?.required">Please select uom</small>
                                                        <small *ngIf="vendor_boq.get('uom')?.errors?.serverError">{{ vendor_boq.get("uom")?.errors?.serverError }}</small>
                                                    </div>
                                                </td>
                                                <td>
                                                    <input type="text" ngxNumberFormat="###,###,###,###,###" formControlName="qty" [class.is-invalid]="vendor_boq.get('qty')?.invalid && (theForm.submitted || vendor_boq.get('qty')?.touched)" class="form-control my-2" required>
                                                    <div class="text-danger position-absolute" style="margin-top: -11px;" *ngIf="vendor_boq.get('qty')?.invalid && (theForm.submitted || vendor_boq.get('qty')?.touched)">
                                                        <small *ngIf="vendor_boq.get('qty')?.errors?.required">Please type qty</small>
                                                        <small *ngIf="vendor_boq.get('qty')?.errors?.serverError">{{ vendor_boq.get("qty")?.errors?.serverError }}</small>
                                                    </div>
                                                </td>
                                                <td>
                                                    <input type="text" formControlName="notes" [class.is-invalid]="vendor_boq.get('notes')?.invalid && (theForm.submitted || vendor_boq.get('notes')?.touched)" class="form-control my-2">
                                                    <div class="text-danger position-absolute" style="margin-top: -11px;" *ngIf="vendor_boq.get('notes')?.invalid && (theForm.submitted || vendor_boq.get('notes')?.touched)">
                                                        <small *ngIf="vendor_boq.get('qty')?.errors?.serverError">{{ vendor_boq.get("notes")?.errors?.serverError }}</small>
                                                    </div>
                                                </td>
                                                <td class="text-center">
                                                    <button type="button" (click)="vendorBoqs.remove(i);" class="btn btn-sm btn-danger my-2">
                                                        <i class="material-icons">close</i>
                                                    </button>
                                                </td>
                                            </tr>
                                        </ng-container>
                                    </tbody>
                                </table>
                                <div class="mb-4">
                                    <button class="btn btn-sm btn-secondary" type="button" (click)="vendorBoqs.add()">
                                        <i class="material-icons">add</i>&nbsp;Add Item
                                    </button>
                                </div>
                            </div>
                            <div class="col-12 fg-3" [class.d-none]="formStep!=4" [formGroup]="formGroup[4]">
                                <div class="h5 mb-3">Milestone Customer</div>
                                <ng-container formArrayName="milestone_customers">
                                    <div *ngFor="let milestone_customer of milestone_customers.get().controls as FormArray;index as i;first as isFirst;last as isLast;" [formGroupName]="i" class="border-bottom mb-3">
                                        <div class="row mb-3">
                                            <div class="col-12 d-flex flex-nowrap align-items-center">
                                                <div class="h6 p-0 m-0">Milestone #{{numToAplha(i)}}</div>
                                                <div class="ps-2">
                                                    <button type="button" class="btn btn-sm btn-outline-danger p-0" (click)="milestone_customers.remove(i)">
                                                        <i class="material-icons">delete</i>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="border-bottom mb-4">
                                            <ng-container formArrayName="sows">
                                                <div *ngFor="let sow of asFormArray(milestone_customer.get('sows')).controls as FormArray;index as sowI;first as sowFirst;last as sowLast;" [formGroupName]="sowI" class="row">
                                                    <div class="col-md-11">
                                                        <div class="form-floating mb-4">
                                                            <ng-select class="form-control" placeholder="-" formControlName="customer_ref" loadingText="Loading..." [class.is-invalid]="sow.get('customer_ref')?.invalid && (theForm.submitted || sow.get('customer_ref')?.touched)" required>
                                                                <ng-option *ngFor="let item of getCustomerSow()" [value]="item.ref_id">{{item.sow_name}}</ng-option>
                                                            </ng-select>
                                                            <label class="required">Customer SoW #{{sowI+1}}</label>
                                                            <div class="text-danger position-absolute text-end w-100" *ngIf="sow.get('customer_ref')?.invalid && (theForm.submitted || sow.get('customer_ref')?.touched)">
                                                                <small *ngIf="sow.get('customer_ref')?.errors?.required">Please select customer SoW</small>
                                                                <small *ngIf="sow.get('customer_ref')?.errors?.serverError">{{ sow.get("customer_ref")?.errors?.serverError }}</small>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-1 d-flex justify-content-start align-items-center mb-4">
                                                        <button type="button" class="btn btn-sm p-0 btn-danger me-1" (click)="customer_ref.remove(milestone_customer.get('sows'), sowI)">
                                                            <i class="material-icons">remove</i>
                                                        </button>
                                                        <button *ngIf="sowLast" type="button" class="btn btn-sm p-0 btn-secondary" (click)="customer_ref.add(milestone_customer.get('sows'))">
                                                            <i class="material-icons">add</i>
                                                        </button>
                                                    </div>
                                                </div>
                                            </ng-container>
                                        </div>
                                        <div>
                                            <ng-container formArrayName="names">
                                                <div *ngFor="let sow_name of asFormArray(milestone_customer.get('names')).controls as FormArray;index as sowNameI;first as sowNameFirst;last as sowNameLast;" [formGroupName]="sowNameI" class="row">
                                                    <div class="col-md-9">
                                                        <div class="form-floating mb-4">
                                                            <input type="text" class="form-control" placeholder="-" formControlName="name" loadingText="Loading..." [class.is-invalid]="sow_name.get('name')?.invalid && (theForm.submitted || sow_name.get('name')?.touched)" required>
                                                            <label class="required">Milestoner Name #{{sowNameI+1}}</label>
                                                            <div class="text-danger position-absolute text-end w-100" *ngIf="sow_name.get('name')?.invalid && (theForm.submitted || sow_name.get('name')?.touched)">
                                                                <small *ngIf="sow_name.get('name')?.errors?.required">Please type milestoner name</small>
                                                                <small *ngIf="sow_name.get('name')?.errors?.serverError">{{ sow_name.get("name")?.errors?.serverError }}</small>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-2 mb-4 d-flex align-items-center">
                                                        <button type="button" [attr.disabled]="loading.status || !sow_name.get('name')?.value ? 'disabled' : null" class="btn btn-sm btn-secondary" (click)="documentDialog.show(sow_name.get('name')?.value, sow_name.get('documents'))">
                                                            {{(sow_name.get('documents')?.value)?.length?'Edit':'Add'}} Documents
                                                        </button>
                                                    </div>
                                                    <div class="col-md-1 d-flex justify-content-start align-items-center mb-4">
                                                        <button type="button" class="btn btn-sm p-0 btn-danger me-1" (click)="sow_names.remove(milestone_customer.get('names'), sowNameI)">
                                                            <i class="material-icons">remove</i>
                                                        </button>
                                                        <button *ngIf="sowNameLast" type="button" class="btn btn-sm p-0 btn-secondary" (click)="sow_names.add(milestone_customer.get('names'))">
                                                            <i class="material-icons">add</i>
                                                        </button>
                                                    </div>
                                                </div>
                                            </ng-container>
                                        </div>
                                    </div>
                                </ng-container>
                                <button type="button" class="btn btn-sm btn-secondary mb-4" (click)="milestone_customers.add()">
                                    <i class="material-icons">add</i>&nbsp;Add Milestone
                                </button>
                            </div>
                            <div class="col-12 fg-3" [class.d-none]="formStep!=5" [formGroup]="formGroup[5]">
                                <div class="h5 mb-3">Milestone Vendor</div>
                                <ng-container formArrayName="milestone_vendors">
                                    <div *ngFor="let milestone_vendor of milestone_vendors.get().controls as FormArray;index as i;first as isFirst;last as isLast;" [formGroupName]="i" class="border-bottom mb-3">
                                        <div class="row mb-3">
                                            <div class="col-12 d-flex flex-nowrap align-items-center">
                                                <div class="h6 p-0 m-0">Milestone #{{numToAplha(i)}}</div>
                                                <div class="ps-2">
                                                    <button type="button" class="btn btn-sm btn-outline-danger p-0" (click)="milestone_vendors.remove(i)">
                                                        <i class="material-icons">delete</i>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="border-bottom mb-4">
                                            <ng-container formArrayName="sows">
                                                <div *ngFor="let sow of asFormArray(milestone_vendor.get('sows')).controls as FormArray;index as sowI;first as sowFirst;last as sowLast;" [formGroupName]="sowI" class="row">
                                                    <div class="col-md-11">
                                                        <div class="form-floating mb-4">
                                                            <ng-select class="form-control" placeholder="-" formControlName="vendor_ref" loadingText="Loading..." [class.is-invalid]="sow.get('vendor_ref')?.invalid && (theForm.submitted || sow.get('vendor_ref')?.touched)" required>
                                                                <ng-option *ngFor="let item of getVendorSow()" [value]="item.ref_id">{{item.sow_name}}</ng-option>
                                                            </ng-select>
                                                            <label class="required">Vendor SoW #{{sowI+1}}</label>
                                                            <div class="text-danger position-absolute text-end w-100" *ngIf="sow.get('vendor_ref')?.invalid && (theForm.submitted || sow.get('vendor_ref')?.touched)">
                                                                <small *ngIf="sow.get('vendor_ref')?.errors?.required">Please select vendor SoW</small>
                                                                <small *ngIf="sow.get('vendor_ref')?.errors?.serverError">{{ sow.get("vendor_ref")?.errors?.serverError }}</small>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-1 d-flex justify-content-start align-items-center mb-4">
                                                        <button type="button" class="btn btn-sm p-0 btn-danger me-1" (click)="vendor_ref.remove(milestone_vendor.get('sows'), sowI)">
                                                            <i class="material-icons">remove</i>
                                                        </button>
                                                        <button *ngIf="sowLast" type="button" class="btn btn-sm p-0 btn-secondary" (click)="vendor_ref.add(milestone_vendor.get('sows'))">
                                                            <i class="material-icons">add</i>
                                                        </button>
                                                    </div>
                                                </div>
                                            </ng-container>
                                        </div>
                                        <div>
                                            <ng-container formArrayName="names">
                                                <div *ngFor="let sow_name of asFormArray(milestone_vendor.get('names')).controls as FormArray;index as sowNameI;first as sowNameFirst;last as sowNameLast;" [formGroupName]="sowNameI" class="row">
                                                    <div class="col-md-9">
                                                        <div class="form-floating mb-4">
                                                            <input type="text" class="form-control" placeholder="-" formControlName="name" loadingText="Loading..." [class.is-invalid]="sow_name.get('name')?.invalid && (theForm.submitted || sow_name.get('name')?.touched)" required>
                                                            <label class="required">Milestoner Name #{{sowNameI+1}}</label>
                                                            <div class="text-danger position-absolute text-end w-100" *ngIf="sow_name.get('name')?.invalid && (theForm.submitted || sow_name.get('name')?.touched)">
                                                                <small *ngIf="sow_name.get('name')?.errors?.required">Please type milestoner name</small>
                                                                <small *ngIf="sow_name.get('name')?.errors?.serverError">{{ sow_name.get("name")?.errors?.serverError }}</small>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-2 mb-4 d-flex align-items-center">
                                                        <button type="button" [attr.disabled]="loading.status || !sow_name.get('name')?.value ? 'disabled' : null" class="btn btn-sm btn-secondary" (click)="documentDialog.show(sow_name.get('name')?.value, sow_name.get('documents'))">
                                                            {{(sow_name.get('documents')?.value)?.length?'Edit':'Add'}} Documents
                                                        </button>
                                                    </div>
                                                    <div class="col-md-1 d-flex justify-content-start align-items-center mb-4">
                                                        <button type="button" class="btn btn-sm p-0 btn-danger me-1" (click)="sow_names.remove(milestone_vendor.get('names'), sowNameI)">
                                                            <i class="material-icons">remove</i>
                                                        </button>
                                                        <button *ngIf="sowNameLast" type="button" class="btn btn-sm p-0 btn-secondary" (click)="sow_names.add(milestone_vendor.get('names'))">
                                                            <i class="material-icons">add</i>
                                                        </button>
                                                    </div>
                                                </div>
                                            </ng-container>
                                        </div>
                                    </div>
                                </ng-container>
                                <button type="button" class="btn btn-sm btn-secondary mb-4" (click)="milestone_vendors.add()">
                                    <i class="material-icons">add</i>&nbsp;Add Milestone
                                </button>
                            </div>
                            <div class="d-flex justify-content-between align-items-center border-top pt-1">
                                <div>&nbsp;</div>
                                <div class="text-end">
                                    <button type="button" class="btn btn-secondary" (click)="back()" [attr.disabled]="loading.status ? 'disabled' : null">
                                        <span class="d-flex justify-content-center align-items-center" *ngIf="formStep<=0">
                                            <i class="material-icons">close</i> &nbsp; Cancel
                                        </span>
                                        <span class="d-flex justify-content-center align-items-center" *ngIf="formStep>0">
                                            <i class="material-icons">navigate_before</i> &nbsp; Prev
                                        </span>
                                    </button>
                                    &nbsp;
                                    <button type="submit" class="btn btn-primary" [attr.disabled]="loading.status ? 'disabled' : null">
                                        <span class="d-flex justify-content-center align-items-center" *ngIf="formStep<5">
                                            Next &nbsp; <i class="material-icons">navigate_next</i>
                                        </span>
                                        <span class="d-flex justify-content-center align-items-center" *ngIf="formStep==5">
                                            Save &nbsp; <i class="material-icons">save</i>
                                        </span>
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<ng-template #documentModal let-modal>
    <div class="modal-header">
        <h5 class="modal-title">Documents of {{documentDialog.title}}</h5>
    </div>
    <div class="modal-body">
        <table *ngIf="documentDialog.data?.length" class="table mb-3">
            <thead>
                <tr>
                    <td>#</td>
                    <td>Document Name</td>
                    <td>Upload File<br><em class="small">(File type:pdf,png,jpg; max. size:5MB)</em></td>
                    <td class="text-center">&times;</td>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let doc of documentDialog.data;index as i">
                    <td>
                        {{i+1}}
                    </td>
                    <td>
                        <input type="text" class="form-control" [(ngModel)]="doc.name">
                    </td>
                    <td>
                        <div class="input-group">
                            <input type="text" [value]="doc.file?.file?.name || ''" (click)="documentDialog.browseFile(doc.ref_id, i);" class="form-control" readonly placeholder="Select document file">
                            <button class="btn btn-outline-secondary" type="button" (click)="documentDialog.browseFile(doc.ref_id, i);">
                                <i class="material-icons">file_upload</i>
                            </button>
                        </div>
                        <input type="file" class="form-control d-none" id="file-input-{{doc.ref_id}}-{{i}}" accept="application/pdf,image/jpg,image/jpg,image/png" (change)="fileHandler.handle($event, doc.file, 'application/pdf,image/jpg,image/jpeg,image/png', 2)">
                    </td>
                    <td class="text-center">
                        <button class="btn btn-danger btn-sm p-0" (click)="documentDialog.removeDoc(i)">
                            <i class="material-icons">close</i>
                        </button>
                    </td>
                </tr>
            </tbody>
        </table>
        <button type="button" class="btn btn-secondary btn-sm" (click)="documentDialog.addDoc()">
            Add Document
        </button>
    </div>
    <div class="modal-footer d-flex justify-content-between">
        <div>
            &nbsp;
        </div>
        <div class="text-end">
            <button type="button" class="btn btn-primary" (click)="documentDialog.submit()" [attr.disabled]="loading.status ? 'disabled' : null">Close</button>
        </div>
    </div>
</ng-template>
<div class="d-none">
    <div class="modal-lg"></div>
</div>
<ng-template #confirmExitModal let-modal>
    <div class="modal-header">
        <h5 class="modal-title">Leave Confirm</h5>
    </div>
    <div class="modal-body">
        Are you sure want to leave this page?<br>
        Your unsaved changes will be lost.
    </div>
    <div class="modal-footer d-flex justify-content-between">
        <div>
            &nbsp;
        </div>
        <div class="text-end">
            <button type="button" class="btn btn-primary" (click)="modal.close(false)" [attr.disabled]="loading.status ? 'disabled' : null">Stay</button>
            &nbsp;
            <button type="button" class="btn btn-secondary" (click)="modal.close(-1)" [attr.disabled]="loading.status ? 'disabled' : null">Leave & Save as Draft</button>
            &nbsp;
            <button type="button" class="btn btn-danger" (click)="modal.close(true)" [attr.disabled]="loading.status ? 'disabled' : null">Leave & Remove Data</button>
        </div>
    </div>
</ng-template>

<ng-template #confirmLoadModal let-modal>
    <div class="modal-header">
        <h5 class="modal-title">Load Unsaved Data</h5>
    </div>
    <div class="modal-body">
        Previously unsaved data was found, do you want to load it?
    </div>
    <div class="modal-footer d-flex justify-content-between">
        <div>
            &nbsp;
        </div>
        <div class="text-end">
            <button type="button" class="btn btn-secondary" (click)="modal.close(false)" [attr.disabled]="loading.status ? 'disabled' : null">No, just create new</button>
            &nbsp;
            <button type="button" class="btn btn-primary" (click)="modal.close(true)" [attr.disabled]="loading.status ? 'disabled' : null">Yes, load it</button>
        </div>
    </div>
</ng-template>
