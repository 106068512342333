<div class="row mb-4">
    <div class="col-sm-12 mx-auto">
        <div class="card">
            <div class="card-body">
                <div class="row">
                    <div class="d-flex flex-nowrap align-items-center">
                        <button class="btn btn-secondary btn-sm me-3" (click)="back()" title="Back">
                            <i class="material-icons">arrow_back</i>
                        </button>
                        <h4 class="card-title col-12 lh-0 m-0">Detail Supplier Price</h4>
                    </div>
                    <div class="text-center" *ngIf="!data.id">
                        <div class="spinner-grow" role="status">
                            <span class="visually-hidden">Loading...</span>
                        </div>
                    </div>
                    <div *ngIf="data.id" class="col-md-12 mt-3">
                        <div class="row">
                            <div class="col-md-6">
                                <div class="form-floating mb-4">
                                    <input type="text" [value]="data.vendor?.name" placeholder="..." class="form-control" readonly>
                                    <label>Name</label>
                                </div>
                                <div class="form-floating mb-4">
                                    <input type="text" [value]="data.vendor?.pic" placeholder="..." class="form-control" readonly>
                                    <label>PIC Name</label>
                                </div>
                                <div class="form-floating mb-4">
                                    <input type="text" [value]="data.vendor?.contact_number" placeholder="..." class="form-control" readonly>
                                    <label>Contact Number</label>
                                </div>
                                <div class="form-floating mb-4">
                                    <input type="text" [value]="data.vendor?.email" placeholder="..." class="form-control" readonly>
                                    <label>Email</label>
                                </div>
                                <div class="form-floating mb-4">
                                    <input type="text" [value]="data.created_at | dateFormat" placeholder="..." class="form-control" readonly>
                                    <label>Created at</label>
                                </div>
                                <div class="form-floating mb-4">
                                    <input type="text" [value]="data.creator?.name || '-'" placeholder="..." class="form-control" readonly>
                                    <label>Created By</label>
                                </div>
                                <div class="form-floating mb-4">
                                    <input type="text" [value]="(data.last_updated_at | dateFormat) || '-'" placeholder="..." class="form-control" readonly>
                                    <label>Last Updated at</label>
                                </div>
                                <div class="form-floating mb-4">
                                    <input type="text" [value]="data.updater?.name ||'-'" placeholder="..." class="form-control" readonly>
                                    <label>Last Updated By</label>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <table class="table">
                                    <thead>
                                        <tr>
                                            <th class="text-center">#</th>
                                            <th style="width:50%;">Material</th>
                                            <th>UoM</th>
                                            <th class="text-end">Price</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let item of data.details; index as i">
                                            <td class="text-center">{{i+1}}</td>
                                            <td>{{item?.material?.code}} - {{item?.material?.name}}</td>
                                            <td>{{item?.material?.uom}}</td>
                                            <td class="text-end">{{item?.price | number}}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
