import { Location } from '@angular/common';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormArray, FormControl, FormGroup, NgForm } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { FileHandlerService } from 'src/app/services/file-handler.service';
import { HttpService } from 'src/app/services/http.service';
import { LoadingService } from 'src/app/services/loading.service';

@Component({
    selector: 'app-approval-setting-add',
    templateUrl: './approval-setting-add.component.html',
    styleUrls: ['./approval-setting-add.component.scss']
})
export class ApprovalSettingAddComponent implements OnInit {

    constructor(
        private http: HttpService,
        private el: ElementRef,
        private location: Location,
        public fileHandler: FileHandlerService,
        public loading: LoadingService,
        private toastr: ToastrService) { }
    @ViewChild('theForm') theForm: NgForm | undefined;
    private apiPath = 'approvals';
    stayPage: boolean = false;
    formGroup = new FormGroup({
        name: new FormControl(),
        type: new FormControl(),
        status: new FormControl(1),
        rules: new FormArray([
            new FormGroup({
                role_id: new FormControl(),
                level_name: new FormControl(),
                level_order: new FormControl()
            })
        ])
    });
    formChanged = false;
    roleList: any[] = [];

    submitForm(form: any): void {
        if (!form.valid) {
            this.el.nativeElement.querySelectorAll('[formcontrolname].ng-invalid')?.[0]?.scrollIntoView({ behavior: "smooth", block: "center" });
            return;
        }
        if (!form.value.rules.length) {
            this.toastr.error('Please add Rule(s)', 'Error');
            return;
        }
        form.value.rules = form.value.rules.map((e: any, i: number) => {
            e.level_order = i
            return e;
        });
        form.value.status = Number(form.value.status);
        let fdata = new FormData();
        fdata.append('data', JSON.stringify(form.value));
        form.disable();
        this.http.Post(this.apiPath + '/x-create', fdata).then((r: any) => {
            form.enable();
            if (r.success) {
                this.toastr.success('Data saved successfully', 'Success');
                this.back();
            } else {
                if (r.response && r.response.wrong) {
                    Object.keys(r.response.wrong).forEach((key) => {
                        if (key != 'id') {
                            form.get(key)?.setErrors({ serverError: r.response.wrong[key][0] });
                            this.el.nativeElement.querySelectorAll('[formcontrolname="' + key + '"]')?.[0]?.focus();
                        }
                    });
                }
            }
        });
    }

    back(): void {
        this.location.back();
    }

    async getRole(): Promise<void> {
        let r = await this.http.Get('roles', { forceView: true });
        this.roleList = r.response?.result?.data || [];
    }

    get rules(): FormArray {
        return this.formGroup.get('rules') as FormArray;
    }

    addRules(): void {
        const rules = this.formGroup.get('rules') as FormArray;
        if (rules.length >= 10) {
            return;
        }
        rules.push(new FormGroup({
            role_id: new FormControl(),
            level_name: new FormControl(),
            level_order: new FormControl()
        }));
    }

    removeRules(i: number): void {
        const rules = this.formGroup.get('rules') as FormArray;
        rules.removeAt(i);
        if (!rules.length) {
            this.addRules();
        }
    }

    ngOnInit(): void {
        const oldValue = this.formGroup.value;
        this.formGroup.valueChanges.subscribe((e: any) => {
            this.formChanged = (oldValue != e);
        });
        this.getRole();
    }

}
