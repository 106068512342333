<div class="row">
    <div class="col-md-3 mb-3">
        <div class="card bg-primary text-white" (click)="getData()">
            <div *ngIf="loading.status" class="card-body text-center">
                <div class="spinner-border text-white" role="status">
                    <span class="visually-hidden">Loading...</span>
                </div>
            </div>
            <div *ngIf="!loading.status" class="card-body text-center">
                <div class="mb-1"><em>Total of Customer BOQ</em></div>
                <div class="fw-bold text-nowrap">IDR {{(data.count?.customer_boq || 0) | number}}</div>
            </div>
        </div>
    </div>
    <div class="col-md-3 mb-3">
        <div class="card bg-secondary text-white">
            <div *ngIf="loading.status" class="card-body text-center">
                <div class="spinner-border text-white" role="status">
                    <span class="visually-hidden">Loading...</span>
                </div>
            </div>
            <div *ngIf="!loading.status" class="card-body text-center">
                <div class="mb-1"><em>Total of Material</em></div>
                <div class="fw-bold text-nowrap">IDR {{(data.count?.material || 0) | number}}</div>
            </div>
        </div>
    </div>
    <div class="col-md-3 mb-3">
        <div class="card bg-info text-white">
            <div *ngIf="loading.status" class="card-body text-center">
                <div class="spinner-border text-white" role="status">
                    <span class="visually-hidden">Loading...</span>
                </div>
            </div>
            <div *ngIf="!loading.status" class="card-body text-center">
                <div class="mb-1"><em>Total of Vendor BOQ</em></div>
                <div class="fw-bold text-nowrap">IDR {{(data.count?.vendor_boq || 0) | number}}</div>
            </div>
        </div>
    </div>
    <div class="col-md-3 mb-3">
        <div class="card bg-success text-white">
            <div *ngIf="loading.status" class="card-body text-center">
                <div class="spinner-border text-white" role="status">
                    <span class="visually-hidden">Loading...</span>
                </div>
            </div>
            <div *ngIf="!loading.status" class="card-body text-center">
                <div class="mb-1"><em>Total of PO Vendor</em></div>
                <div class="fw-bold text-nowrap">IDR {{(data.count?.po_vendor || 0) | number}}</div>
            </div>
        </div>
    </div>
</div>
<div class="row">
    <div class="col-md-12 mb-3">
        <div class="card">
            <div class="card-body">
                <div *ngIf="loading.status" class="text-center">
                    <div class="spinner-border text-primary" role="status">
                        <span class="visually-hidden">Loading...</span>
                    </div>
                </div>
                <div class="text-center" [class.d-none]="loading.status">
                    <h5>PO Customer VS PO Vendor</h5>
                    <canvas #barChart></canvas>
                </div>
            </div>
        </div>
    </div>
</div>
