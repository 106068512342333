import { Location } from '@angular/common';
import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormArray, FormControl, FormGroup, NgForm, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject, Subject, delay, distinctUntilChanged } from 'rxjs';
import { DatePickerParserService } from 'src/app/services/date-picker-parser.service';
import { HttpService } from 'src/app/services/http.service';
import { LoadingService } from 'src/app/services/loading.service';

@Component({
    selector: 'app-pr-supplier-add',
    templateUrl: './pr-supplier-add.component.html',
    styleUrls: ['./pr-supplier-add.component.scss']
})
export class PrSupplierAddComponent implements OnInit, OnDestroy {

    constructor(
        private http: HttpService,
        private el: ElementRef,
        private location: Location,
        public loading: LoadingService,
        private dateParse: DatePickerParserService,
        private toastr: ToastrService) { }
    @ViewChild('theForm') theForm: NgForm | undefined;
    private apiPath = 'pr-suppliers';
    stayPage: boolean = false;
    formGroup = new FormGroup({
        contract_id: new FormControl(),
        purchase_order_id: new FormControl(),
        requested_at: new FormControl(),
        notes: new FormControl(),
        details: new FormArray([])
    });
    formChanged = false;
    contractList: any[] = [];
    poList: any[] = [];
    materialList = new BehaviorSubject<any[] | null>(null);
    qMaterial = new Subject<string>();

    submitForm(form: any): void {
        if (!form.valid) {
            this.el.nativeElement.querySelectorAll('[formcontrolname].ng-invalid')?.[0]?.scrollIntoView({ behavior: "smooth", block: "center" });
            return;
        }
        form.value.requested_at = this.dateParse.toYMD(form.value.requested_at);
        let fdata = new FormData();
        fdata.append('data', JSON.stringify(form.value));
        form.disable();
        this.http.Post(this.apiPath + '/create', fdata).then((r: any) => {
            form.enable();
            if (r.success) {
                this.toastr.success('Data saved successfully', 'Success');
                this.back();
            } else {
                if (r.response && r.response.wrong) {
                    Object.keys(r.response.wrong).forEach((key) => {
                        if (key != 'id') {
                            form.get(key)?.setErrors({ serverError: r.response.wrong[key][0] });
                            this.el.nativeElement.querySelectorAll('[formcontrolname="' + key + '"]')?.[0]?.focus();
                        }
                    });
                }
            }
        });
    }

    back(): void {
        this.location.back();
    }

    async getContract(): Promise<void> {
        const r = await this.http.Get('contracts', { filter: { status: 1 }, forceView: true });
        this.contractList = r.response?.result?.data || [];
    }

    async getPO(): Promise<void> {
        const contract_id = this.formGroup.get('contract_id')?.value;
        this.formGroup.get('purchase_order_id')?.reset();
        const r = await this.http.Get('purchase-orders', { filter: { contract_id: contract_id, status: 1 }, forceView: true });
        this.poList = r.response?.result?.data || [];
    }

    async getMaterial(q: any): Promise<void> {
        const po = this.formGroup.get('purchase_order_id')?.value;
        if (!po) {
            return;
        }
        let r = await this.http.Get('purchase-orders/materials/' + po, { q: q, limit: 15, filter: { status: 1 }, forceView: true });
        this.materialList.next(r.response?.result?.data || []);
    }

    setMaterial(fg: any, i: number): void {
        const material = (JSON.parse(JSON.stringify(this.materialList.getValue())) || []).find((e: any) => e.id == fg.controls?.po_material_id?.value) || {};
        fg.get('uom')?.setValue(material?.uom);
        fg.get('remaining_qty')?.setValue(material?.remaining_qty);
        fg.get('qty')?.setValidators(Validators.max(material?.remaining_qty));
        const hasMaterial = this.materials.get()?.controls.filter(e => e.get('po_material_id')?.value == fg.controls?.po_material_id?.value) || [];
        if (hasMaterial.length > 1 && i > 0) {
            const cVal = hasMaterial[0].get('qty')?.value;
            hasMaterial[0].get('qty')?.setValue(Number(cVal) + 1);
            this.materials.remove(i);
        }
    }

    materials = {
        get: () => {
            return this.formGroup.get('details') as FormArray;
        },
        add: () => {
            if (!this.formGroup.get('purchase_order_id')?.value) {
                this.toastr.error('Please select PO customer', 'PO Customer');
                return;
            }
            const items = this.materials.get();
            items.push(new FormGroup({
                po_material_id: new FormControl(),
                qty: new FormControl(),
                uom: new FormControl(),
                remaining_qty: new FormControl(),
            }));
        },
        remove: (i: number) => {
            const items = this.materials.get();
            items.removeAt(i);
            if (!items.length) {
                this.materials.add();
            }
        },
        clear: () => {
            this.materials.get().clear();
        }
    };

    ngOnInit(): void {
        this.getContract();
        this.qMaterial.pipe(delay(100), distinctUntilChanged()).subscribe((q: any) => {
            if (!q) {
                this.materialList.next([]);
                return;
            }
            this.getMaterial(q);
        });
        const oldValue = this.formGroup.value;
        this.formGroup.valueChanges.subscribe((e: any) => {
            this.formChanged = (oldValue != e);
        });
    }

    ngOnDestroy(): void {
        this.qMaterial.unsubscribe();
    }

}
