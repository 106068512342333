import { Location } from '@angular/common';
import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormControl, NgForm, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject, Subject, delay, distinctUntilChanged } from 'rxjs';
import { DatePickerParserService } from 'src/app/services/date-picker-parser.service';
import { HttpService } from 'src/app/services/http.service';
import { LoadingService } from 'src/app/services/loading.service';

@Component({
    selector: 'app-pr-supplier-edit',
    templateUrl: './pr-supplier-edit.component.html',
    styleUrls: ['./pr-supplier-edit.component.scss']
})
export class PrSupplierEditComponent implements OnInit, OnDestroy {

    constructor(
        private route: ActivatedRoute,
        private http: HttpService,
        private location: Location,
        public loading: LoadingService,
        private el: ElementRef,
        private fb: FormBuilder,
        private toastr: ToastrService,
        private dateParse: DatePickerParserService,
    ) {
        this.route.params.subscribe(e => {
            this.id = e.id;
        });
    }
    private apiPath = 'pr-suppliers';
    id: any;
    @ViewChild('theForm') theForm: NgForm | undefined;
    formGroup = this.fb.group({});
    formChanged = false;
    contractList: any[] = [];
    poList: any[] = [];
    materialList = new BehaviorSubject<any[] | null>(null);
    qMaterial = new Subject<string>();

    submitForm(form: any): void {
        if (!form.valid) {
            this.el.nativeElement.querySelectorAll('[formcontrolname].ng-invalid')?.[0]?.scrollIntoView({ behavior: "smooth", block: "center" });
            return;
        }

        form.value.requested_at = this.dateParse.toYMD(form.value.requested_at);
        let fdata = new FormData();
        fdata.append('data', JSON.stringify(form.value));
        form.disable();
        this.http.Post(this.apiPath + '/update', fdata).then((r: any) => {
            form.enable();
            if (r.success) {
                this.toastr.success('Data saved successfully', 'Success');
                this.back();
            } else {
                if (r.response && r.response.wrong) {
                    Object.keys(r.response.wrong).forEach((key) => {
                        form.get(key)?.setErrors({ serverError: r.response.wrong[key][0] });
                        this.el.nativeElement.querySelectorAll('[formcontrolname="' + key + '"]')?.[0]?.focus();
                    });
                }
            }
        });
    }

    back(): void {
        this.location.back();
    }

    async getContract(): Promise<void> {
        const r = await this.http.Get('contracts', { filter: { status: 1 }, forceView: true });
        this.contractList = r.response?.result?.data || [];
    }

    async getPO(): Promise<void> {
        const contract_id = this.formGroup.get('contract_id')?.value;
        this.formGroup.get('purchase_order_id')?.reset();
        const r = await this.http.Get('purchase-orders', { filter: { contract_id: contract_id, status: 1 }, forceView: true });
        this.poList = r.response?.result?.data || [];
    }

    async getMaterial(q: any): Promise<void> {
        const po = this.formGroup.get('purchase_order_id')?.value;
        if (!po) {
            return;
        }
        let r = await this.http.Get('purchase-orders/materials/' + po, { q: q, limit: 15, filter: { status: 1 }, forceView: true });
        this.materialList.next(r.response?.result?.data || []);
    }

    setMaterial(fg: any, i: number): void {
        const material = (JSON.parse(JSON.stringify(this.materialList.getValue())) || []).find((e: any) => e.id == fg.controls?.po_material_id?.value) || {};
        fg.get('uom')?.setValue(material?.uom);
        let rq = material?.remaining_qty as number;
        if (fg.get('id')?.value != null) {
            rq += material?.qty;
        }
        fg.get('remaining_qty')?.setValue(rq);
        fg.get('qty')?.setValidators(Validators.max(rq));
        const hasMaterial = this.materials.get()?.controls.filter(e => e.get('po_material_id')?.value == fg.controls?.po_material_id?.value) || [];
        if (hasMaterial.length > 1 && i > 0) {
            const cVal = hasMaterial[0].get('qty')?.value;
            hasMaterial[0].get('qty')?.setValue(Number(cVal) + 1);
            this.materials.remove(i);
        }
    }

    materials = {
        get: () => {
            return this.formGroup.get('details') as FormArray | null;
        },
        add: () => {
            if (!this.formGroup.get('purchase_order_id')?.value) {
                this.toastr.error('Please select PO customer', 'PO Customer');
                return;
            }
            const items = this.materials.get();
            items?.push(this.fb.group({
                id: new FormControl(),
                po_material_id: new FormControl(),
                qty: new FormControl(),
                uom: new FormControl(),
                remaining_qty: new FormControl(),
            }));
        },
        remove: (i: number) => {
            const items = this.materials.get();
            items?.removeAt(i);
            if (!items?.length) {
                this.materials.add();
            }
        },
        clear: () => {
            this.materials.get()?.clear();
        }
    };

    ngOnInit(): void {
        this.http.Get(this.apiPath + '/' + this.id, {}).then((r: any) => {
            if (r.success && r?.response?.result?.data?.id) {
                this.getContract();
                this.getPO();
                Object.keys(r?.response?.result?.data).forEach((key) => {
                    if (key == 'details') {
                        let details: any[] = [];
                        let dtl = r.response.result.data.details || [];
                        dtl = !dtl.length ? [{}] : dtl;
                        let material: any[] = [];
                        dtl.forEach((e: any) => {
                            material.push(e?.po_material);
                            details.push(this.fb.group({
                                id: new FormControl(e.id),
                                po_material_id: new FormControl(e.po_material_id),
                                uom: new FormControl(e.po_material?.uom),
                                remaining_qty: new FormControl(e.remaining_qty),
                                qty: new FormControl(e.qty, Validators.max(e.remaining_qty))
                            }))
                        });
                        this.materialList.next(material);
                        this.formGroup.addControl(key, new FormArray(details));
                    } else if (key == 'requested_at') {
                        this.formGroup.addControl(key, new FormControl(this.dateParse.toObject(r?.response?.result?.data[key])));
                    } else {
                        this.formGroup.addControl(key, new FormControl(r?.response?.result?.data[key]));
                    }
                });

                this.qMaterial.pipe(delay(100), distinctUntilChanged()).subscribe((q: any) => {
                    if (!q) {
                        this.materialList.next([]);
                        return;
                    }
                    this.getMaterial(q);
                });
            } else {
                this.back();
            }
        });

    }

    ngOnDestroy(): void {
        this.qMaterial.unsubscribe();
    }

}

