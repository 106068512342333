<div *ngIf="dashboardLoading" class="d-flex w-100 h-100 justify-content-center align-items-center">
    <div class="spinner-border text-primary" role="status">
        <span class="visually-hidden">Loading...</span>
    </div>
</div>
<div *ngIf="!dashboardLoading" class="dashboard">
    <div *ngIf="!canAccess" class="row">
        <div class="col-12">
            <div class="card">
                <div class="card-body" (click)="ngOnInit()">
                    <h4>Hi {{userData.name}}, welcome to imoCRM App</h4>
                </div>
            </div>
        </div>
    </div>
    <ng-container *ngIf="canAccess">
        <app-dashboard-view></app-dashboard-view>
    </ng-container>
</div>
