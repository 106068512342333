import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './components/auth/login/login.component';
import { ForgotPasswordComponent } from './components/auth/forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './components/auth/reset-password/reset-password.component';
import { AutoFocusDirective } from './directives/auto-focus.directive';
import { SortableDirective } from './directives/sortable.directive';
import { DatePipe, FilterPipe, HaveKeysPipe, MePipe, NowPipe, NumberPipe, TimePipe } from './pipes/pipes.pipe';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';
import { ToastrModule } from 'ngx-toastr';
import { NgSelectModule } from '@ng-select/ng-select';
import { HTTPInterceptorService } from './services/http-interceptor.service';
import { AvatarComponent } from './components/avatar/avatar.component';
import { BreadcrumbComponent } from './components/breadcrumb/breadcrumb.component';
import { NavbarComponent } from './components/navbar/navbar.component';
import { SidenavComponent } from './components/sidenav/sidenav.component';
import { MenuViewComponent } from './components/menu/menu-view/menu-view.component';
import { MenuAddComponent } from './components/menu/menu-add/menu-add.component';
import { MenuEditComponent } from './components/menu/menu-edit/menu-edit.component';
import { ContractViewComponent } from './components/contract/contract-view/contract-view.component';
import { ContractAddComponent } from './components/contract/contract-add/contract-add.component';
import { ContractEditComponent } from './components/contract/contract-edit/contract-edit.component';
import { ContractDetailComponent } from './components/contract/contract-detail/contract-detail.component';
import { UserViewComponent } from './components/user/user-view/user-view.component';
import { UserAddComponent } from './components/user/user-add/user-add.component';
import { UserEditComponent } from './components/user/user-edit/user-edit.component';
import { UserDetailComponent } from './components/user/user-detail/user-detail.component';
import { RoleViewComponent } from './components/role/role-view/role-view.component';
import { RoleAddComponent } from './components/role/role-add/role-add.component';
import { RoleEditComponent } from './components/role/role-edit/role-edit.component';
import { RoleDetailComponent } from './components/role/role-detail/role-detail.component';
import { VendorViewComponent } from './components/vendor/vendor-view/vendor-view.component';
import { VendorAddComponent } from './components/vendor/vendor-add/vendor-add.component';
import { VendorEditComponent } from './components/vendor/vendor-edit/vendor-edit.component';
import { VendorDetailComponent } from './components/vendor/vendor-detail/vendor-detail.component';
import { MaterialViewComponent } from './components/material/material-view/material-view.component';
import { MaterialAddComponent } from './components/material/material-add/material-add.component';
import { MaterialEditComponent } from './components/material/material-edit/material-edit.component';
import { MaterialDetailComponent } from './components/material/material-detail/material-detail.component';
import { PurchaseOrderViewComponent } from './components/purchase-order/purchase-order-view/purchase-order-view.component';
import { PurchaseOrderAddComponent } from './components/purchase-order/purchase-order-add/purchase-order-add.component';
import { PurchaseOrderEditComponent } from './components/purchase-order/purchase-order-edit/purchase-order-edit.component';
import { PurchaseOrderDetailComponent } from './components/purchase-order/purchase-order-detail/purchase-order-detail.component';
import { SupplierPriceViewComponent } from './components/supplier-price/supplier-price-view/supplier-price-view.component';
import { SupplierPriceAddComponent } from './components/supplier-price/supplier-price-add/supplier-price-add.component';
import { SupplierPriceEditComponent } from './components/supplier-price/supplier-price-edit/supplier-price-edit.component';
import { SupplierPriceDetailComponent } from './components/supplier-price/supplier-price-detail/supplier-price-detail.component';
import { VendorPriceViewComponent } from './components/vendor-price/vendor-price-view/vendor-price-view.component';
import { VendorPriceAddComponent } from './components/vendor-price/vendor-price-add/vendor-price-add.component';
import { VendorPriceEditComponent } from './components/vendor-price/vendor-price-edit/vendor-price-edit.component';
import { NgxNumberFormatModule } from 'ngx-number-format';
import { VendorPricePriceListComponent } from './components/vendor-price/vendor-price-price-list/vendor-price-price-list.component';
import { VendorPriceVendorListComponent } from './components/vendor-price/vendor-price-vendor-list/vendor-price-vendor-list.component';
import { ApprovalSettingViewComponent } from './components/approval-setting/approval-setting-view/approval-setting-view.component';
import { ApprovalSettingAddComponent } from './components/approval-setting/approval-setting-add/approval-setting-add.component';
import { ApprovalSettingEditComponent } from './components/approval-setting/approval-setting-edit/approval-setting-edit.component';
import { ApprovalSettingDetailComponent } from './components/approval-setting/approval-setting-detail/approval-setting-detail.component';
import { PrSupplierViewComponent } from './components/pr-supplier/pr-supplier-view/pr-supplier-view.component';
import { PrSupplierAddComponent } from './components/pr-supplier/pr-supplier-add/pr-supplier-add.component';
import { PrSupplierEditComponent } from './components/pr-supplier/pr-supplier-edit/pr-supplier-edit.component';
import { PrSupplierDetailComponent } from './components/pr-supplier/pr-supplier-detail/pr-supplier-detail.component';
import { PoSupplierViewComponent } from './components/po-supplier/po-supplier-view/po-supplier-view.component';
import { PoSupplierDetailComponent } from './components/po-supplier/po-supplier-detail/po-supplier-detail.component';
import { NgxPrintModule } from 'ngx-print';
import { initializeApp } from "firebase/app";
import { environment } from 'src/environments/environment';
import { CustomerProgressViewComponent } from './components/customer-progress/customer-progress-view/customer-progress-view.component';
import { CustomerProgressDetailComponent } from './components/customer-progress/customer-progress-detail/customer-progress-detail.component';
import { CustomerProgressUpdateComponent } from './components/customer-progress/customer-progress-update/customer-progress-update.component';
import { LightboxModule } from 'ngx-lightbox';
import { VendorProgressViewComponent } from './components/vendor-progress/vendor-progress-view/vendor-progress-view.component';
import { VendorProgressDetailComponent } from './components/vendor-progress/vendor-progress-detail/vendor-progress-detail.component';
import { VendorProgressUpdateComponent } from './components/vendor-progress/vendor-progress-update/vendor-progress-update.component';
import { CustomerDocumentViewComponent } from './components/customer-document/customer-document-view/customer-document-view.component';
import { CustomerDocumentDetailComponent } from './components/customer-document/customer-document-detail/customer-document-detail.component';
import { VendorDocumentViewComponent } from './components/vendor-document/vendor-document-view/vendor-document-view.component';
import { VendorDocumentDetailComponent } from './components/vendor-document/vendor-document-detail/vendor-document-detail.component';
import { VendorDocumentUpdateComponent } from './components/vendor-document/vendor-document-update/vendor-document-update.component';
import { CustomerDocumentUpdateComponent } from './components/customer-document/customer-document-update/customer-document-update.component';
import { VendorBillingDocumentViewComponent } from './components/vendor-billing-document/vendor-billing-document-view/vendor-billing-document-view.component';
import { VendorBillingDocumentUpdateComponent } from './components/vendor-billing-document/vendor-billing-document-update/vendor-billing-document-update.component';
import { VendorBillingDocumentDetailComponent } from './components/vendor-billing-document/vendor-billing-document-detail/vendor-billing-document-detail.component';
import { CustomerBillingDocumentDetailComponent } from './components/customer-billing-document/customer-billing-document-detail/customer-billing-document-detail.component';
import { CustomerBillingDocumentUpdateComponent } from './components/customer-billing-document/customer-billing-document-update/customer-billing-document-update.component';
import { CustomerBillingDocumentViewComponent } from './components/customer-billing-document/customer-billing-document-view/customer-billing-document-view.component';
import { NotificationComponent } from './components/notification/notification/notification.component';
import { PrVendorViewComponent } from './components/pr-vendor/pr-vendor-view/pr-vendor-view.component';
import { PrVendorAddComponent } from './components/pr-vendor/pr-vendor-add/pr-vendor-add.component';
import { PrVendorEditComponent } from './components/pr-vendor/pr-vendor-edit/pr-vendor-edit.component';
import { PrVendorDetailComponent } from './components/pr-vendor/pr-vendor-detail/pr-vendor-detail.component';
import { UserVendorViewComponent } from './components/user-vendor/user-vendor-view/user-vendor-view.component';
import { UserVendorAddComponent } from './components/user-vendor/user-vendor-add/user-vendor-add.component';
import { UserVendorEditComponent } from './components/user-vendor/user-vendor-edit/user-vendor-edit.component';
import { UserVendorDetailComponent } from './components/user-vendor/user-vendor-detail/user-vendor-detail.component';
import { PoVendorViewComponent } from './components/po-vendor/po-vendor-view/po-vendor-view.component';
import { PoVendorDetailComponent } from './components/po-vendor/po-vendor-detail/po-vendor-detail.component';
import { PoVendorUpdateComponent } from './components/po-vendor/po-vendor-update/po-vendor-update.component';
import { AccountComponent } from './components/account/account.component';
import { PoAdditionalPriceComponent } from './components/po-additional-price/po-additional-price.component';
import { PoAdditionalViewComponent } from './components/po-additional/po-additional-view/po-additional-view.component';
import { PoAdditionalAddComponent } from './components/po-additional/po-additional-add/po-additional-add.component';
import { PoAdditionalEditComponent } from './components/po-additional/po-additional-edit/po-additional-edit.component';
import { PoAdditionalDetailComponent } from './components/po-additional/po-additional-detail/po-additional-detail.component';
import { ReportVendorPriceComponent } from './components/report-vendor-price/report-vendor-price.component';
import { ReportSupplierPriceComponent } from './components/report-supplier-price/report-supplier-price.component';
import { VendorBillingDocumentPaidComponent } from './components/vendor-billing-document/vendor-billing-document-paid/vendor-billing-document-paid.component';
import { DashboardViewComponent } from './components/dashboard-view/dashboard-view/dashboard-view.component';
import { CustomerViewComponent } from './components/customer/customer-view/customer-view.component';
import { CustomerAddComponent } from './components/customer/customer-add/customer-add.component';
import { CustomerEditComponent } from './components/customer/customer-edit/customer-edit.component';
import { CustomerDetailComponent } from './components/customer/customer-detail/customer-detail.component';
initializeApp(environment.firebase);

@NgModule({
    declarations: [
        AutoFocusDirective,
        SortableDirective,
        DatePipe,
        TimePipe,
        NumberPipe,
        HaveKeysPipe,
        FilterPipe,
        MePipe,
        NowPipe,
        AppComponent,
        LoginComponent,
        ForgotPasswordComponent,
        ResetPasswordComponent,
        DashboardComponent,
        AvatarComponent,
        BreadcrumbComponent,
        NavbarComponent,
        SidenavComponent,
        MenuViewComponent,
        MenuAddComponent,
        MenuEditComponent,
        ContractViewComponent,
        ContractAddComponent,
        ContractEditComponent,
        ContractDetailComponent,
        UserViewComponent,
        UserAddComponent,
        UserEditComponent,
        RoleViewComponent,
        RoleAddComponent,
        RoleEditComponent,
        RoleDetailComponent,
        UserDetailComponent,
        VendorViewComponent,
        VendorAddComponent,
        VendorEditComponent,
        VendorDetailComponent,
        MaterialViewComponent,
        MaterialAddComponent,
        MaterialEditComponent,
        MaterialDetailComponent,
        PurchaseOrderViewComponent,
        PurchaseOrderAddComponent,
        PurchaseOrderEditComponent,
        PurchaseOrderDetailComponent,
        SupplierPriceViewComponent,
        SupplierPriceAddComponent,
        SupplierPriceEditComponent,
        SupplierPriceDetailComponent,
        VendorPriceViewComponent,
        VendorPriceAddComponent,
        VendorPriceEditComponent,
        VendorPricePriceListComponent,
        VendorPriceVendorListComponent,
        ApprovalSettingViewComponent,
        ApprovalSettingAddComponent,
        ApprovalSettingEditComponent,
        ApprovalSettingDetailComponent,
        PrSupplierViewComponent,
        PrSupplierAddComponent,
        PrSupplierEditComponent,
        PrSupplierDetailComponent,
        PoSupplierViewComponent,
        PoSupplierDetailComponent,
        CustomerProgressViewComponent,
        CustomerProgressDetailComponent,
        CustomerProgressUpdateComponent,
        VendorProgressViewComponent,
        VendorProgressDetailComponent,
        VendorProgressUpdateComponent,
        CustomerDocumentViewComponent,
        CustomerDocumentDetailComponent,
        CustomerDocumentUpdateComponent,
        VendorDocumentViewComponent,
        VendorDocumentDetailComponent,
        VendorDocumentUpdateComponent,
        VendorBillingDocumentViewComponent,
        VendorBillingDocumentUpdateComponent,
        VendorBillingDocumentDetailComponent,
        CustomerBillingDocumentDetailComponent,
        CustomerBillingDocumentUpdateComponent,
        CustomerBillingDocumentViewComponent,
        NotificationComponent,
        PrVendorViewComponent,
        PrVendorAddComponent,
        PrVendorEditComponent,
        PrVendorDetailComponent,
        UserVendorViewComponent,
        UserVendorAddComponent,
        UserVendorEditComponent,
        UserVendorDetailComponent,
        PoVendorViewComponent,
        PoVendorDetailComponent,
        PoVendorUpdateComponent,
        AccountComponent,
        PoAdditionalPriceComponent,
        PoAdditionalViewComponent,
        PoAdditionalAddComponent,
        PoAdditionalEditComponent,
        PoAdditionalDetailComponent,
        ReportVendorPriceComponent,
        ReportSupplierPriceComponent,
        VendorBillingDocumentPaidComponent,
        DashboardViewComponent,
        CustomerViewComponent,
        CustomerAddComponent,
        CustomerEditComponent,
        CustomerDetailComponent,
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        HttpClientModule,
        FormsModule,
        ReactiveFormsModule,
        BrowserAnimationsModule,
        NgbModule,
        NgxDaterangepickerMd.forRoot(),
        NgSelectModule,
        ToastrModule.forRoot(),
        NgxNumberFormatModule,
        NgxPrintModule,
        LightboxModule,
    ],
    providers: [
        {
            provide: HTTP_INTERCEPTORS,
            useClass: HTTPInterceptorService,
            multi: true
        }
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
