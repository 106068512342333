import { Location } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { HttpService } from 'src/app/services/http.service';
import { LoadingService } from 'src/app/services/loading.service';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'app-material-detail',
    templateUrl: './material-detail.component.html',
    styleUrls: ['./material-detail.component.scss']
})
export class MaterialDetailComponent implements OnInit {

    constructor(
        private route: ActivatedRoute,
        private http: HttpService,
        private location: Location,
        public loading: LoadingService,
    ) {
        this.route.params.subscribe(e => {
            this.id = e.id;
        });
    }
    private apiPath = 'materials';
    id: any;
    @ViewChild('theForm') theForm: NgForm | undefined;
    data: any = {};
    assetUrl: string = environment.assetUrl;
    prices: any[] = [];

    back(): void {
        this.location.back();
    }

    async getPrice(): Promise<void> {
        const r = await this.http.Get(this.apiPath + '/prices/' + this.id, { forceView: true });
        this.prices = r?.response?.result?.data;
    }

    ngOnInit(): void {
        this.http.Get(this.apiPath + '/' + this.id, {}).then((r: any) => {
            if (r.success && r?.response?.result?.data?.id) {
                this.data = r?.response?.result?.data;
            } else {
                this.back();
            }
        });

    }

}


