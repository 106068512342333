<div class="row mb-4">
    <div class="col-sm-12 mx-auto">
        <div class="card">
            <div class="card-body">
                <div class="row">
                    <div class="d-flex flex-nowrap align-items-center">
                        <button class="btn btn-secondary btn-sm me-3" (click)="back()" title="Back">
                            <i class="material-icons">arrow_back</i>
                        </button>
                        <h4 class="card-title col-12 lh-0 m-0">Detail Company User</h4>
                    </div>
                    <div class="text-center" *ngIf="!data.id">
                        <div class="spinner-grow" role="status">
                            <span class="visually-hidden">Loading...</span>
                        </div>
                    </div>
                    <div *ngIf="data.id" class="col-md-8 mt-3">
                        <div>
                            <div class="form-floating mb-4">
                                <input type="text" [value]="data.role?.name" placeholder="..." class="form-control" readonly>
                                <label>Role</label>
                            </div>
                            <div class="form-floating mb-4">
                                <input type="text" [value]="data.name" placeholder="..." class="form-control" readonly>
                                <label>Name</label>
                            </div>
                            <div class="form-floating mb-4">
                                <input [value]="data.email" placeholder="..." class="form-control" readonly>
                                <label>Email</label>
                            </div>
                            <div class="form-floating mb-4">
                                <input [value]="data.phone || '-'" placeholder="..." class="form-control" readonly>
                                <label>Phone No.</label>
                            </div>
                            <div class="form-floating mb-4">
                                <input type="text" [value]="data.status==1?'Active':'Inactive'" placeholder="..." class="form-control" readonly>
                                <label>Status</label>
                            </div>
                            <div class="form-floating mb-4">
                                <input type="text" [value]="(data.last_login_at | dateFormat) || '-'" placeholder="..." class="form-control" readonly>
                                <label>Last Login at</label>
                            </div>
                            <div class="form-floating mb-4">
                                <input type="text" [value]="data.created_at | dateFormat" placeholder="..." class="form-control" readonly>
                                <label>Created at</label>
                            </div>
                            <div class="form-floating mb-4">
                                <input type="text" [value]="data.creator?.name || '-'" placeholder="..." class="form-control" readonly>
                                <label>Created By</label>
                            </div>
                            <div class="form-floating mb-4">
                                <input type="text" [value]="(data.last_updated_at | dateFormat) || '-'" placeholder="..." class="form-control" readonly>
                                <label>Last Updated at</label>
                            </div>
                            <div class="form-floating mb-4">
                                <input type="text" [value]="data.updater?.name ||'-'" placeholder="..." class="form-control" readonly>
                                <label>Last Updated By</label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
