<div class="row">
    <div class="col-12">
        <div class="card">
            <div class="card-body">
                <div class="d-flex justify-content-between row">
                    <div class="row pe-0">
                        <div class="d-flex flex-nowrap align-items-center col-md-6 mb-3">
                            <button class="btn btn-secondary btn-sm me-3" (click)="back()" title="Kembali">
                                <i class="material-icons">arrow_back</i>
                            </button>
                            <h4 class="card-title col-12 lh-0 m-0">Update Customer Billing Document</h4>
                        </div>
                    </div>
                    <div *ngIf="data.id && data.status>-2" class="d-flex justify-content-center col-12">
                        <div class="text-center d-flex justify-content-center flex-wrap step-container active">
                            <div style="width: 32px; height: 32px" class="bg-gray rounded-circle text-white d-flex justify-content-center align-items-center icon-step">
                                <span class="material-icons">verified_user</span>
                            </div>
                            <div class="w-100 text-center small">
                                <div class="text-muted">
                                    Created
                                </div>
                                <div class="fw-bold">
                                    {{data.creator?.role?.name || 'Unknown'}}
                                </div>
                            </div>
                        </div>
                        <div *ngFor="let app of data.approvals;" [class.reject]="app.status==-1" [class.active]="app.status==1" class="text-center d-flex justify-content-center flex-wrap step-container">
                            <div style="width: 32px; height: 32px" class="bg-gray rounded-circle text-white d-flex justify-content-center align-items-center icon-step">
                                <span *ngIf="app.status==0" class="material-icons">
                                    hourglass_top
                                </span>
                                <span *ngIf="app.status==1" class="material-icons">
                                    verified_user
                                </span>
                                <span *ngIf="app.status==-1" class="material-icons">
                                    gpp_bad
                                </span>
                            </div>
                            <div class="w-100 text-center small">
                                <div class="text-muted">
                                    <span *ngIf="app.status==1">
                                        {{app.level_name=='approval' ? 'Approved' : 'Allocated'}}
                                    </span>
                                    <span *ngIf="app.status==-1">
                                        Rejected
                                    </span>
                                    <span *ngIf="app.status==0">
                                        Waiting {{app.level_name || 'Unknown'}}
                                    </span>
                                </div>
                                <div class="fw-bold">
                                    {{app?.role?.name || 'Unknown'}}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div *ngIf="!data.id" class="text-center">
                    <div class="spinner-grow" role="status">
                        <span class="visually-hidden">Loading...</span>
                    </div>
                </div>

                <div *ngIf="data.id" class="mt-4">
                    <div class="row">
                        <div class="col-12">
                            <div class="row">
                                <div class="col-md-6">
                                    <div class="form-floating mb-4">
                                        <input disabled type="text" [value]="data.site?.site_id" placeholder="..." class="form-control">
                                        <label>Site ID</label>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-floating mb-4">
                                        <input disabled [value]="data.site?.name" placeholder="..." class="form-control">
                                        <label>Site Name</label>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-floating mb-4">
                                        <input disabled [value]="data.site?.purchase_order?.number" placeholder="..." class="form-control">
                                        <label>PO Customer</label>
                                    </div>
                                </div>
                                <div class="col-6">
                                    <div class="form-floating mb-4">
                                        <input disabled [value]="data.site?.purchase_order?.contract?.project_name" placeholder="..." class="form-control">
                                        <label>Project Name</label>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-floating mb-4">
                                        <input disabled [value]="data.milestone_name?.milestone_customer?.type" placeholder="..." class="form-control">
                                        <label>Milestone #</label>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-floating mb-4">
                                        <input type="text" disabled [value]="data.milestone_name?.name" placeholder="..." class="form-control">
                                        <label>Milestoner Name</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="rejectRemarks()" class="row">
                        <div class="col-12">
                            <div class="alert alert-danger mb-4">
                                <div class="fw-bold">Rejection remarks :</div>
                                <div>{{rejectRemarks()}}</div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12">
                            <div *ngIf="data.details.length">
                                <table class="table table-bordered">
                                    <thead>
                                        <tr>
                                            <th>
                                                #
                                            </th>
                                            <th>
                                                Document Name
                                            </th>
                                            <th>
                                                Document Date
                                            </th>
                                            <th>
                                                Document File <small>(pdf, jpg, png)</small>
                                            </th>
                                            <th class="text-center">
                                                &times;
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <ng-container *ngFor="let item of data.details;index as i;">
                                            <tr>
                                                <td>
                                                    {{i+1}}
                                                </td>
                                                <td>
                                                    <div class="mb-2">
                                                        <input type="text" [(ngModel)]="item.document_name" (ngModelChange)="item.document_name_error=false" placeholder="Type document name" class="form-control" [attr.disabled]="loading.status ? 'disabled' : null" [class.is-invalid]="item.document_name_error">
                                                    </div>
                                                    <div style="margin-top:-10px;" class="text-danger" *ngIf="item.document_date_error">
                                                        <small>Please type document name</small>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div class="input-group mb-2">
                                                        <input type="text" readonly (click)="tfDate.toggle()" ngbDatepicker #tfDate="ngbDatepicker" [(ngModel)]="item.document_date" (ngModelChange)="item.document_date_error=false" placeholder="Select document date" class="form-control" [attr.disabled]="loading.status ? 'disabled' : null" [class.is-invalid]="item.document_date_error">
                                                        <button type="button" class="btn btn-sm btn-outline-secondary" (click)="tfDate.toggle()" [attr.disabled]="loading.status ? 'disabled' : null">
                                                            <i class="material-icons">today</i>
                                                        </button>
                                                    </div>
                                                    <div style="margin-top:-10px;" class="text-danger" *ngIf="item.document_date_error">
                                                        <small>Please select valid date</small>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div class="input-group mb-2">
                                                        <input type="text" [value]="item.file?.file?.name || ''" (click)="browseFile(i);" class="form-control" readonly placeholder="Select document file (Optional)" [attr.disabled]="loading.status ? 'disabled' : null" [class.is-invalid]="item.file_error">
                                                        <button *ngIf="item.file?.download" class="btn btn-sm btn-outline-primary" type="button" (click)="downloadFile(item.file?.file?.name);" [attr.disabled]="loading.status ? 'disabled' : null">
                                                            <i class="material-icons">file_download</i>
                                                        </button>
                                                        <button class="btn btn-sm btn-outline-secondary" type="button" (click)="browseFile(i);" [attr.disabled]="loading.status ? 'disabled' : null">
                                                            <i class="material-icons">file_upload</i>
                                                        </button>
                                                    </div>
                                                    <div style="margin-top:-10px;" class="text-danger" *ngIf="item.file_error">
                                                        <small>Please select valid file</small>
                                                    </div>
                                                    <input type="file" class="form-control d-none file-input-{{i}}" accept="application/pdf,image/jpg,image/jpeg,image/png" (change)="fileHandler.handle($event, item.file, 'application/pdf,image/jpg,image/jpeg,image/png', 5);item.file_error=false">
                                                </td>
                                                <td class="text-center">
                                                    <button type="button" class="btn btn-sm btn-danger" (click)="removeDetail(i)" [attr.disabled]="loading.status ? 'disabled' : null">
                                                        <i class="material-icons">close</i>
                                                    </button>
                                                </td>
                                            </tr>
                                        </ng-container>
                                    </tbody>
                                </table>
                            </div>
                            <button type="button" class="btn btn-primary" (click)="addDetail()" [attr.disabled]="loading.status ? 'disabled' : null">Add Document(s)</button>
                        </div>
                        <div class="col-12">
                            <div class="text-end">
                                <button type="button" class="btn btn-secondary" (click)="back()" [attr.disabled]="loading.status ? 'disabled' : null">Back</button>
                                &nbsp;
                                <button type="button" class="btn btn-primary" (click)="submitForm()" [attr.disabled]="loading.status || !data.details.length ? 'disabled' : null">Save</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
