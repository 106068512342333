<div class="row">
    <div class="col-md-12">
        <div class="card">
            <div class="card-body">
                <div class="row">
                    <div class="col-md-6 col-sm-12 d-md-flex align-items-center">
                        <h4 class="card-title me-2 mb-md-0 mb-3">Vendor Document</h4>
                    </div>
                    <div class="col-md-6 col-sm-12 d-flex justify-content-end">
                        <div *ngIf="!pageQuery.isSearch">
                            <button title="Refresh" [attr.disabled]="loading.status ? 'disabled' : null" class="btn btn-secondary ms-1" (click)="getData()">
                                <i class="material-icons">refresh</i>
                            </button>
                            <button title="Search" [attr.disabled]="loading.status ? 'disabled' : null" class="btn btn-secondary ms-1" (click)="pageQuery.isSearch = true">
                                <i class="material-icons">search</i>
                            </button>
                        </div>
                        <div *ngIf="pageQuery.isSearch" class="w-100">
                            <div class="input-group has-search">
                                <span class="input-group-text pt-0 pb-0 bg-white border-secondary">
                                    <i class="material-icons">search</i>
                                </span>
                                <input type="search" [appAutoFocus]="true" [(ngModel)]="pageQuery.queryPage.q" (keyup)="pageQuery.searchData($event)" class="form-control border-start-0 ps-1 shadow-none border-secondary bg-light" placeholder="Type & Hit Enter" />
                                <button title="Search" class="btn btn-secondary" [attr.disabled]="loading.status ? 'disabled' : null" (click)="pageQuery.changeRoute({ q: '' }); pageQuery.isSearch = false">
                                    <i class="material-icons">close</i>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row pt-2">
                    <div class="col-12">
                        <div class="text-center h4 p-3" *ngIf="!data?.data?.length">
                            <div *ngIf="loading.status">
                                <div class="spinner-grow" role="status">
                                    <span class="visually-hidden">Loading...</span>
                                </div>
                            </div>
                            <div *ngIf="!loading.status">No data found..</div>
                        </div>
                        <div class="table-responsive">
                            <table class="table table-hover" *ngIf="data?.data?.length">
                                <thead>
                                    <tr>
                                        <th class="text-center">#</th>
                                        <th>Site ID</th>
                                        <th>Site Name</th>
                                        <th>PO Customer</th>
                                        <th>Project Name</th>
                                        <th>Vendor</th>
                                        <th>Milestone #</th>
                                        <th>Milestoner Name</th>
                                        <th>Status</th>
                                        <th>Next PIC</th>
                                        <th>Updated At</th>
                                        <th class="text-center">
                                            <i class="material-icons">more_vert</i>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let item of data?.data; let i = index">
                                        <td class="text-center">
                                            {{ data.from + i }}
                                        </td>
                                        <td>
                                            <a routerLink="{{item.id}}">
                                                {{ item.site?.site_id }}
                                            </a>
                                        </td>
                                        <td>
                                            {{ item.site?.name }}
                                        </td>
                                        <td>
                                            {{ item.site?.purchase_order?.number }}
                                        </td>
                                        <td>
                                            {{ item.site?.purchase_order?.contract?.project_name }}
                                        </td>
                                        <td>
                                            {{item.vendor?.name}}
                                        </td>
                                        <td>
                                            {{item.milestone_name?.milestone_vendor?.type}}
                                        </td>
                                        <td>
                                            {{item.milestone_name?.name}}
                                        </td>
                                        <td nowrap>
                                            <span *ngIf="item.status==1" class="badge text-bg-primary">
                                                Approved
                                            </span>
                                            <span *ngIf="item.status==-1" class="badge text-bg-danger">
                                                Rejected
                                            </span>
                                            <span *ngIf="item.status==0" class="badge text-bg-warning">
                                                Waiting {{item.approvals?.[0]?.level_name || 'Unknown'}}
                                            </span>
                                            <span *ngIf="item.status==-2" class="badge text-bg-info">
                                                Waiting Update
                                            </span>
                                        </td>
                                        <td>
                                            <span *ngIf="item.status==0">
                                                {{item.approvals?.[0]?.role?.name || 'Unknown'}}
                                            </span>
                                            <span *ngIf="item.status!=0">
                                                -
                                            </span>
                                        </td>
                                        <td nowrap>
                                            {{ item.created_at | dateFormat : "DD MMM YYYY, HH:mm" }}
                                        </td>
                                        <td class="text-center" nowrap>
                                            <div class="d-flex nav-item justify-content-center" ngbDropdown display="dynamic" placement="bottom-right" container="body">
                                                <button class="btn btn-sm text-primary border-primary border-1 dropdown-toggle" ngbDropdownToggle>Action</button>
                                                <ul ngbDropdownMenu>
                                                    <a class="dropdown-item btn-link d-flex align-items-center" [class.disabled]="loading.status" title="Detail" routerLink="{{ item.id }}">
                                                        <i class="material-icons fs-5 me-3">preview</i>
                                                        Detail
                                                    </a>

                                                    <li *ngIf="permission.create">
                                                        <a class="dropdown-item btn-link d-flex align-items-center" [class.disabled]="loading.status || item.approvals?.[0]?.level_order>0 || item.status==1" title="{{item.status==-2?'Update':'Edit'}}" routerLink="{{ item.id }}/edit">
                                                            <i class="material-icons fs-5 me-3">edit</i>
                                                            {{item.status==-2?'Update':'Edit'}}
                                                        </a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div *ngIf="data?.data?.length" class="d-flex justify-content-between row small">
                            <div class="col-md-2 small d-flex flex-nowrap row align-items-center">
                                <div class="col-5 d-flex align-items-center">Limit :</div>
                                <select style="height: 25px;" class="form-select form-select-sm col-6 w-50" [attr.disabled]="loading.status ? 'disabled' : null" [(ngModel)]="pageQuery.queryPage.limit" (change)="pageQuery.changeRoute({limit:pageQuery.queryPage.limit});">
                                    <option value="10">10</option>
                                    <option value="20">20</option>
                                    <option value="50">50</option>
                                    <option value="100">100</option>
                                </select>
                            </div>
                            <div class="col-md-4 d-flex flex-nowrap align-items-center justify-content-center justify-content-md-end">
                                <div class="text-nowrap">
                                    Total: {{data.total || 0}}
                                </div>
                                <div class="text-nowrap">
                                    <button (click)="pageQuery.changeRoute({page:1});" class="btn btn-sm border-0 m-1 p-1" [attr.disabled]="loading.status || data.current_page===1 || !data.prev_page_url ? 'disabled' : null">
                                        <span class="material-icons">first_page</span>
                                    </button>
                                    <button (click)="pageQuery.changeRoute({page:(data.prev_page_url).split('page=')[1]});" class="btn btn-sm border-0 m-1 p-1" [attr.disabled]="loading.status || data.current_page===1 || !data.prev_page_url ? 'disabled' : null">
                                        <span class="material-icons">navigate_before</span>
                                    </button>
                                    <span>
                                        {{data.current_page || 1}}/{{data.last_page || '&infintie;'}}
                                    </span>
                                    <button (click)="pageQuery.changeRoute({page:(data.next_page_url).split('page=')[1]});" class="btn btn-sm border-0 m-1 p-1" [attr.disabled]="loading.status || !data.next_page_url || pageQuery.queryPage.page===data.last_page ? 'disabled' : null">
                                        <span class="material-icons">navigate_next</span>
                                    </button>
                                    <button (click)="pageQuery.changeRoute({page:data.last_page});" class="btn btn-sm border-0 m-1 p-1" [attr.disabled]="loading.status || !data.next_page_url || pageQuery.queryPage.page===data.last_page || !data.last_page ? 'disabled' : null">
                                        <span class="material-icons">last_page</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
