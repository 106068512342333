<div class="row">
    <div class="col-12">
        <div class="card">
            <div class="card-body">
                <div class="d-flex justify-content-between row">
                    <div class="row pe-0">
                        <div class="d-flex flex-nowrap align-items-center col-md-6 mb-3">
                            <button class="btn btn-secondary btn-sm me-3" (click)="back()" title="Kembali">
                                <i class="material-icons">arrow_back</i>
                            </button>
                            <h4 class="card-title col-12 lh-0 m-0">Set Paid Vendor Billing Document</h4>
                        </div>
                    </div>
                </div>

                <div *ngIf="!data.id" class="text-center">
                    <div class="spinner-grow" role="status">
                        <span class="visually-hidden">Loading...</span>
                    </div>
                </div>

                <div *ngIf="data.id" class="mt-4">
                    <div class="row">
                        <div class="col-12">
                            <div class="row">
                                <div class="col-md-6">
                                    <div class="form-floating mb-4">
                                        <input disabled type="text" [value]="data.site?.site_id" placeholder="..." class="form-control">
                                        <label>Site ID</label>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-floating mb-4">
                                        <input disabled [value]="data.site?.name" placeholder="..." class="form-control">
                                        <label>Site Name</label>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-floating mb-4">
                                        <input disabled [value]="data.site?.purchase_order?.number" placeholder="..." class="form-control">
                                        <label>PO Customer</label>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-floating mb-4">
                                        <input disabled [value]="data.vendor?.name" placeholder="..." class="form-control">
                                        <label>Vendor</label>
                                    </div>
                                </div>
                                <div class="col-6">
                                    <div class="form-floating mb-4">
                                        <input disabled [value]="data.site?.purchase_order?.contract?.project_name" placeholder="..." class="form-control">
                                        <label>Project Name</label>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-floating mb-4">
                                        <input disabled [value]="data.milestone_name?.milestone_vendor?.type" placeholder="..." class="form-control">
                                        <label>Milestone #</label>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-floating mb-4">
                                        <input type="text" disabled [value]="data.milestone_name?.name" placeholder="..." class="form-control">
                                        <label>Milestoner Name</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 mb-3">
                            Uploaded Vendor Documents :
                            <table cellpadding="5">
                                <tr *ngFor="let doc of data.documents;index as i;">
                                    <td>
                                        {{i+1}}.
                                    </td>
                                    <td>
                                        {{(doc?.document_name?.name || doc?.custom_doc_name) || '-'}}
                                    </td>
                                    <td>
                                        <button class="btn btn-sm btn-secondary" [attr.disabled]="(doc.file)?.length<=1 || loading.status?'disabled':null" (click)="downloadFile(doc.file)">Download</button>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        {{data.documents?.length+1}}.
                                    </td>
                                    <td>
                                        Certificate
                                    </td>
                                    <td>
                                        <button class="btn btn-sm btn-secondary" [attr.disabled]="loading.status?'disabled':null" (click)="downloadCrt()">Download</button>
                                    </td>
                                </tr>
                            </table>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12 mb-3">
                            <div *ngIf="data.status==1" class="d-flex align-items-center">
                                <div>Approved at : <strong>{{data.closed_at | dateFormat:'DD MMM YYYY'}}</strong></div>
                            </div>
                            <div>
                                <table class="table table-bordered">
                                    <thead>
                                        <tr>
                                            <th>
                                                #
                                            </th>
                                            <th>
                                                Document Name
                                            </th>
                                            <th>
                                                Document Date
                                            </th>
                                            <th>
                                                Uploaded Document
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <ng-container *ngFor="let item of data.details;index as i;">
                                            <tr>
                                                <td>
                                                    {{i+1}}
                                                </td>
                                                <td>
                                                    {{item.document_name}}
                                                </td>
                                                <td>
                                                    <span *ngIf="data.status>-2">
                                                        {{item.document_date | dateFormat:'DD MMM YYYY'}}
                                                    </span>
                                                </td>
                                                <td>
                                                    <button class="btn btn-secondary btn-sm" (click)="downloadFile(item.file)" [class.disabled]="loading.status">Download</button>
                                                </td>
                                            </tr>
                                        </ng-container>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div class="col-12 mb-3">
                            <h4>Set as paid</h4>
                            <div class="row">
                                <div class="col-md-6">
                                    <form #theForm="ngForm" (ngSubmit)="submitForm(formGroup)" novalidate>
                                        <div class="row" [formGroup]="formGroup">
                                            <div class="col-md-12">
                                                <div class="form-floating mb-4">
                                                    <div class="input-group">
                                                        <div class="form-floating form-floating-group flex-grow-1">
                                                            <input type="text" readonly (click)="tfDate.toggle()" ngbDatepicker #tfDate="ngbDatepicker" formControlName="paid_date" [class.is-invalid]="formGroup.get('paid_date')?.invalid && (theForm.submitted || formGroup.get('paid_date')?.touched)" placeholder="..." class="form-control border-end-0" [attr.disabled]="loading.status ? 'disabled' : null" required>
                                                            <label>Paid Date</label>
                                                        </div>
                                                        <div class="border border-start-0 d-flex align-items-center rounded-end" [class.border-danger]="formGroup.get('paid_date')?.invalid && (theForm.submitted || formGroup.get('paid_date')?.touched)">
                                                            <button type="button" class="btn btn-sm p-0 m-0 me-2 text-muted" (click)="tfDate.toggle()" [attr.disabled]="loading.status ? 'disabled' : null">
                                                                <i class="material-icons">today</i>
                                                            </button>
                                                        </div>
                                                    </div>
                                                    <div class="text-danger position-absolute w-100" *ngIf="formGroup.get('paid_date')?.invalid && (theForm.submitted || formGroup.get('paid_date')?.touched)">
                                                        <small *ngIf="formGroup.get('paid_date')?.errors?.required">Please select date</small>
                                                        <small *ngIf="formGroup.get('paid_date')?.errors?.serverError">{{ formGroup.get("paid_date")?.errors?.serverError }}</small>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-12 text-end">
                                                <button type="submit" class="btn btn-primary" [attr.disabled]="loading.status ? 'disabled' : null"><i class="material-icons">save</i> &nbsp; Save</button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
