<div class="row mb-4">
    <div class="col-sm-12 mx-auto">
        <div class="card">
            <div class="card-body">
                <div class="row">
                    <h4 class="card-title col-12 mb-4">Edit Supplier Price</h4>
                    <div class="text-center" *ngIf="!formGroup.get('id')?.value">
                        <div class="spinner-grow" role="status">
                            <span class="visually-hidden">Loading...</span>
                        </div>
                    </div>
                    <form *ngIf="formGroup.get('id')?.value" #theForm="ngForm" (ngSubmit)="submitForm(formGroup)" novalidate class="col-12">
                        <div class="row" [formGroup]="formGroup">
                            <div class="col-md-8">
                                <div class="form-floating mb-4">
                                    <ng-select class="form-control" placeholder="-" formControlName="vendor_id" loadingText="Loading..." [class.is-invalid]="formGroup.get('vendor_id')?.invalid && (theForm.submitted || formGroup.get('vendor_id')?.touched)" required>
                                        <ng-option *ngFor="let item of supplierList" [value]="item.id">{{item.name}}</ng-option>
                                    </ng-select>
                                    <label>Supplier Name</label>
                                    <div class="text-danger position-absolute text-end w-100" *ngIf="formGroup.get('vendor_id')?.invalid && (theForm.submitted || formGroup.get('vendor_id')?.touched)">
                                        <small *ngIf="formGroup.get('vendor_id')?.errors?.required">Please select supplier name</small>
                                        <small *ngIf="formGroup.get('vendor_id')?.errors?.serverError">{{ formGroup.get("vendor_id")?.errors?.serverError }}</small>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-8">
                                <table class="table">
                                    <thead>
                                        <tr>
                                            <th class="text-center">#</th>
                                            <th style="width:50%;">Material</th>
                                            <th>UoM</th>
                                            <th>Price</th>
                                            <th class="text-center"><i class="material-icons">close</i></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <ng-container formArrayName="details">
                                            <tr *ngFor="let material of materials.get()?.controls as FormArray;index as i;first as isFirst;last as isLast;" [formGroupName]="i">
                                                <td class="text-center">
                                                    {{i+1}}
                                                </td>
                                                <td>
                                                    <ng-select [items]="materialList | async" bindLabel="name" bindValue="id" formControlName="material_id" class="form-control my-2" placeholder="Search material..." [loading]="loading.status" typeToSearchText="Please enter 1 or more characters" [typeahead]="qMaterial" (change)="setUom(material);" [class.is-invalid]="material.get('material_id')?.invalid && (theForm.submitted || material.get('material_id')?.touched)" required>
                                                        <ng-template class="d-flex" ng-option-tmp let-item="item">
                                                            <div class="text-wrap">{{item.code}} - {{item.name}}</div>
                                                        </ng-template>
                                                    </ng-select>
                                                    <div class="text-danger position-absolute" style="margin-top: -11px;" *ngIf="material.get('material_id')?.invalid && (theForm.submitted || material.get('material_id')?.touched)">
                                                        <small *ngIf="material.get('material_id')?.errors?.required">Please select material</small>
                                                        <small *ngIf="material.get('material_id')?.errors?.serverError">{{ material.get("material_id")?.errors?.serverError }}</small>
                                                    </div>
                                                </td>
                                                <td>
                                                    <input type="text" formControlName="uom" class="form-control my-2" readonly>
                                                </td>
                                                <td>
                                                    <input type="text" ngxNumberFormat="###,###,###,###,###" formControlName="price" class="form-control my-2" [class.is-invalid]="material.get('price')?.invalid && (theForm.submitted || material.get('price')?.touched)" required>
                                                    <div class="text-danger position-absolute" style="margin-top: -11px;" *ngIf="material.get('price')?.invalid && (theForm.submitted || material.get('price')?.touched)">
                                                        <small *ngIf="material.get('price')?.errors?.required">Please type price</small>
                                                        <small *ngIf="material.get('price')?.errors?.serverError">{{ material.get("price")?.errors?.serverError }}</small>
                                                    </div>
                                                </td>
                                                <td class="text-center">
                                                    <button type="button" class="btn btn-sm btn-danger my-2" (click)="materials.remove(i)">
                                                        <i class="material-icons">close</i>
                                                    </button>
                                                </td>
                                            </tr>
                                        </ng-container>
                                    </tbody>
                                </table>
                                <div class="mb-4">
                                    <button type="button" class="btn btn-sm btn-secondary" (click)="materials.add()">
                                        <i class="material-icons">add</i>&nbsp;Add Material(s)
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-8">
                                <div class="d-flex justify-content-between align-items-center border-top pt-1">
                                    <div>&nbsp;</div>
                                    <div class="text-end">
                                        <button type="button" class="btn btn-secondary" (click)="back()" [attr.disabled]="loading.status ? 'disabled' : null"><i class="material-icons">close</i> &nbsp; Cancel</button>
                                        &nbsp;
                                        <button type="submit" class="btn btn-primary" [attr.disabled]="loading.status ? 'disabled' : null"><i class="material-icons">save</i> &nbsp; Save</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>
