import { Location } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { DatePickerParserService } from 'src/app/services/date-picker-parser.service';
import { FileHandlerService } from 'src/app/services/file-handler.service';
import { HttpService } from 'src/app/services/http.service';
import { LoadingService } from 'src/app/services/loading.service';
import { environment } from 'src/environments/environment';
import { Lightbox } from 'ngx-lightbox';

@Component({
    selector: 'app-customer-progress-detail',
    templateUrl: './customer-progress-detail.component.html',
    styleUrls: ['./customer-progress-detail.component.scss']
})
export class CustomerProgressDetailComponent implements OnInit {

    constructor(
        private route: ActivatedRoute,
        private http: HttpService,
        private location: Location,
        private dateParse: DatePickerParserService,
        public fileHandler: FileHandlerService,
        public loading: LoadingService,
        private toastr: ToastrService,
        private modalService: NgbModal,
        private lightBox: Lightbox
    ) {
        this.route.params.subscribe(e => {
            this.id = e.id;
            this.route.queryParams.subscribe(q => {
                const p = new URLSearchParams(q).toString();
                if (p) {
                    this.id += '?' + p;
                }
            });
        });
    }
    @ViewChild('progressModal') progressModal: any;
    @ViewChild('approveModal') approveModal: any;
    @ViewChild('rejectModal') rejectModal: any;
    @ViewChild('photoModal') photoModal: any;
    private apiPath = 'customer-progress';
    id: any;
    data: any = {};
    assetUrl = environment.assetUrl;
    permission: any = {};

    photoDialog: any = {
        modal: null,
        url: null,
        show: (url: any) => {
            this.photoDialog.url = url;
            this.photoDialog.modal = this.modalService.open(this.photoModal, { keyboard: false, backdrop: 'static', centered: true });
        }
    };

    approveDialog: any = {
        modal: null,
        id: null,
        show: (id: any) => {
            this.approveDialog.id = id;
            this.approveDialog.modal = this.modalService.open(this.approveModal, { keyboard: false, backdrop: 'static', centered: true });
        },
        submit: () => {
            let fdata = new FormData();
            fdata.append('id', this.approveDialog.id);
            this.http.Post(this.apiPath + '/approve', fdata).then((r: any) => {
                if (r.success) {
                    this.toastr.success("Data approved successfully", 'Success');
                    this.ngOnInit();
                    this.approveDialog.modal.close();
                    this.progressDialog.modal.close();
                }
            })
        }
    }

    rejectDialog: any = {
        modal: null,
        remarks: null,
        id: null,
        show: (id: any) => {
            this.rejectDialog.id = id;
            this.rejectDialog.modal = this.modalService.open(this.rejectModal, { keyboard: false, backdrop: 'static', centered: true });
        },
        submit: () => {
            let fdata = new FormData();
            fdata.append('id', this.rejectDialog.id);
            fdata.append('remarks', this.rejectDialog.remarks);
            this.http.Post(this.apiPath + '/reject', fdata).then((r: any) => {
                if (r.success) {
                    this.toastr.success("Data rejected successfully", 'Success');
                    this.ngOnInit();
                    this.rejectDialog.modal.close();
                    this.progressDialog.modal.close();
                }
            })
        }
    }

    progressDialog: any = {
        modal: null,
        data: {},
        sow: null,
        isDetail: false,
        detail: {},
        item: {},
        browsePhoto(i: number): void {
            document.getElementById(`input-file-${i}`)?.click();
        },
        addPhoto: (p: any) => {
            setTimeout(() => {
                if (p.file && this.progressDialog.photos.length < 3) {
                    this.progressDialog.photos.push({ file: {} });
                }
            }, 500);
        },
        removePhoto: (i: number) => {
            if (this.progressDialog.photos[i]?.id) {
                this.progressDialog.data.deleted.photo.push(this.progressDialog.photos[i]?.id);
            }
            this.progressDialog.photos.splice(i, 1);
            if (!this.progressDialog.photos.length) {
                this.progressDialog.photos.push({ file: {} });
            }
            if (this.progressDialog.photos.length < 3) {
                this.progressDialog.photos.push({ file: {} });
            }
        },
        photos: [{ file: {} }],
        show: (data: any, detail?: any, isDetail?: boolean) => {
            this.progressDialog.item = data;
            this.progressDialog.detail = detail;
            this.progressDialog.isDetail = true;
            this.progressDialog.sow = data.sow?.customer_boq?.sow_name;
            this.progressDialog.data = {
                id: detail?.id || null,
                customer_progress_sow_id: data.id,
                qty: detail?.qty || null,
                notes: detail?.notes || null,
                progress_date: detail?.progress_date,
                is_done: detail?.is_done || null,
                deleted_photo: []
            };
            if (detail?.photos?.length) {
                this.progressDialog.photos = [];
                detail.photos?.forEach((e: any) => {
                    this.progressDialog.photos.push({ id: e.id, file: { view: environment.assetUrl + e.file } })
                });
            }
            this.progressDialog.modal = this.modalService.open(this.progressModal, { keyboard: false, backdrop: 'static', centered: true, size: 'lg' });
        },
        submit: () => {
            if (!this.progressDialog.data.progress_date_v) {
                this.toastr.error('Please type date', 'Invalid Form');
                return;
            }
            if (Number(this.progressDialog.data.qty) <= 0) {
                this.toastr.error('Please type qty', 'Invalid Form');
                return;
            }
            if (Number(this.progressDialog.data.qty) > (this.progressDialog.item.sow.customer_boq.qty - this.progressDialog.item.total_qty)) {
                this.toastr.error('Maximum qty is ' + (this.progressDialog.item.sow.customer_boq.qty - this.progressDialog.item.total_qty));
                return;
            }
            if (!this.progressDialog.data.id && !this.progressDialog.photos?.filter((e: any) => e.file?.file).length) {
                this.toastr.error('Please upload photo', 'Invalid Form');
                return;
            }
            let fdata = new FormData();
            this.progressDialog.photos?.filter((e: any) => e.file?.file).forEach((e: any) => {
                fdata.append('photo[]', e.file?.file);
            });
            this.progressDialog.data.progress_date = this.dateParse.toYMD(this.progressDialog.data.progress_date_v);
            fdata.append('data', JSON.stringify(this.progressDialog.data));
            const t = this.progressDialog.data.id ? 'update' : 'create';
            this.http.Post(this.apiPath + '/' + t, fdata).then((r: any) => {
                if (r.success) {
                    this.toastr.success("Data saved successfully", 'Success');
                    this.ngOnInit();
                    this.progressDialog.modal.close();
                }
            })
        }
    }

    rejectRemarks(data: any): any {
        const appr = (data?.approvals?.find((e: any) => e.status == -1)) || {};
        return appr.remarks || null;
    }

    nextApprovals(data: any): any {
        return data?.filter((e: any) => e.status == 0);
    }

    back(): void {
        this.location.back();
    }

    openImg(photos: any[]): void {
        const album: any = photos.map((e: any, i: number) => {
            return {
                src: environment.assetUrl + e.file
            };
        });
        this.lightBox.open(album);
    }

    ngOnInit(): void {
        this.http.Get(this.apiPath + '/' + this.id, { update: true }).then((r: any) => {
            if (r.success && r?.response?.result?.data?.id) {
                this.data = r?.response?.result?.data;
                this.permission = r?.permission || {};
            } else {
                this.toastr.error('Data not found', 'Error');
                this.back();
            }
        });

    }
}
