<div class="row mb-4">
    <div class="col-sm-12 mx-auto">
        <div class="card">
            <div class="card-body">
                <div class="row">
                    <div class="d-flex flex-nowrap align-items-center">
                        <button class="btn btn-secondary btn-sm me-3" (click)="back()" title="Back">
                            <i class="material-icons">arrow_back</i>
                        </button>
                        <h4 class="card-title col-12 lh-0 m-0">Detail Purchase Order</h4>
                    </div>
                    <div class="text-center" *ngIf="!data.id">
                        <div class="spinner-grow" role="status">
                            <span class="visually-hidden">Loading...</span>
                        </div>
                    </div>
                    <div *ngIf="data.id" class="col-md-12 mt-3">
                        <ul ngbNav #nav="ngbNav" class="nav-tabs">
                            <li ngbNavItem>
                                <a ngbNavLink>PO Information</a>
                                <ng-template ngbNavContent>
                                    <div class="row mt-3">
                                        <div class="col-12">
                                            <div class="form-floating mb-4">
                                                <input type="text" [value]="data.contract?.customer?.name" placeholder="..." class="form-control" readonly>
                                                <label>Customer</label>
                                            </div>
                                            <div class="form-floating mb-4">
                                                <input type="text" [value]="data.contract?.contract_number || '-'" placeholder="..." class="form-control" readonly>
                                                <label>Contract</label>
                                            </div>
                                            <div class="form-floating mb-4">
                                                <input type="text" [value]="data.contract?.project_name || '-'" placeholder="..." class="form-control" readonly>
                                                <label>Project Name</label>
                                            </div>
                                            <div class="form-floating mb-4">
                                                <input type="text" [value]="data.number || '-'" placeholder="..." class="form-control" readonly>
                                                <label>PO Number</label>
                                            </div>
                                            <div class="form-floating mb-4">
                                                <input type="text" [value]="data.status==1?'Active':'Inactive'" placeholder="..." class="form-control" readonly>
                                                <label>Status</label>
                                            </div>
                                            <div class="form-floating mb-4">
                                                <input type="text" [value]="data.created_at | dateFormat" placeholder="..." class="form-control" readonly>
                                                <label>Created at</label>
                                            </div>
                                            <div class="form-floating mb-4">
                                                <input type="text" [value]="data.creator?.name || '-'" placeholder="..." class="form-control" readonly>
                                                <label>Created By</label>
                                            </div>
                                            <div class="form-floating mb-4">
                                                <input type="text" [value]="(data.last_updated_at | dateFormat) || '-'" placeholder="..." class="form-control" readonly>
                                                <label>Last Updated at</label>
                                            </div>
                                            <div class="form-floating mb-4">
                                                <input type="text" [value]="data.updater?.name ||'-'" placeholder="..." class="form-control" readonly>
                                                <label>Last Updated By</label>
                                            </div>
                                        </div>
                                    </div>
                                </ng-template>
                            </li>
                            <li ngbNavItem>
                                <a ngbNavLink>Customer BOQ</a>
                                <ng-template ngbNavContent>
                                    <div class="row mt-3">
                                        <div class="col-12">
                                            <table class="table">
                                                <thead>
                                                    <tr>
                                                        <th class="text-center">#</th>
                                                        <th style="width:30%;">SoW</th>
                                                        <th class="text-end">Price</th>
                                                        <th>UoM</th>
                                                        <th class="text-end">Qty</th>
                                                        <th>Notes</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr *ngFor="let customer_boq of data.customer_boqs;index as i;">
                                                        <td class="text-center">
                                                            {{i+1}}
                                                        </td>
                                                        <td>
                                                            {{customer_boq.sow_name}}
                                                        </td>
                                                        <td class="text-end">
                                                            {{customer_boq.price | number}}
                                                        </td>
                                                        <td>
                                                            {{customer_boq.uom}}
                                                        </td>
                                                        <td class="text-end">
                                                            {{customer_boq.qty | number}}
                                                        </td>
                                                        <td>
                                                            {{customer_boq.notes || '-'}}
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </ng-template>
                            </li>
                            <li ngbNavItem>
                                <a ngbNavLink>Materials</a>
                                <ng-template ngbNavContent>
                                    <div class="row mt-3">
                                        <div class="col-12">
                                            <table class="table">
                                                <thead>
                                                    <tr>
                                                        <th class="text-center">#</th>
                                                        <th style="width:30%;">Material Name</th>
                                                        <th class="text-end">Reference Price</th>
                                                        <th>UoM</th>
                                                        <th class="text-end">Target Price</th>
                                                        <th class="text-end">Qty</th>
                                                    </tr>
                                                </thead>
                                                <tbody>

                                                    <tr *ngFor="let material of data.materials;index as i;">
                                                        <td class="text-center">
                                                            {{i+1}}
                                                        </td>
                                                        <td>
                                                            {{material?.material?.code}} - {{material?.material?.name}}
                                                        </td>
                                                        <td class="text-end">
                                                            {{material.ref_price | number}}
                                                        </td>
                                                        <td>
                                                            {{material.uom}}
                                                        </td>
                                                        <td class="text-end">
                                                            {{material.price | number}}
                                                        </td>
                                                        <td class="text-end">
                                                            {{material.qty | number}}
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </ng-template>
                            </li>
                            <li ngbNavItem>
                                <a ngbNavLink>Vendor BOQ</a>
                                <ng-template ngbNavContent>
                                    <div class="row mt-3">
                                        <div class="col-12">
                                            <table class="table">
                                                <thead>
                                                    <tr>
                                                        <th class="text-center">#</th>
                                                        <th style="width:30%;">Customer SoW Ref.</th>
                                                        <th style="width:30%;">SoW</th>
                                                        <th class="text-end">Target Price</th>
                                                        <th>UoM</th>
                                                        <th class="text-end">Qty</th>
                                                        <th>Notes</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr *ngFor="let vendor_boq of data.vendor_boqs;index as i;">
                                                        <td class="text-center">
                                                            {{i+1}}
                                                        </td>
                                                        <td>
                                                            {{vendor_boq.customer_boq?.sow_name}}
                                                        </td>
                                                        <td>
                                                            {{vendor_boq.sow_name}}
                                                        </td>
                                                        <td class="text-end">
                                                            {{vendor_boq.price | number}}
                                                        </td>
                                                        <td>
                                                            {{vendor_boq.uom}}
                                                        </td>
                                                        <td class="text-end">
                                                            {{vendor_boq.qty | number}}
                                                        </td>
                                                        <td>
                                                            {{vendor_boq.notes || '-'}}
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </ng-template>
                            </li>
                            <li ngbNavItem>
                                <a ngbNavLink>Milestone Customer</a>
                                <ng-template ngbNavContent>
                                    <div class="row mt-3">
                                        <div class="col-12">
                                            <ul>
                                                <li *ngFor="let milestone_customer of data.milestone_customers;index as i;" class="mb-3">
                                                    <div class="h6 p-0 mb-2">Milestone #{{numToAplha(i)}}</div>
                                                    <ul class="border-bottom mb-4">
                                                        <li *ngFor="let sow of milestone_customer.sows;index as sowI;" class="mb-4">
                                                            <div class="form-floating">
                                                                <input type="text" [value]="sow?.customer_boq?.sow_name || '-'" placeholder="..." class="form-control" readonly>
                                                                <label>Customer SoW #{{sowI+1}}</label>
                                                            </div>
                                                        </li>
                                                    </ul>
                                                    <ul>
                                                        <li *ngFor="let name of milestone_customer.names;index as nameI;" class="mb-4">
                                                            <div class="form-floating">
                                                                <input type="text" [value]="name?.name || '-'" placeholder="..." class="form-control" readonly>
                                                                <label>Milestoner Name #{{nameI+1}}</label>
                                                            </div>
                                                            <div>Documents: </div>
                                                            <ul *ngIf="!name.documents.length">
                                                                <li><em>No document Needed</em></li>
                                                            </ul>
                                                            <ol *ngIf="name.documents.length">
                                                                <li *ngFor="let doc of name.documents;">
                                                                    <a class="cursor-pointer text-decoration-underline" (click)="downloadFile(doc.file)">{{doc.name}}</a>
                                                                </li>
                                                            </ol>
                                                        </li>
                                                    </ul>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </ng-template>
                            </li>
                            <li ngbNavItem>
                                <a ngbNavLink>Milestone Vendor</a>
                                <ng-template ngbNavContent>
                                    <div class="row mt-3">
                                        <div class="col-12">
                                            <ul>
                                                <li *ngFor="let milestone_vendor of data.milestone_vendors;index as i;" class="mb-3">
                                                    <div class="h6 p-0 mb-2">Milestone #{{numToAplha(i)}}</div>
                                                    <ul class="border-bottom mb-4">
                                                        <li *ngFor="let sow of milestone_vendor.sows;index as sowI;" class="mb-4">
                                                            <div class="form-floating">
                                                                <input type="text" [value]="sow?.vendor_boq?.sow_name || '-'" placeholder="..." class="form-control" readonly>
                                                                <label>Vendor SoW #{{sowI+1}}</label>
                                                            </div>
                                                        </li>
                                                    </ul>
                                                    <ul>
                                                        <li *ngFor="let name of milestone_vendor.names;index as nameI;" class="mb-4">
                                                            <div class="form-floating">
                                                                <input type="text" [value]="name?.name || '-'" placeholder="..." class="form-control" readonly>
                                                                <label>Milestoner Name #{{nameI+1}}</label>
                                                            </div>
                                                            <div>Documents: </div>
                                                            <ul *ngIf="!name.documents.length">
                                                                <li><em>No document Needed</em></li>
                                                            </ul>
                                                            <ol *ngIf="name.documents.length">
                                                                <li *ngFor="let doc of name.documents;">
                                                                    <a class="cursor-pointer text-decoration-underline" (click)="downloadFile(doc.file)">{{doc.name}}</a>
                                                                </li>
                                                            </ol>
                                                        </li>
                                                    </ul>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </ng-template>
                            </li>
                        </ul>
                        <div [ngbNavOutlet]="nav"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
