import { Location } from '@angular/common';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormArray, FormControl, FormGroup, NgForm } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { v4 as uuidv4 } from 'uuid';
import { BehaviorSubject, Subject, delay, distinctUntilChanged, merge } from 'rxjs';
import { FileHandlerService } from 'src/app/services/file-handler.service';
import { HttpService } from 'src/app/services/http.service';
import { LoadingService } from 'src/app/services/loading.service';
import { environment } from 'src/environments/environment';
import { AuthService } from 'src/app/services/auth.service';
import { UomService } from 'src/app/services/uom.service';

@Component({
    selector: 'app-purchase-order-edit',
    templateUrl: './purchase-order-edit.component.html',
    styleUrls: ['./purchase-order-edit.component.scss']
})
export class PurchaseOrderEditComponent implements OnInit {

    constructor(
        private auth: AuthService,
        private route: ActivatedRoute,
        private http: HttpService,
        private location: Location,
        public fileHandler: FileHandlerService,
        public loading: LoadingService,
        private modalService: NgbModal,
        private el: ElementRef,
        private uom: UomService,
        private toastr: ToastrService
    ) {
        this.route.params.subscribe(e => {
            this.id = e.id;
        });
    }
    private apiPath = 'purchase-orders';
    id: any;
    @ViewChild('theForm') theForm: NgForm | undefined;
    @ViewChild('documentModal') documentModal: any;
    @ViewChild('confirmExitModal') confirmExitModal: any;
    formGroup!: FormGroup[];
    formChanged = false;
    formStep = 0;
    contractList: any[] = [];
    customerList: any = [];
    materialList = new BehaviorSubject<any[] | null>(null);
    qMaterial = new Subject<string>();
    milestoneCustomerDocs: any = [];
    milestoneVendorDocs: any = [];
    uomList = this.uom.getUoMList();

    documentDialog: any = {
        title: null,
        data: [],
        formGroup: null,
        modal: null,
        browseFile: (ref: string, i: number) => {
            document.getElementById(`file-input-${ref}-${i}`)?.click();
        },
        addDoc: () => {
            this.documentDialog.data.push({
                name: '',
                file: {},
                ref_id: uuidv4()
            });
        },
        removeDoc: (i: number) => {
            this.documentDialog.data.splice(i, 1);
            if (!this.documentDialog.data.length) {
                this.documentDialog.addDoc();
            }
        },
        show: (title: any, fg: FormGroup) => {
            this.documentDialog.formGroup = fg;
            this.documentDialog.data = this.documentDialog.formGroup?.value || [];
            if (!this.documentDialog.data.length) {
                this.documentDialog.addDoc();
            } else {
                this.documentDialog.data = this.documentDialog.data.map((e: any) => {
                    const fileName = e.file;
                    if (typeof (fileName) == 'string') {
                        e.file = { file: { name: fileName, downloadable: true } };
                    }
                    return e;
                });
            }
            this.documentDialog.title = title;
            this.documentDialog.modal = this.modalService.open(this.documentModal, { keyboard: false, backdrop: 'static', centered: true, size: 'lg' });
        },
        submit: () => {
            this.documentDialog.formGroup.value = this.documentDialog.data.filter((e: any) => e.name && e.file?.file).map((e: any) => {
                delete e.file.view;
                delete e.file.loading;
                return e;
            });
            this.documentDialog.formGroup.setValue(this.documentDialog.formGroup.value);
            this.documentDialog.modal.close();
        }
    };

    submitForm(): void {
        if (!this.formGroup[this.formStep].valid) {
            const err_el = this.el.nativeElement.querySelectorAll('.fg-' + this.formStep + ' [formcontrolname].ng-invalid')?.[0];
            if (err_el) {
                err_el.focus();
                err_el.scrollIntoView();
            }
            return;
        }
        if (this.formStep < 5) {
            this.formStep++;
            this.formGroup[this.formStep].markAsUntouched();
            this.theForm?.resetForm();
            this.el.nativeElement.querySelectorAll('.fg-' + this.formStep)?.[0]?.scrollIntoView();
            return;
        }
        let fdata = new FormData();
        let formData = {
            ...this.formGroup[0].value,
            ...this.formGroup[1].value,
            ...this.formGroup[2].value,
            ...this.formGroup[3].value,
            ...this.formGroup[4].value,
            ...this.formGroup[5].value
        };
        formData.status = Number(formData.status);
        formData.have_site_name = Number(formData.have_site_name);
        formData.milestone_customers?.forEach((e: any) => {
            e.names?.forEach((n: any) => {
                n.documents?.filter((d: any) => d?.file?.file && !d.file?.file?.downloadable).forEach((d: any) => {
                    const ext = d.file?.file?.name?.split('.').pop();
                    fdata.append('milestone_customer_documents[]', d.file?.file, d.ref_id + '.' + ext);
                })
            })
        });
        formData.milestone_vendors?.forEach((e: any) => {
            e.names?.forEach((n: any) => {
                n.documents?.filter((d: any) => d?.file?.file && !d.file?.file?.downloadable).forEach((d: any) => {
                    const ext = d.file?.file?.name?.split('.').pop();
                    fdata.append('milestone_vendor_documents[]', d.file?.file, d.ref_id + '.' + ext);
                })
            })
        });
        fdata.append('data', JSON.stringify(formData));
        this.formGroup[this.formStep].disable();
        this.http.Post(this.apiPath + '/update', fdata).then((r: any) => {
            this.formGroup[this.formStep].enable();
            if (r.success) {
                this.formChanged = false;
                this.toastr.success('Data saved successfully', 'Success');
                this.location.back();
            } else {
                if (r.response && r.response.wrong) {
                    Object.keys(r.response.wrong).forEach((key) => {
                        this.formGroup[this.formStep].get(key)?.setErrors({ serverError: r.response.wrong[key][0] });
                        this.el.nativeElement.querySelectorAll('[formcontrolname="' + key + '"]')?.[0]?.focus();
                    });
                }
            }
        });
    }

    back(): void {
        if (this.formStep > 0) {
            this.formStep--;
            return;
        }
        this.location.back();
    }

    changeStep(i: number): void {
        if (i > this.formStep && !this.formGroup[this.formStep].valid) {
            const err_el = this.el.nativeElement.querySelectorAll('.fg-' + this.formStep + ' [formcontrolname].ng-invalid')?.[0];
            if (err_el) {
                err_el.focus();
                err_el.scrollIntoView();
            }
            return;
        }
        this.formStep = i;
    }

    async getCustomer(): Promise<void> {
        const r = await this.http.Get('customers', { filter: { status: 1}, forceView: true });
        this.customerList = r.response?.result?.data || [];
    }

    async getContract(): Promise<void> {
        if (!this.formGroup[0].get('customer_id')?.value) {
            this.formGroup[0].get('contract_id')?.reset();
            this.formGroup[0].get('contract_id')?.disable();
        }
        const r = await this.http.Get('contracts', { filter: { status: 1, customer_id: this.formGroup[0].get('customer_id')?.value }, forceView: true });
        this.contractList = r.response?.result?.data || [];
        this.formGroup[0].get('contract_id')?.enable();
    }

    async getMaterial(q: string): Promise<void> {
        let r = await this.http.Get('materials', { q: q, limit: 15, filter: { status: 1 }, with_price: true, forceView: true });
        this.materialList.next(r?.response?.result?.data || []);
    }

    setMaterial(fg: any, i: number): void {
        const material_id = fg.get('material_id')?.value;
        const material = (JSON.parse(JSON.stringify(this.materialList.getValue())) || []).find((e: any) => e.id == material_id) || {};
        fg.get('uom')?.setValue(material.uom);
        const prices = material.prices?.sort((a: any, b: any) => a.price - b.price) || [{}];
        fg.get('ref_price')?.setValue(prices?.[0]?.price);
        fg.get('supplier_price_detail_id')?.setValue(prices?.[0]?.id);
        const hasMaterial = this.materials.get().controls.filter(e => e.get('material_id')?.value == material_id);
        if (hasMaterial.length > 1 && i > 0) {
            const cVal = hasMaterial[0].get('qty')?.value;
            hasMaterial[0].get('qty')?.setValue(Number(cVal) + 1);
            this.materials.remove(i);
        }
    }

    customerBoqs = {
        get: () => {
            return this.formGroup[1].get('customer_boqs') as FormArray;
        },
        add: () => {
            const items = this.customerBoqs.get();
            items.push(new FormGroup({
                sow_name: new FormControl(),
                qty: new FormControl(),
                uom: new FormControl(),
                price: new FormControl(),
                ref_id: new FormControl(uuidv4()),
                notes: new FormControl()
            }));
        },
        remove: (i: number) => {
            const items = this.customerBoqs.get();
            items.removeAt(i);
            if (!items.length) {
                this.customerBoqs.add();
            }
        }
    };

    vendorBoqs = {
        get: () => {
            return this.formGroup[3].get('vendor_boqs') as FormArray;
        },
        add: () => {
            const items = this.vendorBoqs.get();
            items.push(new FormGroup({
                customer_ref: new FormControl(),
                sow_name: new FormControl(),
                qty: new FormControl(),
                uom: new FormControl(),
                price: new FormControl(),
                ref_id: new FormControl(uuidv4()),
                notes: new FormControl()
            }));
        },
        remove: (i: number) => {
            const items = this.vendorBoqs.get();
            items.removeAt(i);
            if (!items.length) {
                this.vendorBoqs.add();
            }
        }
    };

    materials = {
        get: () => {
            return this.formGroup[2].get('materials') as FormArray;
        },
        add: () => {
            const items = this.materials.get();
            items.push(new FormGroup({
                material_id: new FormControl(),
                supplier_price_detail_id: new FormControl(),
                ref_price: new FormControl(),
                uom: new FormControl(),
                qty: new FormControl(),
                price: new FormControl()
            }));
        },
        remove: (i: number) => {
            const items = this.materials.get();
            items.removeAt(i);
            if (!items.length) {
                this.materials.add();
            }
        }
    };

    asFormArray(varName: any): FormArray {
        return varName as FormArray;
    }

    customer_ref = {
        add: (varName: any) => {
            const items = this.asFormArray(varName);
            items.push(new FormGroup({
                customer_ref: new FormControl()
            }));
        },
        remove: (varName: any, i: number) => {
            const items = this.asFormArray(varName);
            items.removeAt(i);
            if (!items.length) {
                this.customer_ref.add(varName);
            }
        }
    };

    sow_names = {
        add: (varName: any) => {
            const items = this.asFormArray(varName);
            items.push(new FormGroup({
                name: new FormControl(),
                is_need_document: new FormControl(0),
                documents: new FormControl([])
            }));
        },
        remove: (varName: any, i: number) => {
            const items = this.asFormArray(varName);
            items.removeAt(i);
            if (!items.length) {
                this.sow_names.add(varName);
            }
        }
    };

    milestone_customers = {
        get: () => {
            return this.formGroup[4].get('milestone_customers') as FormArray;
        },
        add: () => {
            const items = this.milestone_customers.get();
            items.push(new FormGroup({
                sows: new FormArray([
                    new FormGroup({
                        customer_ref: new FormControl()
                    })
                ]),
                names: new FormArray([
                    new FormGroup({
                        name: new FormControl(),
                        is_need_document: new FormControl(0),
                        documents: new FormControl([])
                    })
                ])
            }))
        },
        remove: (i: number) => {
            const items = this.milestone_customers.get();
            items.removeAt(i);
            if (!items.length) {
                this.milestone_customers.add();
            }
        }
    };

    vendor_ref = {
        add: (varName: any) => {
            const items = this.asFormArray(varName);
            items.push(new FormGroup({
                vendor_ref: new FormControl()
            }));
        },
        remove: (varName: any, i: number) => {
            const items = this.asFormArray(varName);
            items.removeAt(i);
            if (!items.length) {
                this.customer_ref.add(varName);
            }
        }
    };

    milestone_vendors = {
        get: () => {
            return this.formGroup[5].get('milestone_vendors') as FormArray;
        },
        add: () => {
            const items = this.milestone_vendors.get();
            items.push(new FormGroup({
                sows: new FormArray([
                    new FormGroup({
                        vendor_ref: new FormControl()
                    })
                ]),
                names: new FormArray([
                    new FormGroup({
                        name: new FormControl(),
                        is_need_document: new FormControl(0),
                        documents: new FormControl([])
                    })
                ])
            }))
        },
        remove: (i: number) => {
            const items = this.milestone_vendors.get();
            items.removeAt(i);
            if (!items.length) {
                this.milestone_vendors.add();
            }
        }
    };

    getCustomerSow(): any[] {
        return this.customerBoqs.get().controls.map((e) => e.value).filter((e) => e.sow_name);
    }

    getVendorSow(): any[] {
        return this.vendorBoqs.get().controls.map((e) => e.value).filter((e) => e.sow_name);
    }

    numToAplha(n: number): string {
        return String.fromCharCode(97 + n).toUpperCase();
    }

    parepareForm(data: any, key: string): any {
        return data[key]?.map((e: any) => {
            let fb: any = {};
            Object.keys(e).forEach((v: any) => {
                if ((e[v]) instanceof Array && v != 'documents') {
                    fb[v] = new FormArray(this.parepareForm(e, v));
                } else {
                    fb[v] = new FormControl(e[v]);
                }
            });
            return new FormGroup(fb);
        });
    }

    ngOnInit(): void {
        this.http.Get(this.apiPath + '/' + this.id, {}).then((r: any) => {
            if (r.success && r?.response?.result?.data?.id) {
                const data = r?.response?.result?.data;
                this.formGroup = [
                    new FormGroup({}),// 0 => _po_
                    new FormGroup({}),// 1 => customer_boqs
                    new FormGroup({}),// 2 => materials
                    new FormGroup({}),// 3 => vendor_boqs
                    new FormGroup({}),// 4 => milestone_customers
                    new FormGroup({}) // 5 => milestone_vendors
                ];

                Object.keys(data).forEach((key) => {
                    if (key == 'materials') {
                        const materials = (data[key]).map((e: any) => e.material);
                        this.materialList.next(materials);
                        this.formGroup[2].addControl(key, new FormArray(this.parepareForm(data, key)));
                    } else if (key == 'customer_boqs') {
                        this.formGroup[1].addControl(key, new FormArray(this.parepareForm(data, key)));
                    } else if (key == 'vendor_boqs') {
                        this.formGroup[3].addControl(key, new FormArray(this.parepareForm(data, key)));
                    } else if (key == 'milestone_customers') {
                        this.formGroup[4].addControl(key, new FormArray(this.parepareForm(data, key)));
                    } else if (key == 'milestone_vendors') {
                        this.formGroup[5].addControl(key, new FormArray(this.parepareForm(data, key)));
                    } else {
                        if (data[key]?.customer) {
                            this.formGroup[0].addControl('customer_id', new FormControl(data[key]?.customer_id));
                        }
                        this.formGroup[0].addControl(key, new FormControl(data[key]));
                    }
                });
                this.getCustomer();
                this.getContract();
                this.qMaterial.pipe(delay(100), distinctUntilChanged()).subscribe((q: any) => {
                    if (!q) {
                        this.materialList.next([]);
                        return;
                    }
                    this.getMaterial(q);
                });

                merge(this.formGroup[0].valueChanges,
                    this.formGroup[1].valueChanges,
                    this.formGroup[2].valueChanges,
                    this.formGroup[3].valueChanges,
                    this.formGroup[4].valueChanges,
                    this.formGroup[5].valueChanges)
                    .subscribe(() => {
                        this.formChanged = true;
                    });

            } else {
                this.location.back();
            }
        });

    }

    ngOnDestroy(): void {
        this.qMaterial.unsubscribe();
    }

    downloadFile(file: string): void {
        const url = environment.apiUrl + '/download/' + file + '?token=' + this.auth.tokenData.access_token;
        window.open(url, '_blank');
    }

    async canExit(): Promise<boolean> {
        if (!this.formChanged) {
            return true;
        }
        const modal = await this.modalService.open(this.confirmExitModal, { keyboard: false, backdrop: 'static', centered: true }).result;
        return modal;
    }

}

