<div class="row mb-4">
    <div class="col-sm-12 mx-auto">
        <div class="card">
            <div class="card-body">
                <div class="row">
                    <h4 class="card-title col-12 mb-4">Add Contract</h4>
                    <form #theForm="ngForm" (ngSubmit)="submitForm(formGroup)" novalidate class="col-md-8">
                        <div [formGroup]="formGroup">
                            <div class="form-floating mb-4">
                                <ng-select class="form-control" placeholder="-" formControlName="customer_id" loadingText="Loading..." [class.is-invalid]="formGroup.get('customer_id')?.invalid && (theForm.submitted || formGroup.get('customer_id')?.touched)" required>
                                    <ng-option *ngFor="let item of customerList" [value]="item.id">{{item.name}}</ng-option>
                                </ng-select>
                                <label class="required">Customer</label>
                                <div class="text-danger position-absolute text-end w-100" *ngIf="formGroup.get('customer_id')?.invalid && (theForm.submitted || formGroup.get('customer_id')?.touched)">
                                    <small *ngIf="formGroup.get('customer_id')?.errors?.required">Please select customer</small>
                                    <small *ngIf="formGroup.get('customer_id')?.errors?.serverError">{{ formGroup.get("customer_id")?.errors?.serverError }}</small>
                                </div>
                            </div>
                            <div class="form-floating mb-4">
                                <input type="text" formControlName="contract_number" [class.is-invalid]="formGroup.get('contract_number')?.invalid && (theForm.submitted || formGroup.get('contract_number')?.touched)" placeholder="..." class="form-control" required>
                                <label>Contract Number</label>
                                <div class="text-danger position-absolute text-end w-100" *ngIf="formGroup.get('contract_number')?.invalid && (theForm.submitted || formGroup.get('contract_number')?.touched)">
                                    <small *ngIf="formGroup.get('contract_number')?.errors?.required">Please type contract number</small>
                                    <small *ngIf="formGroup.get('contract_number')?.errors?.serverError">{{ formGroup.get("contract_number")?.errors?.serverError }}</small>
                                </div>
                            </div>
                            <div class="form-floating mb-4">
                                <input type="text" formControlName="project_name" [class.is-invalid]="formGroup.get('project_name')?.invalid && (theForm.submitted || formGroup.get('project_name')?.touched)" placeholder="..." class="form-control" required>
                                <label>Project Name</label>
                                <div class="text-danger position-absolute text-end w-100" *ngIf="formGroup.get('project_name')?.invalid && (theForm.submitted || formGroup.get('project_name')?.touched)">
                                    <small *ngIf="formGroup.get('project_name')?.errors?.required">Please type project name</small>
                                    <small *ngIf="formGroup.get('project_name')?.errors?.serverError">{{ formGroup.get("project_name")?.errors?.serverError }}</small>
                                </div>
                            </div>
                            <div class="form-floating mb-4">
                                <div class="input-group">
                                    <div class="form-floating form-floating-group flex-grow-1">
                                        <input type="text" readonly (click)="ctDate.toggle()" ngbDatepicker #ctDate="ngbDatepicker" formControlName="contract_date" [class.is-invalid]="formGroup.get('contract_date')?.invalid && (theForm.submitted || formGroup.get('contract_date')?.touched)" placeholder="..." class="form-control border-end-0" [attr.disabled]="loading.status ? 'disabled' : null" required>
                                        <label>Contract Date</label>
                                    </div>
                                    <div class="border border-start-0 d-flex align-items-center rounded-end" [class.border-danger]="formGroup.get('contract_date')?.invalid && (theForm.submitted || formGroup.get('contract_date')?.touched)">
                                        <button type="button" class="btn btn-sm p-0 m-0 me-2 text-muted" (click)="ctDate.toggle()" [attr.disabled]="loading.status ? 'disabled' : null">
                                            <i class="material-icons">today</i>
                                        </button>
                                    </div>
                                </div>
                                <div class="text-danger position-absolute text-end w-100" *ngIf="formGroup.get('contract_date')?.invalid && (theForm.submitted || formGroup.get('contract_date')?.touched)">
                                    <small *ngIf="formGroup.get('contract_date')?.errors?.required">Please select contract date</small>
                                    <small *ngIf="formGroup.get('contract_date')?.errors?.serverError">{{ formGroup.get("contract_date")?.errors?.serverError }}</small>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-6">
                                    <div class="form-floating mb-4">
                                        <div class="input-group">
                                            <div class="form-floating form-floating-group flex-grow-1">
                                                <input type="text" readonly (click)="stDate.toggle()" ngbDatepicker #stDate="ngbDatepicker" formControlName="start_date" [class.is-invalid]="formGroup.get('start_date')?.invalid && (theForm.submitted || formGroup.get('start_date')?.touched)" placeholder="..." class="form-control border-end-0" [attr.disabled]="loading.status ? 'disabled' : null">
                                                <label>Start Date</label>
                                            </div>
                                            <div class="border border-start-0 d-flex align-items-center rounded-end" [class.border-danger]="formGroup.get('start_date')?.invalid && (theForm.submitted || formGroup.get('start_date')?.touched)">
                                                <button type="button" class="btn btn-sm p-0 m-0 me-2 text-muted" (click)="stDate.toggle()" [attr.disabled]="loading.status ? 'disabled' : null">
                                                    <i class="material-icons">today</i>
                                                </button>
                                            </div>
                                        </div>
                                        <div class="text-danger position-absolute text-end w-100" *ngIf="formGroup.get('start_date')?.invalid && (theForm.submitted || formGroup.get('start_date')?.touched)">
                                            <small *ngIf="formGroup.get('start_date')?.errors?.required">Please select start date</small>
                                            <small *ngIf="formGroup.get('start_date')?.errors?.serverError">{{ formGroup.get("start_date")?.errors?.serverError }}</small>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-floating mb-4">
                                        <div class="input-group">
                                            <div class="form-floating form-floating-group flex-grow-1">
                                                <input type="text" readonly (click)="endDate.toggle()" ngbDatepicker #endDate="ngbDatepicker" formControlName="end_date" [class.is-invalid]="formGroup.get('end_date')?.invalid && (theForm.submitted || formGroup.get('end_date')?.touched)" placeholder="..." class="form-control border-end-0" [attr.disabled]="loading.status ? 'disabled' : null">
                                                <label>End Date</label>
                                            </div>
                                            <div class="border border-start-0 d-flex align-items-center rounded-end" [class.border-danger]="formGroup.get('end_date')?.invalid && (theForm.submitted || formGroup.get('end_date')?.touched)">
                                                <button type="button" class="btn btn-sm p-0 m-0 me-2 text-muted" (click)="endDate.toggle()" [attr.disabled]="loading.status ? 'disabled' : null">
                                                    <i class="material-icons">today</i>
                                                </button>
                                            </div>
                                        </div>
                                        <div class="text-danger position-absolute text-end w-100" *ngIf="formGroup.get('end_date')?.invalid && (theForm.submitted || formGroup.get('end_date')?.touched)">
                                            <small *ngIf="formGroup.get('end_date')?.errors?.required">Please select end date</small>
                                            <small *ngIf="formGroup.get('end_date')?.errors?.serverError">{{ formGroup.get("end_date")?.errors?.serverError }}</small>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="form-check form-switch mb-4">
                                <input class="form-check-input" type="checkbox" formControlName="status" id="flexSwitchCheckDefault" />
                                <label class="form-check-label" for="flexSwitchCheckDefault">Status : <strong>{{ formGroup.get("status")?.value ? "Active" : "Inactive" }}</strong></label>
                            </div>
                            <div *ngIf="documents.get()?.length" class="mb-4">
                                <table class="table">
                                    <thead>
                                        <tr>
                                            <th class="text-center">#</th>
                                            <th style="width:47%;">Document Name</th>
                                            <th style="width:47%;">Upload File</th>
                                            <th>&times;</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let doc of documents.get();index as i;">
                                            <td class="text-center">
                                                {{i+1}}
                                            </td>
                                            <td>
                                                <input type="text" [(ngModel)]="doc.name" [ngModelOptions]="{standalone:true}" class="form-control my-2" required>
                                            </td>
                                            <td>
                                                <div class="input-group">
                                                    <input type="text" [value]="doc.file?.file?.name || ''" (click)="documents.browseFile(i);" class="form-control" readonly placeholder="Select document file">
                                                    <button class="btn btn-outline-secondary" type="button" (click)="documents.browseFile(i);">
                                                        <i class="material-icons">file_upload</i>
                                                    </button>
                                                </div>
                                                <input type="file" class="form-control d-none" id="file-input-{{i}}" accept="application/pdf,image/jpg,image/jpg,image/png" (change)="fileHandler.handle($event, doc.file, 'application/pdf,image/jpg,image/jpeg,image/png', 2)">
                                            </td>
                                            <td class="text-center">
                                                <button class="btn btn-danger btn-sm p-0" (click)="documents.remove(i)">
                                                    <i class="material-icons">close</i>
                                                </button>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div class="mb-4">
                                <button class="btn btn-sm btn-secondary" type="button" (click)="documents.add()">Add Documents</button>
                            </div>
                        </div>
                        <div class="d-flex justify-content-between align-items-center border-top pt-1">
                            <div>&nbsp;</div>
                            <div class="text-end">
                                <button type="button" class="btn btn-secondary" (click)="back()" [attr.disabled]="loading.status ? 'disabled' : null"><i class="material-icons">close</i> &nbsp; Cancel</button>
                                &nbsp;
                                <button type="submit" class="btn btn-primary" [attr.disabled]="loading.status ? 'disabled' : null"><i class="material-icons">save</i> &nbsp; Save</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>
