import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { AfterLoginGuard, BeforeLoginGuard, CanDeactivateGuard } from './services/guard.service';
import { LoginComponent } from './components/auth/login/login.component';
import { ForgotPasswordComponent } from './components/auth/forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './components/auth/reset-password/reset-password.component';
import { MenuViewComponent } from './components/menu/menu-view/menu-view.component';
import { MenuEditComponent } from './components/menu/menu-edit/menu-edit.component';
import { MenuAddComponent } from './components/menu/menu-add/menu-add.component';
import { ContractViewComponent } from './components/contract/contract-view/contract-view.component';
import { ContractAddComponent } from './components/contract/contract-add/contract-add.component';
import { ContractEditComponent } from './components/contract/contract-edit/contract-edit.component';
import { ContractDetailComponent } from './components/contract/contract-detail/contract-detail.component';
import { UserViewComponent } from './components/user/user-view/user-view.component';
import { UserAddComponent } from './components/user/user-add/user-add.component';
import { UserEditComponent } from './components/user/user-edit/user-edit.component';
import { UserDetailComponent } from './components/user/user-detail/user-detail.component';
import { RoleViewComponent } from './components/role/role-view/role-view.component';
import { RoleAddComponent } from './components/role/role-add/role-add.component';
import { RoleEditComponent } from './components/role/role-edit/role-edit.component';
import { RoleDetailComponent } from './components/role/role-detail/role-detail.component';
import { VendorViewComponent } from './components/vendor/vendor-view/vendor-view.component';
import { VendorAddComponent } from './components/vendor/vendor-add/vendor-add.component';
import { VendorEditComponent } from './components/vendor/vendor-edit/vendor-edit.component';
import { VendorDetailComponent } from './components/vendor/vendor-detail/vendor-detail.component';
import { MaterialViewComponent } from './components/material/material-view/material-view.component';
import { MaterialAddComponent } from './components/material/material-add/material-add.component';
import { MaterialEditComponent } from './components/material/material-edit/material-edit.component';
import { MaterialDetailComponent } from './components/material/material-detail/material-detail.component';
import { PurchaseOrderViewComponent } from './components/purchase-order/purchase-order-view/purchase-order-view.component';
import { PurchaseOrderAddComponent } from './components/purchase-order/purchase-order-add/purchase-order-add.component';
import { PurchaseOrderEditComponent } from './components/purchase-order/purchase-order-edit/purchase-order-edit.component';
import { PurchaseOrderDetailComponent } from './components/purchase-order/purchase-order-detail/purchase-order-detail.component';
import { SupplierPriceViewComponent } from './components/supplier-price/supplier-price-view/supplier-price-view.component';
import { SupplierPriceAddComponent } from './components/supplier-price/supplier-price-add/supplier-price-add.component';
import { SupplierPriceEditComponent } from './components/supplier-price/supplier-price-edit/supplier-price-edit.component';
import { SupplierPriceDetailComponent } from './components/supplier-price/supplier-price-detail/supplier-price-detail.component';
import { VendorPriceViewComponent } from './components/vendor-price/vendor-price-view/vendor-price-view.component';
import { VendorPricePriceListComponent } from './components/vendor-price/vendor-price-price-list/vendor-price-price-list.component';
import { VendorPriceVendorListComponent } from './components/vendor-price/vendor-price-vendor-list/vendor-price-vendor-list.component';
import { ApprovalSettingViewComponent } from './components/approval-setting/approval-setting-view/approval-setting-view.component';
import { ApprovalSettingAddComponent } from './components/approval-setting/approval-setting-add/approval-setting-add.component';
import { ApprovalSettingEditComponent } from './components/approval-setting/approval-setting-edit/approval-setting-edit.component';
import { ApprovalSettingDetailComponent } from './components/approval-setting/approval-setting-detail/approval-setting-detail.component';
import { PrSupplierViewComponent } from './components/pr-supplier/pr-supplier-view/pr-supplier-view.component';
import { PrSupplierAddComponent } from './components/pr-supplier/pr-supplier-add/pr-supplier-add.component';
import { PrSupplierEditComponent } from './components/pr-supplier/pr-supplier-edit/pr-supplier-edit.component';
import { PrSupplierDetailComponent } from './components/pr-supplier/pr-supplier-detail/pr-supplier-detail.component';
import { PoSupplierViewComponent } from './components/po-supplier/po-supplier-view/po-supplier-view.component';
import { PoSupplierDetailComponent } from './components/po-supplier/po-supplier-detail/po-supplier-detail.component';
import { CustomerProgressViewComponent } from './components/customer-progress/customer-progress-view/customer-progress-view.component';
import { CustomerProgressUpdateComponent } from './components/customer-progress/customer-progress-update/customer-progress-update.component';
import { CustomerProgressDetailComponent } from './components/customer-progress/customer-progress-detail/customer-progress-detail.component';
import { VendorProgressViewComponent } from './components/vendor-progress/vendor-progress-view/vendor-progress-view.component';
import { VendorProgressUpdateComponent } from './components/vendor-progress/vendor-progress-update/vendor-progress-update.component';
import { VendorProgressDetailComponent } from './components/vendor-progress/vendor-progress-detail/vendor-progress-detail.component';
import { CustomerDocumentViewComponent } from './components/customer-document/customer-document-view/customer-document-view.component';
import { CustomerDocumentDetailComponent } from './components/customer-document/customer-document-detail/customer-document-detail.component';
import { VendorDocumentViewComponent } from './components/vendor-document/vendor-document-view/vendor-document-view.component';
import { VendorDocumentDetailComponent } from './components/vendor-document/vendor-document-detail/vendor-document-detail.component';
import { CustomerDocumentUpdateComponent } from './components/customer-document/customer-document-update/customer-document-update.component';
import { VendorDocumentUpdateComponent } from './components/vendor-document/vendor-document-update/vendor-document-update.component';
import { CustomerBillingDocumentViewComponent } from './components/customer-billing-document/customer-billing-document-view/customer-billing-document-view.component';
import { CustomerBillingDocumentUpdateComponent } from './components/customer-billing-document/customer-billing-document-update/customer-billing-document-update.component';
import { CustomerBillingDocumentDetailComponent } from './components/customer-billing-document/customer-billing-document-detail/customer-billing-document-detail.component';
import { VendorBillingDocumentViewComponent } from './components/vendor-billing-document/vendor-billing-document-view/vendor-billing-document-view.component';
import { VendorBillingDocumentUpdateComponent } from './components/vendor-billing-document/vendor-billing-document-update/vendor-billing-document-update.component';
import { VendorBillingDocumentDetailComponent } from './components/vendor-billing-document/vendor-billing-document-detail/vendor-billing-document-detail.component';
import { NotificationComponent } from './components/notification/notification/notification.component';
import { PrVendorViewComponent } from './components/pr-vendor/pr-vendor-view/pr-vendor-view.component';
import { PrVendorAddComponent } from './components/pr-vendor/pr-vendor-add/pr-vendor-add.component';
import { PrVendorEditComponent } from './components/pr-vendor/pr-vendor-edit/pr-vendor-edit.component';
import { PrVendorDetailComponent } from './components/pr-vendor/pr-vendor-detail/pr-vendor-detail.component';
import { UserVendorViewComponent } from './components/user-vendor/user-vendor-view/user-vendor-view.component';
import { UserVendorAddComponent } from './components/user-vendor/user-vendor-add/user-vendor-add.component';
import { UserVendorEditComponent } from './components/user-vendor/user-vendor-edit/user-vendor-edit.component';
import { UserVendorDetailComponent } from './components/user-vendor/user-vendor-detail/user-vendor-detail.component';
import { PoVendorViewComponent } from './components/po-vendor/po-vendor-view/po-vendor-view.component';
import { PoVendorUpdateComponent } from './components/po-vendor/po-vendor-update/po-vendor-update.component';
import { PoVendorDetailComponent } from './components/po-vendor/po-vendor-detail/po-vendor-detail.component';
import { AccountComponent } from './components/account/account.component';
import { PoAdditionalPriceComponent } from './components/po-additional-price/po-additional-price.component';
import { PoAdditionalViewComponent } from './components/po-additional/po-additional-view/po-additional-view.component';
import { PoAdditionalAddComponent } from './components/po-additional/po-additional-add/po-additional-add.component';
import { PoAdditionalEditComponent } from './components/po-additional/po-additional-edit/po-additional-edit.component';
import { PoAdditionalDetailComponent } from './components/po-additional/po-additional-detail/po-additional-detail.component';
import { ReportVendorPriceComponent } from './components/report-vendor-price/report-vendor-price.component';
import { ReportSupplierPriceComponent } from './components/report-supplier-price/report-supplier-price.component';
import { VendorBillingDocumentPaidComponent } from './components/vendor-billing-document/vendor-billing-document-paid/vendor-billing-document-paid.component';
import { CustomerViewComponent } from './components/customer/customer-view/customer-view.component';
import { CustomerAddComponent } from './components/customer/customer-add/customer-add.component';
import { CustomerEditComponent } from './components/customer/customer-edit/customer-edit.component';
import { CustomerDetailComponent } from './components/customer/customer-detail/customer-detail.component';

const routes: Routes = [
    { path: '', redirectTo: '/dashboard', pathMatch: 'full', data: { title: 'Home' } },
    { path: 'dashboard', component: DashboardComponent, canActivate: [AfterLoginGuard], data: { title: 'Dashboard' } },
    { path: 'login', component: LoginComponent, canActivate: [BeforeLoginGuard], data: { title: 'Login' } },
    { path: 'forgot-password', component: ForgotPasswordComponent, canActivate: [BeforeLoginGuard], data: { title: 'Forgot Password' } },
    { path: 'reset-password/:id', component: ResetPasswordComponent, canActivate: [BeforeLoginGuard], data: { title: 'Reset Password' } },
    {
        path: 'menu', canActivate: [AfterLoginGuard],
        children: [
            { path: '', component: MenuViewComponent, data: { title: 'Menu Navigation' } },
            { path: 'add', component: MenuAddComponent, data: { title: 'Add Menu' } },
            { path: ':id/edit', component: MenuEditComponent, data: { title: 'Edit Menu' } }
        ]
    },
    {
        path: 'contracts', canActivate: [AfterLoginGuard],
        children: [
            { path: '', component: ContractViewComponent, data: { title: 'Contract' } },
            { path: 'add', component: ContractAddComponent, data: { title: 'Add Contract' } },
            { path: ':id/edit', component: ContractEditComponent, data: { title: 'Edit Contract' } },
            { path: ':id', component: ContractDetailComponent, data: { title: 'Detail Contract' } }
        ]
    },
    {
        path: 'users', canActivate: [AfterLoginGuard],
        children: [
            { path: '', component: UserViewComponent, data: { title: 'User Account' } },
            { path: 'add', component: UserAddComponent, data: { title: 'Add User Account' } },
            { path: ':id/edit', component: UserEditComponent, data: { title: 'Edit User Account' } },
            { path: ':id', component: UserDetailComponent, data: { title: 'Detail User Account' } }
        ]
    },
    {
        path: 'roles', canActivate: [AfterLoginGuard],
        children: [
            { path: '', component: RoleViewComponent, data: { title: 'User Role' } },
            { path: 'add', component: RoleAddComponent, data: { title: 'Add User Role' } },
            { path: ':id/edit', component: RoleEditComponent, data: { title: 'Edit User Role' } },
            { path: ':id', component: RoleDetailComponent, data: { title: 'Detail User Role' } }
        ]
    },
    {
        path: 'vendors', canActivate: [AfterLoginGuard],
        children: [
            { path: '', component: VendorViewComponent, data: { title: 'Vendor & Supplier' } },
            { path: 'add', component: VendorAddComponent, data: { title: 'Add Vendor & Supplier' } },
            { path: ':id/edit', component: VendorEditComponent, data: { title: 'Edit Vendor & Supplier' } },
            { path: ':id', component: VendorDetailComponent, data: { title: 'Detail Vendor & Supplier' } }
        ]
    },
    {
        path: 'materials', canActivate: [AfterLoginGuard],
        children: [
            { path: '', component: MaterialViewComponent, data: { title: 'Material Item' } },
            { path: 'add', component: MaterialAddComponent, data: { title: 'Add Material Item' } },
            { path: ':id/edit', component: MaterialEditComponent, data: { title: 'Edit Material Item' } },
            { path: ':id', component: MaterialDetailComponent, data: { title: 'Detail Material Item' } }
        ]
    },
    {
        path: 'purchase-orders', canActivate: [AfterLoginGuard],
        children: [
            { path: '', component: PurchaseOrderViewComponent, data: { title: 'Purchase Order' } },
            { path: 'add', component: PurchaseOrderAddComponent, canDeactivate: [CanDeactivateGuard], data: { title: 'Add Purchase Order' } },
            { path: ':id/edit', component: PurchaseOrderEditComponent, canDeactivate: [CanDeactivateGuard], data: { title: 'Edit Purchase Order' } },
            { path: ':id', component: PurchaseOrderDetailComponent, data: { title: 'Detail Purchase Order' } }
        ]
    },
    {
        path: 'supplier-prices', canActivate: [AfterLoginGuard],
        children: [
            { path: '', component: SupplierPriceViewComponent, data: { title: 'Supplier Price List' } },
            { path: 'add', component: SupplierPriceAddComponent, data: { title: 'Add Supplier Price' } },
            { path: ':id/edit', component: SupplierPriceEditComponent, data: { title: 'Edit Supplier Price' } },
            { path: ':id', component: SupplierPriceDetailComponent, data: { title: 'Detail Supplier Price' } }
        ]
    },
    {
        path: 'vendor-prices', canActivate: [AfterLoginGuard],
        children: [
            { path: '', component: VendorPriceViewComponent, data: { title: 'Vendor Price List' } },
            { path: ':id/price-list', component: VendorPricePriceListComponent, data: { title: 'Price List' } },
            { path: ':id/vendor-list', component: VendorPriceVendorListComponent, data: { title: 'Vendor List' } }
        ]
    },
    {
        path: 'approvals', canActivate: [AfterLoginGuard],
        children: [
            { path: '', component: ApprovalSettingViewComponent, data: { title: 'Approval Settings' } },
            { path: 'x-add', component: ApprovalSettingAddComponent, data: { title: 'Add Approval Settings' } },
            { path: ':id/edit', component: ApprovalSettingEditComponent, data: { title: 'Edit Approval Settings' } },
            { path: ':id', component: ApprovalSettingDetailComponent, data: { title: 'Detail Approval Settings' } }
        ]
    },
    {
        path: 'pr-suppliers', canActivate: [AfterLoginGuard],
        children: [
            { path: '', component: PrSupplierViewComponent, data: { title: 'Supplier PR' } },
            { path: 'add', component: PrSupplierAddComponent, data: { title: 'Add Supplier PR' } },
            { path: ':id/edit', component: PrSupplierEditComponent, data: { title: 'Edit Supplier PR' } },
            { path: ':id', component: PrSupplierDetailComponent, data: { title: 'Detail Supplier PR' } }
        ]
    },
    {
        path: 'po-suppliers', canActivate: [AfterLoginGuard],
        children: [
            { path: '', component: PoSupplierViewComponent, data: { title: 'PO Supplier' } },
            { path: ':id', component: PoSupplierDetailComponent, data: { title: 'Detail PO Supplier' } }
        ]
    },
    {
        path: 'customer-progress', canActivate: [AfterLoginGuard],
        children: [
            { path: '', component: CustomerProgressViewComponent, data: { title: 'Customer Progress' } },
            { path: ':id/edit', component: CustomerProgressUpdateComponent, data: { title: 'Update Customer Progress' } },
            { path: ':id', component: CustomerProgressDetailComponent, data: { title: 'Detail Customer Progress' } }
        ]
    },
    {
        path: 'vendor-progress', canActivate: [AfterLoginGuard],
        children: [
            { path: '', component: VendorProgressViewComponent, data: { title: 'Vendor Progress' } },
            { path: ':id/edit', component: VendorProgressUpdateComponent, data: { title: 'Update Vendor Progress' } },
            { path: ':id', component: VendorProgressDetailComponent, data: { title: 'Detail Vendor Progress' } }
        ]
    },
    {
        path: 'customer-document', canActivate: [AfterLoginGuard],
        children: [
            { path: '', component: CustomerDocumentViewComponent, data: { title: 'Customer Document' } },
            { path: ':id/edit', component: CustomerDocumentUpdateComponent, data: { title: 'Update Customer Document' } },
            { path: ':id', component: CustomerDocumentDetailComponent, data: { title: 'Detail Customer Document' } }
        ]
    },
    {
        path: 'vendor-document', canActivate: [AfterLoginGuard],
        children: [
            { path: '', component: VendorDocumentViewComponent, data: { title: 'Vendor Document' } },
            { path: ':id/edit', component: VendorDocumentUpdateComponent, data: { title: 'Update Vendor Document' } },
            { path: ':id', component: VendorDocumentDetailComponent, data: { title: 'Detail Vendor Document' } }
        ]
    },
    {
        path: 'customer-billing-docs', canActivate: [AfterLoginGuard],
        children: [
            { path: '', component: CustomerBillingDocumentViewComponent, data: { title: 'Customer Billing Document' } },
            { path: ':id/edit', component: CustomerBillingDocumentUpdateComponent, data: { title: 'Update Customer Billing Document' } },
            { path: ':id', component: CustomerBillingDocumentDetailComponent, data: { title: 'Detail Customer Billing Document' } }
        ]
    },
    {
        path: 'vendor-billing-docs', canActivate: [AfterLoginGuard],
        children: [
            { path: '', component: VendorBillingDocumentViewComponent, data: { title: 'Vendor Billing Document' } },
            { path: ':id/edit', component: VendorBillingDocumentUpdateComponent, data: { title: 'Update Vendor Billing Document' } },
            { path: ':id/paid', component: VendorBillingDocumentPaidComponent, data: { title: 'Set Paid Vendor Billing Document' } },
            { path: ':id', component: VendorBillingDocumentDetailComponent, data: { title: 'Detail Vendor Billing Document' } }
        ]
    },
    { path: 'notifications', component: NotificationComponent, canActivate: [AfterLoginGuard], data: { title: 'Notification' } },
    {
        path: 'pr-vendors', canActivate: [AfterLoginGuard],
        children: [
            { path: '', component: PrVendorViewComponent, data: { title: 'Vendor PR' } },
            { path: 'add', component: PrVendorAddComponent, data: { title: 'Add Vendor PR' } },
            { path: ':id/edit', component: PrVendorEditComponent, data: { title: 'Edit Vendor PR' } },
            { path: ':id', component: PrVendorDetailComponent, data: { title: 'Detail Vendor PR' } }
        ]
    },
    {
        path: 'user-vendors', canActivate: [AfterLoginGuard],
        children: [
            { path: '', component: UserVendorViewComponent, data: { title: 'User Vendor' } },
            { path: 'add', component: UserVendorAddComponent, data: { title: 'Add User Vendor' } },
            { path: ':id/edit', component: UserVendorEditComponent, data: { title: 'Edit User Vendor' } },
            { path: ':id', component: UserVendorDetailComponent, data: { title: 'Detail User Vendor' } }
        ]
    },
    {
        path: 'po-vendors', canActivate: [AfterLoginGuard],
        children: [
            { path: '', component: PoVendorViewComponent, data: { title: 'PO Vendor' } },
            { path: ':id/edit', component: PoVendorUpdateComponent, data: { title: 'Opname PO Vendor' } },
            { path: ':id', component: PoVendorDetailComponent, data: { title: 'Detail PO Vendor' } }
        ]
    },
    { path: 'account', component: AccountComponent, canActivate: [AfterLoginGuard], data: { title: 'Account' } },
    { path: 'po-additional-price', component: PoAdditionalPriceComponent, canActivate: [AfterLoginGuard], data: { title: 'PO Additional Price' } },
    {
        path: 'po-additionals', canActivate: [AfterLoginGuard],
        children: [
            { path: '', component: PoAdditionalViewComponent, data: { title: 'PO Additional' } },
            { path: 'add', component: PoAdditionalAddComponent, data: { title: 'Add PO Additional' } },
            { path: ':id/edit', component: PoAdditionalEditComponent, data: { title: 'Edit PO Additional' } },
            { path: ':id', component: PoAdditionalDetailComponent, data: { title: 'Detail PO Additional' } }
        ]
    },
    { path: 'report-vendor-price', component: ReportVendorPriceComponent, canActivate: [AfterLoginGuard], data: { title: 'Report Vendor Price' } },
    { path: 'report-supplier-price', component: ReportSupplierPriceComponent, canActivate: [AfterLoginGuard], data: { title: 'Report Supplier Price' } },
    {
        path: 'customers', canActivate: [AfterLoginGuard],
        children: [
            { path: '', component: CustomerViewComponent, data: { title: 'Customer' } },
            { path: 'add', component: CustomerAddComponent, data: { title: 'Add Customer' } },
            { path: ':id/edit', component: CustomerEditComponent, data: { title: 'Edit Customer' } },
            { path: ':id', component: CustomerDetailComponent, data: { title: 'Detail Customer' } }
        ]
    },

    { path: '**', redirectTo: '/dashboard', pathMatch: 'full' },
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule { }
