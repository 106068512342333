import { Location } from '@angular/common';
import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormArray, FormControl, FormGroup, NgForm } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject, Subject, delay, distinctUntilChanged } from 'rxjs';
import { HttpService } from 'src/app/services/http.service';
import { LoadingService } from 'src/app/services/loading.service';

@Component({
    selector: 'app-supplier-price-add',
    templateUrl: './supplier-price-add.component.html',
    styleUrls: ['./supplier-price-add.component.scss']
})
export class SupplierPriceAddComponent implements OnInit, OnDestroy {

    constructor(
        private http: HttpService,
        private el: ElementRef,
        private location: Location,
        public loading: LoadingService,
        private toastr: ToastrService) { }
    @ViewChild('theForm') theForm: NgForm | undefined;
    private apiPath = 'supplier-prices';
    stayPage: boolean = false;
    formGroup = new FormGroup({
        vendor_id: new FormControl(),
        details: new FormArray([
            new FormGroup({
                material_id: new FormControl(),
                uom: new FormControl(),
                price: new FormControl()
            })
        ])
    });
    formChanged = false;
    supplierList: any[] = [];
    materialList = new BehaviorSubject<any[] | null>(null);
    qMaterial = new Subject<string>();

    submitForm(form: any): void {
        if (!form.valid) {
            this.el.nativeElement.querySelectorAll('[formcontrolname].ng-invalid')?.[0]?.focus();
            return;
        }
        form.value.status = Number(form.value.status);
        let fdata = new FormData();
        fdata.append('data', JSON.stringify(form.value));
        form.disable();
        this.http.Post(this.apiPath + '/create', fdata).then((r: any) => {
            form.enable();
            if (r.success) {
                this.toastr.success('Data saved successfully', 'Success');
                this.back();
            } else {
                if (r.response && r.response.wrong) {
                    Object.keys(r.response.wrong).forEach((key) => {
                        if (key != 'id') {
                            form.get(key)?.setErrors({ serverError: r.response.wrong[key][0] });
                            this.el.nativeElement.querySelectorAll('[formcontrolname="' + key + '"]')?.[0]?.focus();
                        }
                    });
                }
            }
        });
    }

    back(): void {
        this.location.back();
    }

    async getSupplier(): Promise<void> {
        const r = await this.http.Get('vendors', { filter: { status: 1, type: 'material' }, forceView: true });
        this.supplierList = r.response?.result?.data || [];
    }

    async getMaterial(q: any): Promise<void> {
        let r = await this.http.Get('materials', { q: q, limit: 15, filter: { status: 1 }, with_price: true, forceView: true });
        this.materialList.next(r.response?.result?.data || []);
    }

    setUom(fg: any): void {
        const material = (JSON.parse(JSON.stringify(this.materialList.getValue())) || []).find((e: any) => e.id == fg.controls?.material_id?.value) || {};
        fg.get('uom')?.setValue(material?.uom);
    }

    materials = {
        get: () => {
            return this.formGroup.get('details') as FormArray;
        },
        add: () => {
            const items = this.materials.get();
            items.push(new FormGroup({
                material_id: new FormControl(),
                uom: new FormControl(),
                price: new FormControl()
            }));
        },
        remove: (i: number) => {
            const items = this.materials.get();
            items.removeAt(i);
            if (!items.length) {
                this.materials.add();
            }
        }
    };

    ngOnInit(): void {
        this.getSupplier();
        this.qMaterial.pipe(delay(100), distinctUntilChanged()).subscribe((q: any) => {
            if (!q) {
                this.materialList.next([]);
                return;
            }
            this.getMaterial(q);
        });
        const oldValue = this.formGroup.value;
        this.formGroup.valueChanges.subscribe((e: any) => {
            this.formChanged = (oldValue != e);
        });
    }

    ngOnDestroy(): void {
        this.qMaterial.unsubscribe();
    }

}
