import { Location } from '@angular/common';
import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormControl, NgForm } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject, Subject, delay, distinctUntilChanged } from 'rxjs';
import { HttpService } from 'src/app/services/http.service';
import { LoadingService } from 'src/app/services/loading.service';

@Component({
    selector: 'app-supplier-price-edit',
    templateUrl: './supplier-price-edit.component.html',
    styleUrls: ['./supplier-price-edit.component.scss']
})
export class SupplierPriceEditComponent implements OnInit, OnDestroy {

    constructor(
        private route: ActivatedRoute,
        private http: HttpService,
        private location: Location,
        public loading: LoadingService,
        private el: ElementRef,
        private fb: FormBuilder,
        private toastr: ToastrService
    ) {
        this.route.params.subscribe(e => {
            this.id = e.id;
        });
    }
    private apiPath = 'supplier-prices';
    id: any;
    @ViewChild('theForm') theForm: NgForm | undefined;
    formGroup = this.fb.group({});
    formChanged = false;
    supplierList: any[] = [];
    materialList = new BehaviorSubject<any[] | null>(null);
    qMaterial = new Subject<string>();

    submitForm(form: any): void {
        if (!form.valid) {
            this.el.nativeElement.querySelectorAll('[formcontrolname].ng-invalid')?.[0]?.focus();
            return;
        }

        let fdata = new FormData();
        form.value.status = Number(form.value.status);
        fdata.append('data', JSON.stringify(form.value));
        form.disable();
        this.http.Post(this.apiPath + '/update', fdata).then((r: any) => {
            form.enable();
            if (r.success) {
                this.toastr.success('Data saved successfully', 'Success');
                this.back();
            } else {
                if (r.response && r.response.wrong) {
                    Object.keys(r.response.wrong).forEach((key) => {
                        form.get(key)?.setErrors({ serverError: r.response.wrong[key][0] });
                        this.el.nativeElement.querySelectorAll('[formcontrolname="' + key + '"]')?.[0]?.focus();
                    });
                }
            }
        });
    }

    back(): void {
        this.location.back();
    }

    async getSupplier(): Promise<void> {
        const r = await this.http.Get('vendors', { filter: { status: 1, type: 'material' }, forceView: true });
        this.supplierList = r.response?.result?.data || [];
    }

    async getMaterial(q: any): Promise<void> {
        let r = await this.http.Get('materials', { q: q, limit: 15, filter: { status: 1 }, with_price: true, forceView: true });
        this.materialList.next(r.response?.result?.data || []);
    }

    setUom(fg: any): void {
        const material = (JSON.parse(JSON.stringify(this.materialList.getValue())) || []).find((e: any) => e.id == fg.controls?.material_id?.value) || {};
        fg.get('uom')?.setValue(material?.uom);
    }

    materials = {
        get: () => {
            return this.formGroup.get('details') as FormArray | null;
        },
        add: () => {
            const items = this.materials.get();
            items?.push(this.fb.group({
                material_id: new FormControl(),
                uom: new FormControl(),
                price: new FormControl()
            }));
        },
        remove: (i: number) => {
            const items = this.materials.get();
            items?.removeAt(i);
            if (!items?.length) {
                this.materials.add();
            }
        }
    };

    ngOnInit(): void {
        this.http.Get(this.apiPath + '/' + this.id, {}).then((r: any) => {
            if (r.success && r?.response?.result?.data?.id) {
                this.getSupplier();
                Object.keys(r?.response?.result?.data).forEach((key) => {
                    if (key == 'details') {
                        let details: any[] = [];
                        let dtl = r.response.result.data.details || [];
                        dtl = !dtl.length ? [{}] : dtl;
                        let material: any[] = [];
                        dtl.forEach((e: any) => {
                            material.push(e?.material);
                            details.push(this.fb.group({
                                material_id: new FormControl(e.material_id),
                                uom: new FormControl(e.material?.uom),
                                price: new FormControl(e.price),
                                qty: new FormControl(e.qty)
                            }))
                        });
                        this.materialList.next(material);
                        this.formGroup.addControl(key, new FormArray(details));
                    } else {
                        this.formGroup.addControl(key, new FormControl(r?.response?.result?.data[key]));
                    }
                });

                this.qMaterial.pipe(delay(100), distinctUntilChanged()).subscribe((q: any) => {
                    if (!q) {
                        this.materialList.next([]);
                        return;
                    }
                    this.getMaterial(q);
                });
            } else {
                this.back();
            }
        });

    }

    ngOnDestroy(): void {
        this.qMaterial.unsubscribe();
    }

}

