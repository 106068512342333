<div class="row mb-4">
    <div class="col-sm-12 mx-auto">
        <div class="card">
            <div class="card-body">
                <div class="row">
                    <h4 class="card-title col-12 mb-4">Edit Approval Settings</h4>
                    <div class="text-center" *ngIf="!formGroup.get('id')?.value">
                        <div class="spinner-grow" role="status">
                            <span class="visually-hidden">Loading...</span>
                        </div>
                    </div>
                    <form *ngIf="formGroup.get('id')?.value" #theForm="ngForm" (ngSubmit)="submitForm(formGroup)" novalidate class="col-md-12">
                        <div [formGroup]="formGroup">
                            <div class="form-floating mb-4">
                                <input type="text" [value]="formGroup.get('name')?.value" readonly [class.is-invalid]="formGroup.get('name')?.invalid && (theForm.submitted || formGroup.get('name')?.touched)" placeholder="..." class="form-control" required>
                                <label>Name</label>
                                <div class="text-danger position-absolute text-end w-100" *ngIf="formGroup.get('name')?.invalid && (theForm.submitted || formGroup.get('name')?.touched)">
                                    <small *ngIf="formGroup.get('name')?.errors?.required">Please type name</small>
                                    <small *ngIf="formGroup.get('name')?.errors?.serverError">{{formGroup.get('name')?.errors?.serverError}}</small>
                                </div>
                            </div>
                            <div class="form-check form-switch mb-4">
                                <input class="form-check-input" type="checkbox" formControlName="status" id="flexSwitchCheckDefault">
                                <label class="form-check-label" for="flexSwitchCheckDefault">Status : <strong>{{formGroup.get('status')?.value ? 'Active' : 'Inactive'}}</strong></label>
                            </div>
                            <h4 class="card-title col-12 mb-4">Approval Rules</h4>
                            <div class="col-12 mb-4">
                                <table class="table">
                                    <thead>
                                        <tr>
                                            <th>#</th>
                                            <th style="width:45%;">Role</th>
                                            <th style="width:45%;">Action Name</th>
                                            <th>
                                                <i class="material-icons">close</i>
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <ng-container formArrayName="rules">
                                            <tr *ngFor="let rule of rules?.controls as FormArray;index as i;first as isFirst;last as isLast;" [formGroupName]="i">
                                                <td>{{i+1}}</td>
                                                <td>
                                                    <ng-select class="form-control" [clearable]="false" placeholder="-" formControlName="role_id" loadingText="Loading..." [class.is-invalid]="formGroup.get('role_id')?.invalid && (theForm.submitted || formGroup.get('role_id')?.touched)" required>
                                                        <ng-option *ngFor="let item of roleList" [value]="item.id">{{ item.name }}</ng-option>
                                                    </ng-select>
                                                </td>
                                                <td>
                                                    <ng-select class="form-control" [clearable]="false" placeholder="-" formControlName="level_name" loadingText="Loading..." [class.is-invalid]="formGroup.get('level_name')?.invalid && (theForm.submitted || formGroup.get('level_name')?.touched)" required>
                                                        <ng-option value="approval">Approval</ng-option>
                                                        <ng-option *ngIf="formGroup.get('type')?.value=='pr-suppliers'" value="allocation">Allocation</ng-option>
                                                    </ng-select>
                                                </td>
                                                <td>
                                                    <button type="button" class="btn btn-sm btn-danger" (click)="removeRules(i);">
                                                        <i class="material-icons">close</i>
                                                    </button>
                                                </td>
                                            </tr>
                                        </ng-container>
                                    </tbody>
                                </table>
                                <div>
                                    <button type="button" class="btn btn-primary" (click)="addRules()" *ngIf="((rules?.length || 0) < 10)">
                                        Add Rule(s)
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div class="d-flex justify-content-between align-items-center border-top pt-1">
                            <div>
                                &nbsp;
                            </div>
                            <div class="text-end">
                                <button type="button" class="btn btn-secondary" (click)="back();" [attr.disabled]="loading.status ? 'disabled' : null">
                                    <i class="material-icons">close</i> &nbsp;
                                    Cancel
                                </button>
                                &nbsp;
                                <button type="submit" class="btn btn-primary" [attr.disabled]="loading.status ? 'disabled' : null">
                                    <i class="material-icons">save</i> &nbsp;
                                    Save
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>
