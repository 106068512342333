<div class="row mb-4">
    <div class="col-sm-12 mx-auto">
        <div class="card">
            <div class="card-body">
                <div class="row">
                    <h4 class="card-title col-12 mb-4">PO Additional Price Setting</h4>
                    <div class="text-center" *ngIf="!formGroup.get('id')?.value">
                        <div class="spinner-grow" role="status">
                            <span class="visually-hidden">Loading...</span>
                        </div>
                    </div>
                    <form *ngIf="formGroup.get('id')?.value" #theForm="ngForm" (ngSubmit)="submitForm(formGroup)" novalidate class="col-md-8">
                        <div [formGroup]="formGroup">
                            <div class="form-floating mb-4">
                                <input type="text" formControlName="item_name" [class.is-invalid]="formGroup.get('item_name')?.invalid && (theForm.submitted || formGroup.get('item_name')?.touched)" placeholder="..." class="form-control" required>
                                <label>Item name</label>
                                <div class="text-danger position-absolute text-end w-100" *ngIf="formGroup.get('item_name')?.invalid && (theForm.submitted || formGroup.get('item_name')?.touched)">
                                    <small *ngIf="formGroup.get('item_name')?.errors?.required">Please type item name</small>
                                    <small *ngIf="formGroup.get('item_name')?.errors?.serverError">{{ formGroup.get("item_name")?.errors?.serverError }}</small>
                                </div>
                            </div>
                            <div class="form-floating mb-4">
                                <ng-select class="form-control" placeholder="-" formControlName="uom" loadingText="Loading..." [class.is-invalid]="formGroup.get('uom')?.invalid && (theForm.submitted || formGroup.get('uom')?.touched)" required>
                                    <ng-option *ngFor="let item of (uomList | async)" [value]="item">{{item}}</ng-option>
                                </ng-select>
                                <label>UoM</label>
                                <div class="text-danger position-absolute text-end w-100" *ngIf="formGroup.get('uom')?.invalid && (theForm.submitted || formGroup.get('uom')?.touched)">
                                    <small *ngIf="formGroup.get('uom')?.errors?.required">Please select UoM</small>
                                    <small *ngIf="formGroup.get('uom')?.errors?.serverError">{{ formGroup.get("uom")?.errors?.serverError }}</small>
                                </div>
                            </div>
                            <div class="form-floating mb-4">
                                <input type="text" ngxNumberFormat="###,###,###,###,###" formControlName="price" [class.is-invalid]="formGroup.get('price')?.invalid && (theForm.submitted || formGroup.get('price')?.touched)" placeholder="..." class="form-control" required>
                                <label>Unit Price</label>
                                <div class="text-danger position-absolute text-end w-100" *ngIf="formGroup.get('price')?.invalid && (theForm.submitted || formGroup.get('price')?.touched)">
                                    <small *ngIf="formGroup.get('price')?.errors?.required">Please type unit price</small>
                                    <small *ngIf="formGroup.get('price')?.errors?.serverError">{{ formGroup.get("price")?.errors?.serverError }}</small>
                                </div>
                            </div>
                            <div class="form-floating mb-4">
                                <input type="text" disabled [value]="data.updater?.name || '-'" placeholder="..." class="form-control">
                                <label>Last updated by</label>
                            </div>
                        </div>
                        <div class="d-flex justify-content-between align-items-center border-top pt-1">
                            <div>&nbsp;</div>
                            <div class="text-end">
                                <button type="submit" class="btn btn-primary" [attr.disabled]="loading.status || formGroup.get('item_name')?.disabled ? 'disabled' : null"><i class="material-icons">save</i> &nbsp; Save</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>
