<div class="row mb-4">
    <div class="col-sm-12 mx-auto">
        <div class="card">
            <div class="card-body">
                <div class="row">
                    <h4 class="card-title col-12 mb-4">Add Customer</h4>
                    <form #theForm="ngForm" (ngSubmit)="submitForm(formGroup)" novalidate class="col-md-8">
                        <div [formGroup]="formGroup">
                            <div class="form-floating mb-4">
                                <input type="text" formControlName="name" [class.is-invalid]="formGroup.get('name')?.invalid && (theForm.submitted || formGroup.get('name')?.touched)" placeholder="..." class="form-control" required>
                                <label>Name</label>
                                <div class="text-danger position-absolute text-end w-100" *ngIf="formGroup.get('name')?.invalid && (theForm.submitted || formGroup.get('name')?.touched)">
                                    <small *ngIf="formGroup.get('name')?.errors?.required">Please type name</small>
                                    <small *ngIf="formGroup.get('name')?.errors?.serverError">{{ formGroup.get("name")?.errors?.serverError }}</small>
                                </div>
                            </div>
                            <div class="form-floating mb-4">
                                <input type="text" formControlName="pic" [class.is-invalid]="formGroup.get('pic')?.invalid && (theForm.submitted || formGroup.get('pic')?.touched)" placeholder="..." class="form-control" required>
                                <label>PIC Name</label>
                                <div class="text-danger position-absolute text-end w-100" *ngIf="formGroup.get('pic')?.invalid && (theForm.submitted || formGroup.get('pic')?.touched)">
                                    <small *ngIf="formGroup.get('pic')?.errors?.required">Please type PIC name</small>
                                    <small *ngIf="formGroup.get('pic')?.errors?.serverError">{{ formGroup.get("pic")?.errors?.serverError }}</small>
                                </div>
                            </div>
                            <div class="form-floating mb-4">
                                <input type="tel" formControlName="contact_number" [class.is-invalid]="formGroup.get('contact_number')?.invalid && (theForm.submitted || formGroup.get('contact_number')?.touched)" placeholder="..." class="form-control" required>
                                <label>Contact Number</label>
                                <div class="text-danger position-absolute text-end w-100" *ngIf="formGroup.get('contact_number')?.invalid && (theForm.submitted || formGroup.get('contact_number')?.touched)">
                                    <small *ngIf="formGroup.get('contact_number')?.errors?.required">Please type contact number</small>
                                    <small *ngIf="formGroup.get('contact_number')?.errors?.serverError">{{ formGroup.get("contact_number")?.errors?.serverError }}</small>
                                </div>
                            </div>
                            <div class="form-floating mb-4">
                                <input type="email" formControlName="email" [class.is-invalid]="formGroup.get('email')?.invalid && (theForm.submitted || formGroup.get('email')?.touched)" placeholder="..." class="form-control" required>
                                <label>Email</label>
                                <div class="text-danger position-absolute text-end w-100" *ngIf="formGroup.get('email')?.invalid && (theForm.submitted || formGroup.get('email')?.touched)">
                                    <small *ngIf="formGroup.get('email')?.errors?.required">Please type email</small>
                                    <small *ngIf="formGroup.get('email')?.errors?.serverError">{{ formGroup.get("email")?.errors?.serverError }}</small>
                                </div>
                            </div>
                            <div class="form-floating mb-4">
                                <textarea formControlName="address" [class.is-invalid]="formGroup.get('address')?.invalid && (theForm.submitted || formGroup.get('address')?.touched)" placeholder="..." class="form-control"></textarea>
                                <label>Address</label>
                                <div class="text-danger position-absolute text-end w-100" *ngIf="formGroup.get('address')?.invalid && (theForm.submitted || formGroup.get('address')?.touched)">
                                    <small *ngIf="formGroup.get('address')?.errors?.required">Please type address</small>
                                    <small *ngIf="formGroup.get('address')?.errors?.serverError">{{ formGroup.get("address")?.errors?.serverError }}</small>
                                </div>
                            </div>
                            <div class="form-check form-switch mb-4">
                                <input class="form-check-input" type="checkbox" formControlName="status" id="flexSwitchCheckDefault" />
                                <label class="form-check-label" for="flexSwitchCheckDefault">Status : <strong>{{ formGroup.get("status")?.value ? "Active" : "Inactive" }}</strong></label>
                            </div>
                        </div>
                        <div class="d-flex justify-content-between align-items-center border-top pt-1">
                            <div>&nbsp;</div>
                            <div class="text-end">
                                <button type="button" class="btn btn-secondary" (click)="back()" [attr.disabled]="loading.status ? 'disabled' : null"><i class="material-icons">close</i> &nbsp; Cancel</button>
                                &nbsp;
                                <button type="submit" class="btn btn-primary" [attr.disabled]="loading.status ? 'disabled' : null"><i class="material-icons">save</i> &nbsp; Save</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>
