import { Location } from '@angular/common';
import { Component, ElementRef, OnDestroy, OnInit, AfterViewInit, ViewChild } from '@angular/core';
import { FormArray, FormControl, FormGroup, NgForm } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { v4 as uuidv4 } from 'uuid';
import { HttpService } from 'src/app/services/http.service';
import { LoadingService } from 'src/app/services/loading.service';
import { BehaviorSubject, Subject, delay, distinctUntilChanged, merge } from 'rxjs';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FileHandlerService } from 'src/app/services/file-handler.service';
import { environment } from 'src/environments/environment';
import { ActivatedRoute, Router } from '@angular/router';
import { UomService } from 'src/app/services/uom.service';

@Component({
    selector: 'app-purchase-order-add',
    templateUrl: './purchase-order-add.component.html',
    styleUrls: ['./purchase-order-add.component.scss']
})
export class PurchaseOrderAddComponent implements OnInit, OnDestroy, AfterViewInit {
    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private http: HttpService,
        private el: ElementRef,
        private location: Location,
        public fileHandler: FileHandlerService,
        public loading: LoadingService,
        private modalService: NgbModal,
        private uom: UomService,
        private toastr: ToastrService
    ) {
        this.route.queryParams.subscribe(q => {
            this.draftId = q.draftid;
        });
    }
    @ViewChild('theForm') theForm: NgForm | undefined;
    @ViewChild('documentModal') documentModal: any;
    @ViewChild('confirmExitModal') confirmExitModal: any;
    @ViewChild('confirmLoadModal') confirmLoadModal: any;
    private apiPath = 'purchase-orders';
    private draftId: any = null;
    formGroup: FormGroup[] = [
        new FormGroup({
            customer_id: new FormControl(),
            contract_id: new FormControl({ value: null, disabled: true }),
            number: new FormControl(),
            status: new FormControl(1),
        }),
        new FormGroup({
            customer_boqs: new FormArray([
                new FormGroup({
                    sow_name: new FormControl(),
                    qty: new FormControl(),
                    uom: new FormControl(),
                    price: new FormControl(),
                    notes: new FormControl(),
                    ref_id: new FormControl(uuidv4())
                })
            ])
        }),
        new FormGroup({
            materials: new FormArray([
                new FormGroup({
                    material_id: new FormControl(),
                    supplier_price_detail_id: new FormControl(),
                    ref_price: new FormControl(),
                    uom: new FormControl(),
                    qty: new FormControl(),
                    price: new FormControl()
                })
            ])
        }),
        new FormGroup({
            vendor_boqs: new FormArray([
                new FormGroup({
                    customer_ref: new FormControl(),
                    sow_name: new FormControl(),
                    qty: new FormControl(),
                    uom: new FormControl(),
                    price: new FormControl(),
                    notes: new FormControl(),
                    ref_id: new FormControl(uuidv4())
                })
            ])
        }),
        new FormGroup({
            milestone_customers: new FormArray([
                new FormGroup({
                    sows: new FormArray([
                        new FormGroup({
                            customer_ref: new FormControl()
                        })
                    ]),
                    names: new FormArray([
                        new FormGroup({
                            name: new FormControl(),
                            is_need_document: new FormControl(0),
                            documents: new FormControl([])
                        })
                    ])
                })
            ])
        }),
        new FormGroup({
            milestone_vendors: new FormArray([
                new FormGroup({
                    sows: new FormArray([
                        new FormGroup({
                            vendor_ref: new FormControl()
                        })
                    ]),
                    names: new FormArray([
                        new FormGroup({
                            name: new FormControl(),
                            is_need_document: new FormControl(0),
                            documents: new FormControl([])
                        })
                    ])
                })
            ])
        })
    ];
    formChanged = false;
    formStep = 0;
    contractList: any[] = [];
    customerList: any = [];
    materialList = new BehaviorSubject<any[] | null>(null);
    qMaterial = new Subject<string>();
    milestoneCustomerDocs: any = [];
    milestoneVendorDocs: any = [];
    uomList = this.uom.getUoMList();

    documentDialog: any = {
        title: null,
        data: [],
        formGroup: null,
        modal: null,
        browseFile: (ref: string, i: number) => {
            document.getElementById(`file-input-${ref}-${i}`)?.click();
        },
        addDoc: () => {
            this.documentDialog.data.push({
                name: '',
                file: {},
                ref_id: uuidv4()
            });
        },
        removeDoc: (i: number) => {
            this.documentDialog.data.splice(i, 1);
            if (!this.documentDialog.data.length) {
                this.documentDialog.addDoc();
            }
        },
        show: (title: any, fg: FormGroup) => {
            this.documentDialog.formGroup = fg;
            this.documentDialog.data = this.documentDialog.formGroup?.value || [];
            if (!this.documentDialog.data.length) {
                this.documentDialog.addDoc();
            }
            this.documentDialog.title = title;
            this.documentDialog.modal = this.modalService.open(this.documentModal, { keyboard: false, backdrop: 'static', centered: true, size: 'lg' });
        },
        submit: () => {
            this.documentDialog.formGroup.value = this.documentDialog.data.filter((e: any) => e.name && e.file?.file).map((e: any) => {
                delete e.file.view;
                delete e.file.loading;
                return e;
            });
            this.documentDialog.formGroup.setValue(this.documentDialog.formGroup.value);
            this.documentDialog.modal.close();
        }
    };

    submitForm(): void {
        if (!this.formGroup[this.formStep].valid) {
            const err_el = this.el.nativeElement.querySelectorAll('.fg-' + this.formStep + ' [formcontrolname].ng-invalid')?.[0];
            if (err_el) {
                err_el.focus();
                err_el.scrollIntoView();
            }
            return;
        }
        if (this.formStep < 5) {
            this.formStep++;
            this.formGroup[this.formStep].markAsUntouched();
            this.theForm?.resetForm();
            this.el.nativeElement.querySelectorAll('.fg-' + this.formStep)?.[0]?.scrollIntoView();
            return;
        }
        let fdata = new FormData();
        let formData = {
            ...this.formGroup[0].value,
            ...this.formGroup[1].value,
            ...this.formGroup[2].value,
            ...this.formGroup[3].value,
            ...this.formGroup[4].value,
            ...this.formGroup[5].value
        };
        formData.status = Number(formData.status);
        formData.have_site_name = Number(formData.have_site_name);
        formData.milestone_customers?.forEach((e: any) => {
            e.names?.forEach((n: any) => {
                n.documents?.forEach((d: any) => {
                    const ext = d.file?.file?.name?.split('.').pop();
                    fdata.append('milestone_customer_documents[]', d.file?.file, d.ref_id + '.' + ext);
                })
            })
        });
        formData.milestone_vendors?.forEach((e: any) => {
            e.names?.forEach((n: any) => {
                n.documents?.forEach((d: any) => {
                    const ext = d.file?.file?.name?.split('.').pop();
                    fdata.append('milestone_vendor_documents[]', d.file?.file, d.ref_id + '.' + ext);
                })
            })
        });
        if (this.draftId) {
            formData.draftID = this.draftId;
        }
        fdata.append('data', JSON.stringify(formData));
        this.formGroup[this.formStep].disable();
        this.http.Post(this.apiPath + '/create', fdata).then((r: any) => {
            this.formGroup[this.formStep].enable();
            if (r.success) {
                this.formChanged = false;
                this.draft.remove();
                this.toastr.success('Data saved successfully', 'Success');
                if (r.response?.result?.from_draft) {
                    this.router.navigateByUrl('/purchase-orders');
                    return;
                }
                this.location.back();
            } else {
                if (r.response && r.response.wrong) {
                    Object.keys(r.response.wrong).forEach((key) => {
                        if (key != 'id') {
                            this.formGroup[this.formStep].get(key)?.setErrors({ serverError: r.response.wrong[key][0] });
                            this.el.nativeElement.querySelectorAll('[formcontrolname="' + key + '"]')?.[0]?.focus();
                        }
                    });
                }
            }
        });
    }

    back(): void {
        if (this.formStep > 0) {
            this.formStep--;
            return;
        }
        this.location.back();
    }

    changeStep(i: number): void {
        if (i > this.formStep && !this.formGroup[this.formStep].valid) {
            const err_el = this.el.nativeElement.querySelectorAll('.fg-' + this.formStep + ' [formcontrolname].ng-invalid')?.[0];
            if (err_el) {
                err_el.focus();
                err_el.scrollIntoView();
            }
            return;
        }
        this.formStep = i;
    }

    async getCustomer(): Promise<void> {
        const r = await this.http.Get('customers', { filter: { status: 1 }, forceView: true });
        this.customerList = r.response?.result?.data || [];
    }

    async getContract(): Promise<void> {
        if (!this.formGroup[0].get('customer_id')?.value) {
            this.formGroup[0].get('contract_id')?.reset();
            this.formGroup[0].get('contract_id')?.disable();
        }
        const r = await this.http.Get('contracts', { filter: { status: 1, customer_id: this.formGroup[0].get('customer_id')?.value }, forceView: true });
        this.contractList = r.response?.result?.data || [];
        this.formGroup[0].get('contract_id')?.enable();
    }

    async getMaterial(q: string): Promise<void> {
        let r = await this.http.Get('materials', { q: q, limit: 15, filter: { status: 1 }, with_price: true, forceView: true });
        this.materialList.next(r?.response?.result?.data || []);
    }

    setMaterial(fg: any, i: number): void {
        const material_id = fg.get('material_id')?.value;
        const material = (JSON.parse(JSON.stringify(this.materialList.getValue())) || []).find((e: any) => e.id == material_id) || {};
        fg.get('uom')?.setValue(material.uom);
        const prices = material.prices?.sort((a: any, b: any) => a.price - b.price) || [{}];
        fg.get('ref_price')?.setValue(prices?.[0]?.price);
        fg.get('supplier_price_detail_id')?.setValue(prices?.[0]?.id);
        const hasMaterial = this.materials.get().controls.filter(e => e.get('material_id')?.value == material_id);
        if (hasMaterial.length > 1 && i > 0) {
            const cVal = hasMaterial[0].get('qty')?.value;
            hasMaterial[0].get('qty')?.setValue(Number(cVal) + 1);
            this.materials.remove(i);
        }
    }

    customerBoqs = {
        get: () => {
            return this.formGroup[1].get('customer_boqs') as FormArray;
        },
        add: () => {
            const items = this.customerBoqs.get();
            items.push(new FormGroup({
                sow_name: new FormControl(),
                qty: new FormControl(),
                uom: new FormControl(),
                price: new FormControl(),
                notes: new FormControl(),
                ref_id: new FormControl(uuidv4())
            }));
        },
        remove: (i: number) => {
            const items = this.customerBoqs.get();
            items.removeAt(i);
            if (!items.length) {
                this.customerBoqs.add();
            }
        }
    };

    vendorBoqs = {
        get: () => {
            return this.formGroup[3].get('vendor_boqs') as FormArray;
        },
        add: () => {
            const items = this.vendorBoqs.get();
            items.push(new FormGroup({
                customer_ref: new FormControl(),
                sow_name: new FormControl(),
                qty: new FormControl(),
                uom: new FormControl(),
                price: new FormControl(),
                notes: new FormControl(),
                ref_id: new FormControl(uuidv4())
            }));
        },
        remove: (i: number) => {
            const items = this.vendorBoqs.get();
            items.removeAt(i);
            if (!items.length) {
                this.vendorBoqs.add();
            }
        }
    };

    materials = {
        get: () => {
            return this.formGroup[2].get('materials') as FormArray;
        },
        add: () => {
            const items = this.materials.get();
            items.push(new FormGroup({
                material_id: new FormControl(),
                supplier_price_detail_id: new FormControl(),
                ref_price: new FormControl(),
                uom: new FormControl(),
                qty: new FormControl(),
                price: new FormControl()
            }));
        },
        remove: (i: number) => {
            const items = this.materials.get();
            items.removeAt(i);
            if (!items.length) {
                this.materials.add();
            }
        }
    };

    asFormArray(varName: any): FormArray {
        return varName as FormArray;
    }

    customer_ref = {
        add: (varName: any) => {
            const items = this.asFormArray(varName);
            items.push(new FormGroup({
                customer_ref: new FormControl()
            }));
        },
        remove: (varName: any, i: number) => {
            const items = this.asFormArray(varName);
            items.removeAt(i);
            if (!items.length) {
                this.customer_ref.add(varName);
            }
        }
    };

    sow_names = {
        add: (varName: any) => {
            const items = this.asFormArray(varName);
            items.push(new FormGroup({
                name: new FormControl(),
                is_need_document: new FormControl(0),
                documents: new FormControl([])
            }));
        },
        remove: (varName: any, i: number) => {
            const items = this.asFormArray(varName);
            items.removeAt(i);
            if (!items.length) {
                this.sow_names.add(varName);
            }
        }
    };

    milestone_customers = {
        get: () => {
            return this.formGroup[4].get('milestone_customers') as FormArray;
        },
        add: () => {
            const items = this.milestone_customers.get();
            items.push(new FormGroup({
                sows: new FormArray([
                    new FormGroup({
                        customer_ref: new FormControl()
                    })
                ]),
                names: new FormArray([
                    new FormGroup({
                        name: new FormControl(),
                        is_need_document: new FormControl(0),
                        documents: new FormControl([])
                    })
                ])
            }))
        },
        remove: (i: number) => {
            const items = this.milestone_customers.get();
            items.removeAt(i);
            if (!items.length) {
                this.milestone_customers.add();
            }
        }
    };

    vendor_ref = {
        add: (varName: any) => {
            const items = this.asFormArray(varName);
            items.push(new FormGroup({
                vendor_ref: new FormControl()
            }));
        },
        remove: (varName: any, i: number) => {
            const items = this.asFormArray(varName);
            items.removeAt(i);
            if (!items.length) {
                this.customer_ref.add(varName);
            }
        }
    };

    milestone_vendors = {
        get: () => {
            return this.formGroup[5].get('milestone_vendors') as FormArray;
        },
        add: () => {
            const items = this.milestone_vendors.get();
            items.push(new FormGroup({
                sows: new FormArray([
                    new FormGroup({
                        vendor_ref: new FormControl()
                    })
                ]),
                names: new FormArray([
                    new FormGroup({
                        name: new FormControl(),
                        is_need_document: new FormControl(0),
                        documents: new FormControl([])
                    })
                ])
            }))
        },
        remove: (i: number) => {
            const items = this.milestone_vendors.get();
            items.removeAt(i);
            if (!items.length) {
                this.milestone_vendors.add();
            }
        }
    };

    getCustomerSow(): any[] {
        return this.customerBoqs.get().controls.map((e) => e.value).filter((e) => e.sow_name);
    }

    getVendorSow(): any[] {
        return this.vendorBoqs.get().controls.map((e) => e.value).filter((e) => e.sow_name);
    }

    numToAplha(n: number): string {
        return String.fromCharCode(97 + n).toUpperCase();
    }

    ngOnInit(): void {
        this.getCustomer();
        this.qMaterial.pipe(delay(100), distinctUntilChanged()).subscribe((q: any) => {
            if (!q) {
                this.materialList.next([]);
                return;
            }
            this.getMaterial(q);
        });
        merge(
            this.formGroup[0].valueChanges,
            this.formGroup[1].valueChanges,
            this.formGroup[2].valueChanges,
            this.formGroup[3].valueChanges,
            this.formGroup[4].valueChanges,
            this.formGroup[5].valueChanges)
            .subscribe(() => {
                this.formChanged = true;
                this.draft.save();
            });
    }

    ngOnDestroy(): void {
        this.qMaterial.unsubscribe();
    }

    async ngAfterViewInit(): Promise<void> {
        if (this.draftId) {
            let r: any = await this.http.Get(this.apiPath + '/' + this.draftId, { type: 'draft' });
            this.draft.prepareDraft(r?.response?.result?.data);
        } else {
            this.draft.load();
        }
    }

    async canExit(): Promise<boolean> {
        if (!this.formChanged) {
            return true;
        }
        const modal = await this.modalService.open(this.confirmExitModal, { keyboard: false, backdrop: 'static', centered: true }).result;
        if (modal == true) {
            this.draft.remove();
            return true;
        } else if (modal == -1) {
            const formData = {
                ...this.formGroup[0].value,
                ...this.formGroup[1].value,
                ...this.formGroup[2].value,
                ...this.formGroup[3].value,
                ...this.formGroup[4].value,
                ...this.formGroup[5].value,
                created_at: (new Date()).toISOString(),
                draftID: uuidv4()
            };
            const fdata = new FormData();
            fdata.append('data', JSON.stringify(formData));
            this.formGroup[this.formStep].disable();
            const r = await this.http.Post(this.apiPath + '/create?draft=true', fdata);
            this.draft.remove();
            return r.success;
        }
        return false;
    }

    draft = {
        isLoading: false,
        tmpName: '_' + environment.appName + '.poTemp',
        save: () => {
            if (this.draft.isLoading) {
                return;
            }
            const formData = {
                ...this.formGroup[0].value,
                ...this.formGroup[1].value,
                ...this.formGroup[2].value,
                ...this.formGroup[3].value,
                ...this.formGroup[4].value,
                ...this.formGroup[5].value,
                timestamp: (new Date()).getTime()
            };
            localStorage.setItem(this.draft.tmpName, JSON.stringify(formData));
        },
        load: async () => {
            const data = JSON.parse(localStorage.getItem(this.draft.tmpName) || '{}');
            if (data.contract_id) {
                try {
                    const modal = await this.modalService.open(this.confirmLoadModal, { keyboard: false, backdrop: 'static', centered: true }).result;
                    if (!modal) {
                        this.draft.remove();
                        return;
                    }
                } catch (e) {
                    return;
                }
                this.draft.prepareDraft(data);
            }
        },
        remove: () => {
            localStorage.removeItem(this.draft.tmpName);
        },
        prepareDraft: (data: any) => {
            this.draft.isLoading = true;
            const parepareForm = (fdata: any, key: string) => {
                return fdata[key]?.map((e: any) => {
                    let fb: any = {};
                    Object.keys(e).forEach((v: any) => {
                        if ((e[v]) instanceof Array && v != 'documents') {
                            fb[v] = new FormArray(parepareForm(e, v));
                        } else {
                            fb[v] = new FormControl(e[v]);
                        }
                    });
                    return new FormGroup(fb);
                });
            }
            Object.keys(data).forEach((key) => {
                if (key == 'materials' && data[key]?.[0]?.material_id) {
                    const materials = (data[key]).map((e: any) => e.material_id);
                    this.http.Get('materials', { ids: materials.join(','), filter: { status: 1 }, with_price: true, forceView: true }).then(r => {
                        this.materialList.next(r?.response?.result?.data || []);
                    });
                    this.formGroup[2].removeControl(key);
                    this.formGroup[2].addControl(key, new FormArray(parepareForm(data, key)));
                } else if (key == 'customer_boqs' && data[key]?.[0]?.sow_name) {
                    this.formGroup[1].removeControl(key);
                    this.formGroup[1].addControl(key, new FormArray(parepareForm(data, key)));
                } else if (key == 'vendor_boqs' && data[key]?.[0]?.customer_ref) {
                    this.formGroup[3].removeControl(key);
                    this.formGroup[3].addControl(key, new FormArray(parepareForm(data, key)));
                } else if (key == 'milestone_customers' && data[key]?.[0]?.sows?.[0]?.customer_ref) {
                    this.formGroup[4].removeControl(key);
                    this.formGroup[4].addControl(key, new FormArray(parepareForm(data, key)));
                } else if (key == 'milestone_vendors' && data[key]?.[0]?.sows?.[0]?.vendor_ref) {
                    this.formGroup[5].removeControl(key);
                    this.formGroup[5].addControl(key, new FormArray(parepareForm(data, key)));
                } else if (key != 'timestamp') {
                    this.formGroup[0].get(key)?.setValue(data[key]);
                }
            });
            this.getCustomer();
            this.getContract();
            setTimeout(() => {
                this.draft.isLoading = false;
            }, 1000);
        }
    }
}
