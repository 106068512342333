import { formatNumber } from '@angular/common';
import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Chart, ChartData, Legend, Colors, Tooltip, BarElement, BarController, CategoryScale, LineController, LinearScale } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { HttpService } from 'src/app/services/http.service';
import { LoadingService } from 'src/app/services/loading.service';

@Component({
    selector: 'app-dashboard-view',
    templateUrl: './dashboard-view.component.html',
    styleUrls: ['./dashboard-view.component.scss']
})
export class DashboardViewComponent implements OnInit, AfterViewInit {
    constructor(
        private http: HttpService,
        public loading: LoadingService
    ) {
        this.loading.start();
        Chart.register(
            ChartDataLabels,
            Colors,
            Legend,
            Tooltip,
            BarElement,
            BarController,
            CategoryScale,
            LineController,
            LinearScale
        );
    }
    @ViewChild('barChart') barChartEl!: ElementRef;
    private apiPath = 'dashboard/view';
    data: any = {};
    barChart!: any;
    barOptons: any = {
        responsive: true,
        maintainAspectRatio: true,
        plugins: {
            datalabels: {
                formatter: (value: number, ctx: any) => {
                    const datapoints = ctx.chart.data.datasets[0].data
                    const total = datapoints.reduce((t: number, d: number) => Number(t) + Number(d), 0)
                    if (!total) return 0;
                    const percentage = value / Number(total) * 100;
                    return formatNumber(value, 'en') + '\n(' + percentage.toFixed(2) + "%)";
                },
                color: '#000'
            },
            legend: {
                labels: {
                    padding: 50
                },
                position: 'bottom'
            }
        }
    };

    async getData(): Promise<void> {
        const r = await this.http.Get(this.apiPath, { forceView: true });
        this.data = r.response?.result || {};

        this.barChart?.destroy();
        let barData: ChartData<'bar'> = { labels: [], datasets: [] };
        let bar_1: any = [];
        let bar_2: any = [];
        let bar_3: any = [];
        (this.data?.bar || []).forEach((e: any) => {
            barData.labels?.push(e.name);
            bar_1.push(e.customer_boq);
            bar_2.push(e.material + e.vendor_boq);
            bar_3.push(e.po_vendor);
        });
        barData.datasets = [
            {
                label: 'Customer BOQ',
                data: bar_1,
            },
            {
                label: 'Material+Vendor BOQ',
                data: bar_2,
            },
            {
                label: 'PO Vendor',
                data: bar_3,
            }
        ];

        this.barChart = new Chart(this.barChartEl.nativeElement, {
            type: 'bar',
            data: barData,
            options: this.barOptons
        });
    }

    ngOnInit(): void {
        //
    }

    ngAfterViewInit(): void {
        this.getData();
    }
}
