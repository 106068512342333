import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, NgForm } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from 'src/app/services/auth.service';
import { HttpService } from 'src/app/services/http.service';
import { LoadingService } from 'src/app/services/loading.service';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'app-report-vendor-price',
    templateUrl: './report-vendor-price.component.html',
    styleUrls: ['./report-vendor-price.component.scss']
})
export class ReportVendorPriceComponent implements OnInit {

    constructor(
        private http: HttpService,
        private toastr: ToastrService,
        private el: ElementRef,
        private auth: AuthService,
        public loading: LoadingService
    ) {
    }
    @ViewChild('reportForm') reportForm: NgForm | undefined;
    private apiPath = 'report-vendor-price';
    formGroup = new FormGroup({
        contract_id: new FormControl(),
        purchase_order_id: new FormControl()
    });
    data: any = {};
    poList: any[] = [];
    contractList: any[] = [];
    isDownload = false;
    submitLoading = false;

    setAll(fg: any): void {
        const wh: any[] = fg?.value;
        if (wh.indexOf(-1) > -1) {
            fg?.setValue(null);
        }
    }

    submitForm(form: any, page: number = 1): void {
        if (!form.valid) {
            this.el.nativeElement.querySelectorAll('[formcontrolname].ng-invalid')[0]?.focus();
            return;
        }
        if (this.isDownload) {
            let val: any = {};
            Object.keys(form.value).forEach((key: any) => {
                if (form.value[key] != null) {
                    val[key] = form.value[key];
                }
            });
            let params = new URLSearchParams(val).toString();
            let url = environment.apiUrl + '/' + this.apiPath + '/download?token=' + this.auth.tokenData?.access_token + '&' + params;
            window.open(url, '_blank');
            return;
        }
        this.submitLoading = true;
        let fdata = new FormData();
        fdata.append('data', JSON.stringify(form.value));
        form.disable();
        this.http.Post(this.apiPath + '?page=' + page, fdata).then((r: any) => {
            this.submitLoading = false;
            form.enable();
            if (r.success) {
                this.data = r?.response?.result || {};
                if (!this.data?.data?.length) {
                    this.toastr.warning("No data found, please change filter");
                }
            } else {
                if (r.response && r.response.wrong) {
                    Object.keys(r.response.wrong).forEach((key) => {
                        if (key == 'is_download') return;
                        form.get(key)?.setErrors({ serverError: r.response.wrong[key][0] });
                        this.el.nativeElement.querySelectorAll('[formcontrolname="' + key + '"]')[0]?.focus();
                    });
                }
            }
        });
    }

    findPrice(boq_id: any, v: any): any {
        return v.details?.find((e: any) => e.vendor_boq_id == boq_id) || {};
    }

    async getContract(): Promise<void> {
        const r = await this.http.Get('contracts', { filter: { status: 1 }, forceView: true });
        this.contractList = r.response?.result?.data || [];
    }

    async getPO(): Promise<void> {
        const contract_id = this.formGroup.get('contract_id')?.value;
        if (!contract_id) {
            this.formGroup.get('purchase_order_id')?.reset();
        }
        const r = await this.http.Get('purchase-orders', { filter: { contract_id: contract_id, status: 1 }, forceView: true });
        this.poList = r.response?.result?.data || [];
    }

    ngOnInit(): void {
        this.getContract();
    }

}
