import { Component, OnDestroy, OnInit } from '@angular/core';
import { HttpService } from 'src/app/services/http.service';
import { LoadingService } from 'src/app/services/loading.service';
import { PageQueryService } from 'src/app/services/page-query.service';

@Component({
    selector: 'app-customer-document-view',
    templateUrl: './customer-document-view.component.html',
    styleUrls: ['./customer-document-view.component.scss']
})
export class CustomerDocumentViewComponent implements OnInit, OnDestroy {
    constructor(
        public pageQuery: PageQueryService,
        public loading: LoadingService,
        private http: HttpService
    ) {
        this.loading.start();
    }

    private apiPath = 'customer-document';
    data: any = {};
    permission: any = {};

    getData(): void {
        const query: any = this.pageQuery.query.getValue() || {};
        query.limit = query?.limit || 10;
        query.page = query?.page || 1;
        this.http.Get(this.apiPath, query).then((r: any) => {
            if (r.success) {
                this.data = r?.response?.result || {};
                this.permission = r?.permission || {};
            }
        });
    }

    ngOnInit(): void {
        this.pageQuery.init(() => {
            this.getData();
        });
    }

    ngOnDestroy(): void {
        this.pageQuery.destroy();
    }

}
