import { Location } from '@angular/common';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { HttpService } from 'src/app/services/http.service';
import { LoadingService } from 'src/app/services/loading.service';
import { PageQueryService } from 'src/app/services/page-query.service';

@Component({
    selector: 'app-vendor-price-price-list',
    templateUrl: './vendor-price-price-list.component.html',
    styleUrls: ['./vendor-price-price-list.component.scss']
})
export class VendorPricePriceListComponent implements OnInit, OnDestroy {
    constructor(
        private location: Location,
        private aRoute: ActivatedRoute,
        public pageQuery: PageQueryService,
        public loading: LoadingService,
        private http: HttpService,
    ) {
        this.loading.start();
        this.aRoute.params.subscribe(e => {
            this.id = e.id;
        });
    }

    @ViewChild('deleteModal') deleteModal: any;
    @ViewChild('formModal') formModal: any;
    private apiPath = 'vendor-prices';
    id: any;
    data: any = {};
    permission: any = {};

    getData(): void {
        const query: any = this.pageQuery.query.getValue() || {};
        query.limit = query?.limit || 10;
        query.page = query?.page || 1;
        query.filter = { status: 1 };
        query.type = 'prices';
        this.http.Get(this.apiPath + '/' + this.id, query).then((r: any) => {
            if (r.success) {
                this.data = r?.response?.result || {};
                this.permission = r?.permission || {};
            }
        });
    }

    findPrice(boq_id: any, v: any): any {
        return v.details?.find((e: any) => e.vendor_boq_id == boq_id) || {};
    }

    back(): void {
        this.location.back();
    }

    ngOnInit(): void {
        this.pageQuery.init(() => {
            this.getData();
        });
    }
    ngOnDestroy(): void {
        this.pageQuery.destroy();
    }

}
