<div class="row">
    <div class="col-12">
        <div class="card">
            <div class="card-body">
                <div class="d-flex justify-content-between row">
                    <div class="row pe-0">
                        <div class="d-flex flex-nowrap align-items-center col-md-6 mb-3">
                            <button class="btn btn-secondary btn-sm me-3" (click)="back()" title="Kembali">
                                <i class="material-icons">arrow_back</i>
                            </button>
                            <h4 class="card-title col-12 lh-0 m-0">Detail Vendor Progress</h4>
                        </div>
                    </div>
                </div>

                <div *ngIf="!data.id" class="text-center">
                    <div class="spinner-grow" role="status">
                        <span class="visually-hidden">Loading...</span>
                    </div>
                </div>

                <div *ngIf="data.id" class="mt-4">
                    <div class="row">
                        <div class="col-12">
                            <div class="row">
                                <div class="col-md-6">
                                    <div class="form-floating mb-4">
                                        <input disabled type="text" [value]="data.site?.site_id" placeholder="..." class="form-control">
                                        <label>Site ID</label>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-floating mb-4">
                                        <input disabled [value]="data.site?.name" placeholder="..." class="form-control">
                                        <label>Site Name</label>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-floating mb-4">
                                        <input disabled [value]="data.site?.purchase_order?.number" placeholder="..." class="form-control">
                                        <label>PO Customer</label>
                                    </div>
                                </div>
                                <div class="col-6">
                                    <div class="form-floating mb-4">
                                        <input disabled [value]="data.site?.purchase_order?.contract?.project_name" placeholder="..." class="form-control">
                                        <label>Project Name</label>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-floating mb-4">
                                        <input disabled [value]="data.milestone_name?.milestone_vendor?.type" placeholder="..." class="form-control">
                                        <label>Milestone #</label>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-floating mb-4">
                                        <input type="text" disabled [value]="data.milestone_name?.name" placeholder="..." class="form-control">
                                        <label>Milestoner Name</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12">
                            <div *ngIf="data.status==1" class="d-flex align-items-center">
                                <div>Allocated at : <strong>{{data.closed_at | dateFormat:'DD MMM YYYY'}}</strong></div>
                            </div>
                            <div>
                                <table class="table table-bordered">
                                    <thead>
                                        <tr>
                                            <th>
                                                #
                                            </th>
                                            <th>
                                                SoW
                                            </th>
                                            <th class="text-end">
                                                Qty
                                            </th>
                                            <th>
                                                UoM
                                            </th>
                                            <th>
                                                Vendor
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <ng-container *ngFor="let item of data.sows;index as i;">
                                            <tr [class.opacity-75]="item.status==0">
                                                <td>
                                                    {{i+1}}
                                                </td>
                                                <td>
                                                    {{item.sow?.vendor_boq?.sow_name}}
                                                </td>
                                                <td class="text-end">
                                                    {{item.qty | number}}
                                                </td>
                                                <td>
                                                    {{item.sow?.vendor_boq?.uom}}
                                                </td>
                                                <td>
                                                    {{item.vendor?.name || '-'}}
                                                    <em *ngIf="item.status==0" class="fw-bold">(Inactive)</em>
                                                </td>
                                            </tr>
                                            <ng-container *ngIf="!item.details.length">
                                                <tr>
                                                    <td colspan="5">
                                                        <em>No progress updates yet</em>
                                                    </td>
                                                </tr>
                                            </ng-container>
                                            <ng-container *ngIf="item.details.length">
                                                <tr [class.opacity-75]="item.status==0">
                                                    <td colspan="5">
                                                        Progress List:
                                                        <table class="table">
                                                            <thead>
                                                                <tr>
                                                                    <th>#</th>
                                                                    <th>Date</th>
                                                                    <th class="text-end">Qty</th>
                                                                    <th>Photo(s)</th>
                                                                    <th>Created By</th>
                                                                    <th>Status</th>
                                                                    <th>Next PIC</th>
                                                                    <th class="text-center">
                                                                        <i class="material-icons">more_vert</i>
                                                                    </th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <tr *ngFor="let d of item.details;index as x">
                                                                    <td>
                                                                        {{x+1}}
                                                                    </td>
                                                                    <td>
                                                                        {{d.progress_date | dateFormat:'DD MMM YYYY'}}
                                                                    </td>
                                                                    <td class="text-end">
                                                                        {{d.qty | number}}
                                                                    </td>
                                                                    <td nowrap>
                                                                        <img class="cursor-pointer border rounded me-1" (click)="openImg(d.photos)" [src]="assetUrl+d.photos?.[0]?.file" style="max-width:50px;height:auto">
                                                                    </td>
                                                                    <td>
                                                                        {{d.creator?.name}}
                                                                    </td>
                                                                    <td nowrap>
                                                                        <span *ngIf="d.status==1" class="badge text-bg-primary">
                                                                            Approved
                                                                        </span>
                                                                        <span *ngIf="d.status==-1" class="badge text-bg-danger">
                                                                            Rejected
                                                                        </span>
                                                                        <span *ngIf="d.status==0" class="badge text-bg-warning">
                                                                            Waiting {{nextApprovals(d.approvals)?.[0]?.level_name || 'Unknown'}}
                                                                        </span>
                                                                    </td>
                                                                    <td>
                                                                        <span *ngIf="d.status==0">
                                                                            {{nextApprovals(d.approvals)?.[0]?.role?.name || 'Unknown'}}
                                                                        </span>
                                                                        <span *ngIf="d.status!=0">
                                                                            -
                                                                        </span>
                                                                    </td>
                                                                    <td class="text-center" nowrap>
                                                                        <button class="btn btn-link" [class.disabled]="loading.status" title="Detail" (click)="progressDialog.show(item, d, true)">
                                                                            Detail
                                                                        </button>
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                            <tfoot class="fw-bold">
                                                                <tr>
                                                                    <td colspan="2">Total Actual Qty</td>
                                                                    <td class="text-end">{{item.total_qty}}</td>
                                                                    <td colspan="6">&nbsp;</td>
                                                                </tr>
                                                            </tfoot>
                                                        </table>
                                                    </td>
                                                </tr>
                                            </ng-container>
                                        </ng-container>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<ng-template #progressModal let-modal>
    <div class="modal-header">
        <h5 class="modal-title">Update Progress</h5>
    </div>
    <div class="modal-body">
        <div *ngIf="progressDialog.detail?.id" class="d-flex justify-content-center mb-2">
            <div class="text-center d-flex justify-content-center flex-wrap step-container active">
                <div style="width: 32px; height: 32px" class="bg-gray rounded-circle text-white d-flex justify-content-center align-items-center icon-step">
                    <span class="material-icons">verified_user</span>
                </div>
                <div class="w-100 text-center small">
                    <div class="text-muted">
                        Created
                    </div>
                    <div class="fw-bold">
                        {{progressDialog.detail.creator?.role?.name || 'Unknown'}}
                    </div>
                </div>
            </div>
            <div *ngFor="let app of progressDialog.detail.approvals;" [class.reject]="app.status==-1" [class.active]="app.status==1" class="text-center d-flex justify-content-center flex-wrap step-container">
                <div style="width: 32px; height: 32px" class="bg-gray rounded-circle text-white d-flex justify-content-center align-items-center icon-step">
                    <span *ngIf="app.status==0" class="material-icons">
                        hourglass_top
                    </span>
                    <span *ngIf="app.status==1" class="material-icons">
                        verified_user
                    </span>
                    <span *ngIf="app.status==-1" class="material-icons">
                        gpp_bad
                    </span>
                </div>
                <div class="w-100 text-center small">
                    <div class="text-muted">
                        <span *ngIf="app.status==1">
                            {{app.level_name=='approval' ? 'Approved' : 'Allocated'}}
                        </span>
                        <span *ngIf="app.status==-1">
                            Rejected
                        </span>
                        <span *ngIf="app.status==0">
                            Waiting {{app.level_name || 'Unknown'}}
                        </span>
                    </div>
                    <div class="fw-bold">
                        {{app?.role?.name || 'Unknown'}}
                    </div>
                </div>
            </div>
        </div>

        <div *ngIf="rejectRemarks(progressDialog.detail)" class="row mb-2">
            <div class="col-12">
                <div class="alert alert-danger mb-4">
                    <div class="fw-bold">Rejection remarks :</div>
                    <div>{{rejectRemarks(progressDialog.detail)}}</div>
                </div>
            </div>
        </div>

        <div class="form-floating mb-4">
            <input type="text" [value]="progressDialog.sow" placeholder="..." class="form-control" readonly>
            <label class="required">SoW</label>
        </div>
        <div class="form-floating mb-4">
            <input type="text" readonly [value]="progressDialog.data.progress_date | dateFormat:'DD MMM YYYY'" placeholder="..." class="form-control">
            <label>Date</label>
        </div>
        <div class="form-floating mb-4">
            <input type="text" readonly [value]="progressDialog.data.qty" ngxNumberFormat="###,###,###,###,###" placeholder="..." class="form-control">
            <label>Qty</label>
        </div>
        <div class="form-floating mb-4">
            <input type="text" readonly [value]="progressDialog.data.notes" placeholder="..." class="form-control">
            <label>Notes</label>
        </div>
        <div *ngIf="progressDialog.isDetail" class="mb-4">
            This progress is complete? : <strong>{{ progressDialog.data.is_done ? "Yes" : "No" }}</strong>
        </div>
        <div>
            <div class="d-flex">
                <div *ngFor="let p of progressDialog.photos;" (click)="photoDialog.show(p.file?.view)" class="me-2 rounded border position-relative cursor-pointer" style="height:80px;width:80px;background-position: center;background-size: contain;background-repeat: no-repeat;" [style.background-image]="'url('+p.file?.view+')'">
                    &nbsp;
                </div>
            </div>
        </div>
    </div>
    <div class="modal-footer d-flex justify-content-between">
        <div>
            <div class="spinner-border text-primary" role="status" *ngIf="loading.status">
                <span class="visually-hidden">Loading...</span>
            </div>
        </div>
        <div class="text-end">
            <button type="button" class="btn btn-secondary" (click)="modal.close()" [attr.disabled]="loading.status ? 'disabled' : null">Cancel</button>
            &nbsp;
            <button *ngIf="progressDialog.detail?.need_approval" class="btn btn-danger me-2" (click)="rejectDialog.show(progressDialog.detail?.id)" [disabled]="loading.status">
                Reject
            </button>
            <button *ngIf="progressDialog.detail?.need_approval" class="btn btn-primary" (click)="approveDialog.show(progressDialog.detail?.id);" [disabled]="loading.status">
                Approve
            </button>
        </div>
    </div>
</ng-template>
<div class="d-none">
    <div class="modal-lg"></div>
</div>
<ng-template #approveModal let-modal>
    <div class="modal-header">
        <h5 class="modal-title">Approve</h5>
    </div>
    <div class="modal-body">Are you sure want to <strong>Approve</strong> this data?</div>
    <div class="modal-footer d-flex justify-content-between">
        <div>
            <div class="spinner-border text-primary" role="status" *ngIf="loading.status">
                <span class="visually-hidden">Loading...</span>
            </div>
        </div>
        <div class="text-end">
            <button type="button" class="btn btn-secondary" (click)="modal.close()" [attr.disabled]="loading.status ? 'disabled' : null">Cancel</button>
            &nbsp;
            <button type="button" class="btn btn-primary" (click)="approveDialog.submit()" [attr.disabled]="loading.status ? 'disabled' : null">Approve</button>
        </div>
    </div>
</ng-template>

<ng-template #rejectModal let-modal>
    <div class="modal-header">
        <h5 class="modal-title">Reject</h5>
    </div>
    <div class="modal-body">
        <div>Are you sure want to <strong>Reject</strong> this data?</div>
        <div class="form-floating mt-3">
            <textarea [(ngModel)]="rejectDialog.remarks" placeholder="..." class="form-control" required></textarea>
            <label>Please type remarks</label>
        </div>
    </div>
    <div class="modal-footer d-flex justify-content-between">
        <div>
            <div class="spinner-border text-primary" role="status" *ngIf="loading.status">
                <span class="visually-hidden">Loading...</span>
            </div>
        </div>
        <div class="text-end">
            <button type="button" class="btn btn-secondary" (click)="modal.close()" [attr.disabled]="loading.status ? 'disabled' : null">Cancel</button>
            &nbsp;
            <button type="button" class="btn btn-danger" (click)="rejectDialog.submit()" [attr.disabled]="loading.status || !rejectDialog.remarks ? 'disabled' : null">Reject</button>
        </div>
    </div>
</ng-template>

<ng-template #photoModal let-modal>
    <div class="modal-header">
        <h5 class="modal-title">View Photo</h5>
    </div>
    <div class="modal-body">
        <div class="text-center">
            <img [src]="photoDialog.url" class="img-fluid">
        </div>
    </div>
    <div class="modal-footer d-flex justify-content-between">
        <div>
            &nbsp;
        </div>
        <div class="text-end">
            <button type="button" class="btn btn-secondary" (click)="modal.close()" [attr.disabled]="loading.status ? 'disabled' : null">Close</button>
        </div>
    </div>
</ng-template>
