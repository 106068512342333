<div class="row">
    <div class="col-12">
        <div class="card">
            <div class="card-body">
                <div class="d-flex justify-content-between row">
                    <div class="row pe-0">
                        <div class="d-flex flex-nowrap align-items-center col-md-6 mb-3">
                            <button class="btn btn-secondary btn-sm me-3" (click)="back()" title="Kembali">
                                <i class="material-icons">arrow_back</i>
                            </button>
                            <h4 class="card-title col-12 lh-0 m-0">Detail Vendor Document</h4>
                        </div>
                    </div>
                    <div *ngIf="data.id && data.status>-2" class="d-flex justify-content-center col-12">
                        <div class="text-center d-flex justify-content-center flex-wrap step-container active">
                            <div style="width: 32px; height: 32px" class="bg-gray rounded-circle text-white d-flex justify-content-center align-items-center icon-step">
                                <span class="material-icons">verified_user</span>
                            </div>
                            <div class="w-100 text-center small">
                                <div class="text-muted">
                                    Created
                                </div>
                                <div class="fw-bold">
                                    {{data.creator?.role?.name || 'Unknown'}}
                                </div>
                            </div>
                        </div>
                        <div *ngFor="let app of data.approvals;" [class.reject]="app.status==-1" [class.active]="app.status==1" class="text-center d-flex justify-content-center flex-wrap step-container">
                            <div style="width: 32px; height: 32px" class="bg-gray rounded-circle text-white d-flex justify-content-center align-items-center icon-step">
                                <span *ngIf="app.status==0" class="material-icons">
                                    hourglass_top
                                </span>
                                <span *ngIf="app.status==1" class="material-icons">
                                    verified_user
                                </span>
                                <span *ngIf="app.status==-1" class="material-icons">
                                    gpp_bad
                                </span>
                            </div>
                            <div class="w-100 text-center small">
                                <div class="text-muted">
                                    <span *ngIf="app.status==1">
                                        {{app.level_name=='approval' ? 'Approved' : 'Allocated'}}
                                    </span>
                                    <span *ngIf="app.status==-1">
                                        Rejected
                                    </span>
                                    <span *ngIf="app.status==0">
                                        Waiting {{app.level_name || 'Unknown'}}
                                    </span>
                                </div>
                                <div class="fw-bold">
                                    {{app?.role?.name || 'Unknown'}}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div *ngIf="!data.id" class="text-center">
                    <div class="spinner-grow" role="status">
                        <span class="visually-hidden">Loading...</span>
                    </div>
                </div>

                <div *ngIf="data.id" class="mt-4">
                    <div class="row">
                        <div class="col-12">
                            <div class="row">
                                <div class="col-md-6">
                                    <div class="form-floating mb-4">
                                        <input disabled type="text" [value]="data.site?.site_id" placeholder="..." class="form-control">
                                        <label>Site ID</label>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-floating mb-4">
                                        <input disabled [value]="data.site?.name" placeholder="..." class="form-control">
                                        <label>Site Name</label>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-floating mb-4">
                                        <input disabled [value]="data.site?.purchase_order?.number" placeholder="..." class="form-control">
                                        <label>PO Customer</label>
                                    </div>
                                </div>
                                <div class="col-6">
                                    <div class="form-floating mb-4">
                                        <input disabled [value]="data.site?.purchase_order?.contract?.project_name" placeholder="..." class="form-control">
                                        <label>Project Name</label>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-floating mb-4">
                                        <input disabled [value]="data.milestone_name?.milestone_vendor?.type" placeholder="..." class="form-control">
                                        <label>Milestone #</label>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-floating mb-4">
                                        <input type="text" disabled [value]="data.milestone_name?.name" placeholder="..." class="form-control">
                                        <label>Milestoner Name</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12">
                            <div *ngIf="data.status==1" class="d-flex align-items-center">
                                <div>Closed at : <strong>{{data.closed_at | dateFormat:'DD MMM YYYY'}}</strong></div>
                            </div>
                            <div>
                                <table class="table table-bordered">
                                    <thead>
                                        <tr>
                                            <th>
                                                #
                                            </th>
                                            <th>
                                                Document Name
                                            </th>
                                            <th>
                                                Document Example
                                            </th>
                                            <th>
                                                Document Date
                                            </th>
                                            <th>
                                                Uploaded Document
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <ng-container *ngFor="let item of data.details;index as i;">
                                            <tr>
                                                <td>
                                                    {{i+1}}
                                                </td>
                                                <td>
                                                    {{(item.document_name?.name || item.custom_doc_name) || '-'}}
                                                </td>
                                                <td>
                                                    <button class="btn btn-secondary btn-sm" (click)="downloadFile(item.document_name?.file)" [class.disabled]="loading.status || (item.document_name?.file)?.length<=1">Download</button>
                                                </td>
                                                <td>
                                                    <em class="text-muted" *ngIf="data.status==-2">Waiting update</em>
                                                    <span *ngIf="data.status>-2">
                                                        {{(item.document_date | dateFormat:'DD MMM YYYY') || '-'}}
                                                    </span>
                                                </td>
                                                <td>
                                                    <em class="text-muted" *ngIf="data.status==-2">Waiting update</em>
                                                    <button *ngIf="data.status>-2" class="btn btn-secondary btn-sm" (click)="downloadFile(item.file)" [class.disabled]="loading.status || !item.file">Download</button>
                                                </td>
                                            </tr>
                                        </ng-container>
                                    </tbody>
                                </table>
                            </div>
                            <div class="mt-3">
                                <div class="d-flex align-items-center fw-bold">
                                    SoW List
                                </div>
                                <div>
                                    <table class="table table-bordered">
                                        <thead>
                                            <tr>
                                                <th>
                                                    #
                                                </th>
                                                <th>
                                                    SoW
                                                </th>
                                                <th>
                                                    UoM
                                                </th>
                                                <th class="text-end">
                                                    Planned Qty
                                                </th>
                                                <th class="text-end">
                                                    Actual Qty
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr *ngFor="let sow of data.sows;index as i;">
                                                <td>{{i+1}}</td>
                                                <td>
                                                    {{sow.sow?.vendor_boq?.sow_name}}
                                                </td>
                                                <td>
                                                    {{sow.sow?.vendor_boq?.uom}}
                                                </td>
                                                <td class="text-end">
                                                    {{sow.qty | number}}
                                                </td>
                                                <td class="text-end">
                                                    {{sow?.total_qty_approved | number}}
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="rejectRemarks()" class="row">
                        <div class="col-12">
                            <div class="alert alert-danger mb-4">
                                <div class="fw-bold">Rejection remarks :</div>
                                <div>{{rejectRemarks()}}</div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div *ngIf="data.need_approval" class="col-12 text-end">
                            <div *ngIf="myApproval().level_name=='approval'">
                                <button class="btn btn-danger me-2" (click)="rejectDialog.show()" [disabled]="loading.status">
                                    &nbsp;&nbsp;<span class="material-icons">gpp_bad</span>&nbsp;Reject&nbsp;&nbsp;
                                </button>
                                <button class="btn btn-primary" (click)="approveDialog.show();" [disabled]="loading.status">
                                    &nbsp;&nbsp;<span class="material-icons">verified_user</span>&nbsp;Approve&nbsp;&nbsp;
                                </button>
                            </div>
                        </div>
                        <div *ngIf="data.status==1" class="col-12 text-end">
                            <button class="btn btn-info me-2" (click)="downloadCrt()" [disabled]="loading.status">
                                &nbsp;&nbsp;<span class="material-icons">file_download</span>&nbsp;Download Cerificate&nbsp;&nbsp;
                            </button>
                        </div>
                    </div>

                    <div *ngIf="data.status>-2" class="row mt-3">
                        <div class="col-12 small border-top pt-2">
                            <em class="fw-bold">Approval History</em>
                            <div class="table-responsive">
                                <table class="table table-bordered">
                                    <thead>
                                        <tr>
                                            <th>#</th>
                                            <th>Role</th>
                                            <th>Status</th>
                                            <th>User</th>
                                            <th>Timestamp</th>
                                            <th>Remarks</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>1</td>
                                            <td>
                                                {{data.creator?.role?.name || '-'}}
                                            </td>
                                            <td>
                                                Created
                                            </td>
                                            <td class="text-capitalize">
                                                {{data.creator?.name || '-'}}
                                            </td>
                                            <td class="text-capitalize">
                                                {{(data.created_at | dateFormat:'DD MMM YYYY, HH:mm') || '-'}}
                                            </td>
                                            <td class="text-capitalize">
                                                -
                                            </td>
                                        </tr>
                                        <tr *ngFor="let app of data.approvals | filter:'status':1:'status':-1;index as i;">
                                            <td>{{i+2}}</td>
                                            <td>
                                                {{app.role?.name || '-'}}
                                            </td>
                                            <td>
                                                <span *ngIf="app.status==1">
                                                    {{app.level_name=='approval'?'Approved':'Allocated'}}
                                                </span>
                                                <span *ngIf="app.status==-1">
                                                    Rejected
                                                </span>
                                                <span *ngIf="app.status==0">
                                                    Waiting
                                                </span>
                                            </td>
                                            <td class="text-capitalize">
                                                {{app.updater?.name || '-'}}
                                            </td>
                                            <td class="text-capitalize">
                                                {{(app.updated_at | dateFormat:'DD MMM YYYY, HH:mm') || '-'}}
                                            </td>
                                            <td class="text-capitalize">
                                                {{app.remarks || '-'}}
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<ng-template #approveModal let-modal>
    <div class="modal-header">
        <h5 class="modal-title">Approve</h5>
    </div>
    <div class="modal-body">Are you sure want to <strong>Approve</strong> this data?</div>
    <div class="modal-footer d-flex justify-content-between">
        <div>
            <div class="spinner-border text-primary" role="status" *ngIf="loading.status">
                <span class="visually-hidden">Loading...</span>
            </div>
        </div>
        <div class="text-end">
            <button type="button" class="btn btn-secondary" (click)="modal.close()" [attr.disabled]="loading.status ? 'disabled' : null">Cancel</button>
            &nbsp;
            <button type="button" class="btn btn-primary" (click)="approveDialog.submit()" [attr.disabled]="loading.status ? 'disabled' : null">Approve</button>
        </div>
    </div>
</ng-template>

<ng-template #rejectModal let-modal>
    <div class="modal-header">
        <h5 class="modal-title">Reject</h5>
    </div>
    <div class="modal-body">
        <div>Are you sure want to <strong>Reject</strong> this data?</div>
        <div class="form-floating mt-3">
            <textarea [(ngModel)]="rejectDialog.remarks" placeholder="..." class="form-control" required></textarea>
            <label>Please type remarks</label>
        </div>
    </div>
    <div class="modal-footer d-flex justify-content-between">
        <div>
            <div class="spinner-border text-primary" role="status" *ngIf="loading.status">
                <span class="visually-hidden">Loading...</span>
            </div>
        </div>
        <div class="text-end">
            <button type="button" class="btn btn-secondary" (click)="modal.close()" [attr.disabled]="loading.status ? 'disabled' : null">Cancel</button>
            &nbsp;
            <button type="button" class="btn btn-danger" (click)="rejectDialog.submit()" [attr.disabled]="loading.status || !rejectDialog.remarks ? 'disabled' : null">Reject</button>
        </div>
    </div>
</ng-template>
