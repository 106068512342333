import { Location } from '@angular/common';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, NgForm } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { DatePickerParserService } from 'src/app/services/date-picker-parser.service';
import { FileHandlerService } from 'src/app/services/file-handler.service';
import { HttpService } from 'src/app/services/http.service';
import { LoadingService } from 'src/app/services/loading.service';

@Component({
    selector: 'app-contract-add',
    templateUrl: './contract-add.component.html',
    styleUrls: ['./contract-add.component.scss']
})
export class ContractAddComponent implements OnInit {
    constructor(
        private http: HttpService,
        private el: ElementRef,
        private location: Location,
        private dateParse: DatePickerParserService,
        public fileHandler: FileHandlerService,
        public loading: LoadingService,
        private toastr: ToastrService
    ) { }
    @ViewChild('theForm') theForm: NgForm | undefined;
    private apiPath = 'contracts';
    stayPage: boolean = false;
    formGroup = new FormGroup({
        customer_id: new FormControl(),
        project_name: new FormControl(),
        project_code: new FormControl(),
        contract_number: new FormControl(),
        contract_date: new FormControl(),
        start_date: new FormControl(),
        end_date: new FormControl(),
        status: new FormControl(1),
        documents: new FormControl()
    });
    formChanged = false;
    customerList: any = [];

    submitForm(form: any): void {

        if (!form.valid) {
            this.el.nativeElement.querySelectorAll('[formcontrolname].ng-invalid')?.[0]?.focus();
            return;
        }
        form.value.status = Number(form.value.status);
        form.value.contract_date = this.dateParse.toYMD(form.value.contract_date);
        if (form.value.start_date) {
            form.value.start_date = this.dateParse.toYMD(form.value.start_date);
        }
        if (form.value.end_date) {
            form.value.end_date = this.dateParse.toYMD(form.value.end_date);
        }
        let fdata = new FormData();
        if (form.value?.documents?.length) {
            form.value?.documents?.filter((i: any) => i.file?.file).forEach((i: any) => {
                fdata.append('files[]', i.file.file);
            });
            form.value.documents = form.value?.documents?.map((i: any) => {
                return { name: i.name };
            });
        }
        fdata.append('data', JSON.stringify(form.value));
        form.disable();
        this.http.Post(this.apiPath + '/create', fdata).then((r: any) => {
            form.enable();
            if (r.success) {
                this.toastr.success('Data saved successfully', 'Success');
                this.back();
            } else {
                if (r.response && r.response.wrong) {
                    Object.keys(r.response.wrong).forEach((key) => {
                        if (key != 'id') {
                            form.get(key)?.setErrors({ serverError: r.response.wrong[key][0] });
                            this.el.nativeElement.querySelectorAll('[formcontrolname="' + key + '"]')?.[0]?.focus();
                        }
                    });
                }
            }
        });
    }

    back(): void {
        this.location.back();
    }

    documents = {
        get: () => {
            return this.formGroup.get('documents')?.value as Array<any>
        },
        add: () => {
            const val = this.documents.get() || [];
            val.push({ name: null, file: {} });
            this.formGroup.get('documents')?.setValue(val);
        },
        remove: (i: number) => {
            const val = this.documents.get() || [];
            val.splice(i, 1);
            this.formGroup.get('documents')?.setValue(val);
        },
        browseFile: (i: number) => {
            document.getElementById(`file-input-${i}`)?.click();
        }
    };

    async getCustomer(): Promise<void> {
        const r = await this.http.Get('customers', { filter: { status: 1 }, forceView: true });
        this.customerList = r.response?.result?.data || [];
    }

    ngOnInit(): void {
        const oldValue = this.formGroup.value;
        this.formGroup.valueChanges.subscribe((e: any) => {
            this.formChanged = (oldValue != e);
        });
        this.getCustomer();
    }

}
