<div class="row">
    <div class="col-12">
        <div class="card">
            <div class="card-body">
                <div class="d-flex justify-content-between row">
                    <div class="row pe-0">
                        <div class="d-flex flex-nowrap align-items-center col-md-6 mb-3">
                            <button class="btn btn-secondary btn-sm me-3" (click)="back()" title="Kembali">
                                <i class="material-icons">arrow_back</i>
                            </button>
                            <h4 class="card-title col-12 lh-0 m-0">Detail PO Supplier</h4>
                        </div>
                    </div>
                    <div *ngIf="data.id && data.approvals?.length" class="d-flex justify-content-center col-12">
                        <div class="text-center d-flex justify-content-center flex-wrap step-container active">
                            <div style="width: 32px; height: 32px" class="bg-gray rounded-circle text-white d-flex justify-content-center align-items-center icon-step">
                                <span class="material-icons">verified_user</span>
                            </div>
                            <div class="w-100 text-center small">
                                <div class="text-muted">
                                    Created
                                </div>
                                <div class="fw-bold">
                                    {{data.creator?.role?.name || 'Unknown'}}
                                </div>
                            </div>
                        </div>
                        <div *ngFor="let app of data.approvals;" [class.reject]="app.status==-1" [class.active]="app.status==1" class="text-center d-flex justify-content-center flex-wrap step-container">
                            <div style="width: 32px; height: 32px" class="bg-gray rounded-circle text-white d-flex justify-content-center align-items-center icon-step">
                                <span *ngIf="app.status==0" class="material-icons">
                                    hourglass_top
                                </span>
                                <span *ngIf="app.status==1" class="material-icons">
                                    verified_user
                                </span>
                                <span *ngIf="app.status==-1" class="material-icons">
                                    gpp_bad
                                </span>
                            </div>
                            <div class="w-100 text-center small">
                                <div class="text-muted">
                                    <span *ngIf="app.status==1">
                                        {{app.level_name=='approval' ? 'Approved' : 'Allocated'}}
                                    </span>
                                    <span *ngIf="app.status==-1">
                                        Rejected
                                    </span>
                                    <span *ngIf="app.status==0">
                                        Waiting {{app.level_name || 'Unknown'}}
                                    </span>
                                </div>
                                <div class="fw-bold">
                                    {{app?.role?.name || 'Unknown'}}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div *ngIf="!data.id" class="text-center">
                    <div class="spinner-grow" role="status">
                        <span class="visually-hidden">Loading...</span>
                    </div>
                </div>

                <div *ngIf="data.id" class="mt-4">
                    <div class="row">
                        <div class="col-12">
                            <div class="row">
                                <div class="col-md-6">
                                    <div class="form-floating mb-4">
                                        <input type="text" disabled [value]="data.number+(data.revision>0?' Rev. '+data.revision:'')" placeholder="..." class="form-control">
                                        <label>PO Number</label>
                                    </div>
                                </div>
                                <div [class.col-md-3]="data.parent_id" [class.col-md-6]="!data.parent_id">
                                    <div class="form-floating mb-4">
                                        <input disabled type="text" [value]="data.requested_at | dateFormat:'DD MMM YYYY'" placeholder="..." class="form-control">
                                        <label>PO Date</label>
                                    </div>
                                </div>
                                <div [class.col-md-3]="data.parent_id" [class.col-md-6]="!data.parent_id">
                                    <div class="form-floating mb-4">
                                        <input disabled [value]="data.is_additional ? data.po_additional?.number : data.pr_vendor?.number" placeholder="..." class="form-control">
                                        <label>PR Number</label>
                                    </div>
                                </div>
                                <div class="col-6">
                                    <div class="form-floating mb-4">
                                        <input disabled [value]="data.is_additional ? data.po_additional?.purchase_order?.number : data.pr_vendor?.purchase_order?.number" placeholder="..." class="form-control">
                                        <label>PO Customer</label>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-floating mb-4">
                                        <input disabled [value]="data.is_additional ? data.po_additional?.contract?.project_name : data.pr_vendor?.contract?.project_name" placeholder="..." class="form-control">
                                        <label>Project Name</label>
                                    </div>
                                </div>
                                <div *ngIf="data.parent_id" class="col-md-6">
                                    <div class="form-floating mb-4">
                                        <input type="text" disabled [value]="data.parent?.vendor?.name" placeholder="..." class="form-control">
                                        <label>Old Vendor</label>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-floating mb-4">
                                        <input type="text" disabled [value]="data.vendor?.name" placeholder="..." class="form-control">
                                        <label>{{data.parent_id?'New':''}} Vendor</label>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-floating mb-4">
                                        <input type="text" disabled [value]="data.deduction | number" placeholder="..." class="form-control">
                                        <label>Deduction</label>
                                    </div>
                                </div>
                                <div class="col-md-12">
                                    <div class="form-floating mb-4">
                                        <textarea disabled [value]="data.notes" placeholder="..." class="form-control textareabox"></textarea>
                                        <label>Notes</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12">
                            <div>
                                <table class="table table-bordered">
                                    <thead>
                                        <tr>
                                            <th>
                                                #
                                            </th>
                                            <th>
                                                SoW
                                            </th>
                                            <th class="text-end">
                                                Planned Qty
                                            </th>
                                            <th class="text-end">
                                                Actual Qty
                                            </th>
                                            <th>
                                                UoM
                                            </th>
                                            <th class="text-end">
                                                Price
                                            </th>
                                            <th class="text-end">
                                                Total
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let item of data.details;index as i">
                                            <td>
                                                {{i+1}}
                                            </td>
                                            <td>
                                                {{item.vendor_boq?.sow_name}}
                                            </td>
                                            <td class="text-end">
                                                {{item.qty | number}}
                                            </td>
                                            <td class="text-end">
                                                {{item.actual_qty | number}}
                                            </td>
                                            <td>
                                                {{item.vendor_boq?.uom}}
                                            </td>
                                            <td class="text-end">
                                                {{item.price | number}}
                                            </td>
                                            <td class="text-end">
                                                {{(item.total | number)}}
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="rejectRemarks()" class="row">
                        <div class="col-12">
                            <div class="alert alert-danger mb-4">
                                <div class="fw-bold">Rejection remarks :</div>
                                <div>{{rejectRemarks()}}</div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div *ngIf="data.need_approval" class="col-12 text-end">
                            <div *ngIf="myApproval().level_name=='approval'">
                                <button class="btn btn-danger me-2" (click)="rejectDialog.show()" [disabled]="loading.status">
                                    &nbsp;&nbsp;<span class="material-icons">gpp_bad</span>&nbsp;Reject&nbsp;&nbsp;
                                </button>
                                <button class="btn btn-primary" (click)="approveDialog.show();" [disabled]="loading.status">
                                    &nbsp;&nbsp;<span class="material-icons">verified_user</span>&nbsp;Approve&nbsp;&nbsp;
                                </button>
                            </div>
                        </div>
                        <div *ngIf="data.status==1" class="col-12 text-end">
                            <button class="btn btn-primary me-2" [disabled]="loading.status" [useExistingCss]="true" printSectionId="print-section" ngxPrint>
                                &nbsp;&nbsp;<span class="material-icons">print</span>&nbsp;Print&nbsp;&nbsp;
                            </button>
                        </div>
                    </div>

                    <div *ngIf="data.approvals?.length" class="row mt-3">
                        <div class="col-12 small border-top pt-2">
                            <em class="fw-bold">Approval History</em>
                            <div class="table-responsive">
                                <table class="table table-bordered">
                                    <thead>
                                        <tr>
                                            <th>#</th>
                                            <th>Role</th>
                                            <th>Status</th>
                                            <th>User</th>
                                            <th>Timestamp</th>
                                            <th>Remarks</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>1</td>
                                            <td>
                                                {{data.creator?.role?.name || '-'}}
                                            </td>
                                            <td>
                                                Created
                                            </td>
                                            <td class="text-capitalize">
                                                {{data.creator?.name || '-'}}
                                            </td>
                                            <td class="text-capitalize">
                                                {{(data.created_at | dateFormat:'DD MMM YYYY, HH:mm') || '-'}}
                                            </td>
                                            <td class="text-capitalize">
                                                -
                                            </td>
                                        </tr>
                                        <tr *ngFor="let app of data.approvals | filter:'status':1:'status':-1;index as i;">
                                            <td>{{i+2}}</td>
                                            <td>
                                                {{app.role?.name || '-'}}
                                            </td>
                                            <td>
                                                <span *ngIf="app.status==1">
                                                    Approved
                                                </span>
                                                <span *ngIf="app.status==-1">
                                                    Rejected
                                                </span>
                                                <span *ngIf="app.status==0">
                                                    Waiting
                                                </span>
                                            </td>
                                            <td class="text-capitalize">
                                                {{app.updater?.name || '-'}}
                                            </td>
                                            <td class="text-capitalize">
                                                {{(app.updated_at | dateFormat:'DD MMM YYYY, HH:mm') || '-'}}
                                            </td>
                                            <td class="text-capitalize">
                                                {{app.remarks || '-'}}
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<ng-template #approveModal let-modal>
    <div class="modal-header">
        <h5 class="modal-title">Approve</h5>
    </div>
    <div class="modal-body">Are you sure want to <strong>Approve</strong> this data?</div>
    <div class="modal-footer d-flex justify-content-between">
        <div>
            <div class="spinner-border text-primary" role="status" *ngIf="loading.status">
                <span class="visually-hidden">Loading...</span>
            </div>
        </div>
        <div class="text-end">
            <button type="button" class="btn btn-secondary" (click)="modal.close()" [attr.disabled]="loading.status ? 'disabled' : null">Cancel</button>
            &nbsp;
            <button type="button" class="btn btn-primary" (click)="approveDialog.submit()" [attr.disabled]="loading.status ? 'disabled' : null">Approve</button>
        </div>
    </div>
</ng-template>

<ng-template #rejectModal let-modal>
    <div class="modal-header">
        <h5 class="modal-title">Reject</h5>
    </div>
    <div class="modal-body">
        <div>Are you sure want to <strong>Reject</strong> this data?</div>
        <div class="form-floating mt-3">
            <textarea [(ngModel)]="rejectDialog.remarks" placeholder="..." class="form-control" required></textarea>
            <label>Please type remarks</label>
        </div>
    </div>
    <div class="modal-footer d-flex justify-content-between">
        <div>
            <div class="spinner-border text-primary" role="status" *ngIf="loading.status">
                <span class="visually-hidden">Loading...</span>
            </div>
        </div>
        <div class="text-end">
            <button type="button" class="btn btn-secondary" (click)="modal.close()" [attr.disabled]="loading.status ? 'disabled' : null">Cancel</button>
            &nbsp;
            <button type="button" class="btn btn-danger" (click)="rejectDialog.submit()" [attr.disabled]="loading.status || !rejectDialog.remarks ? 'disabled' : null">Reject</button>
        </div>
    </div>
</ng-template>

<div class="d-none">
    <div *ngIf="data.id && data.status==1" id="print-section" class="row bg-light">
        <div class="col-12 text-center mb-3 d-flex position-relative justify-content-between">
            <div>
                <div class="position-absolute top-0 start-0">
                    <img src="/assets/logo.png" style="width:150px;height:auto;">
                </div>
            </div>
            <div class="text-end">
                <strong>PT Surya Rahayu Perdana</strong><br>
                Jl. Agus Salim No 60, Menteng, Jakarta Pusat<br>
                DKI Jakarta, Indonesia, 10340<br>
                021-83708877 info@suryarahayu.com
            </div>
        </div>
        <div class="col-12">
            <table class="table table-borderless mb-1">
                <tr>
                    <td>
                        <h3 class="lh-0 m-0 p-0">PURCHASE ORDER</h3>
                    </td>
                    <td class="text-end small">
                        <em>Printed by : {{''|me}}</em>
                    </td>
                </tr>
            </table>
        </div>
        <div class="col-12 mb-3 border-top">
            <div class="row">
                <div class="col-6">
                    <table class="table table-borderless mb-0" cellpadding="2">
                        <tr>
                            <td class="fw-bold align-top">To</td>
                            <td class="align-top">:</td>
                            <td class="align-top">
                                {{data.vendor?.name}}
                            </td>
                        </tr>
                        <tr>
                            <td class="fw-bold align-top" nowrap>Address</td>
                            <td class="align-top">:</td>
                            <td class="align-top">
                                {{data.vendor?.address}}
                            </td>
                        </tr>
                    </table>
                </div>
                <div class="col-6">
                    <table class="table table-borderless" cellpadding="2">
                        <tr>
                            <td class="fw-bold align-top text-nowrap">PO Number</td>
                            <td class="align-top">:</td>
                            <td class="align-top text-nowrap">
                                {{data.number}} {{data.revision>0?'Rev. '+data.revision:''}}
                            </td>
                        </tr>
                        <tr>
                            <td class="fw-bold align-top text-nowrap" nowrap>PO Date</td>
                            <td class="align-top">:</td>
                            <td class="align-top text-nowrap">
                                {{data.requested_at | dateFormat:'DD MMM YYYY'}}
                            </td>
                        </tr>
                    </table>
                </div>
            </div>
        </div>
        <div class="col-12 mb-3">
            <table class="table table-bordered">
                <thead>
                    <tr>
                        <th>
                            #
                        </th>
                        <th>
                            SoW
                        </th>
                        <th>
                            UoM
                        </th>
                        <th class="text-end">
                            Planned<br>Quantity
                        </th>
                        <th class="text-end">
                            Actual<br>Quantity
                        </th>
                        <th class="text-end">
                            Unit Price<br>(IDR)
                        </th>
                        <th class="text-end">
                            Amount<br>(IDR)
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <ng-container *ngFor="let item of data.details;index as i">
                        <tr>
                            <td>
                                {{i+1}}
                            </td>
                            <td>
                                {{item.vendor_boq?.sow_name}}
                            </td>
                            <td>
                                {{item.vendor_boq?.uom}}
                            </td>
                            <td class="text-end">
                                {{item.qty | number}}
                            </td>
                            <td class="text-end">
                                {{item.actual_qty | number}}
                            </td>
                            <td class="text-end">
                                {{item.price | number}}
                            </td>
                            <td class="text-end">
                                {{item.total | number}}
                            </td>
                        </tr>
                    </ng-container>
                    <tr>
                        <td colspan="5" rowspan="3">
                            {{data.notes}}
                        </td>
                        <td class="fw-bold text-end text-nowrap">Subtotal (IDR)</td>
                        <td class="text-end text-nowrap">{{data.details_sum_total | number}}</td>
                    </tr>
                    <tr>
                        <td class="fw-bold text-end text-nowrap">Deduction (IDR)</td>
                        <td class="text-end text-nowrap">{{(data.deduction || '0') | number}}</td>
                    </tr>
                    <tr>
                        <td class="fw-bold text-end text-nowrap">Total Amount (IDR)</td>
                        <td class="text-end fw-bold text-nowrap">{{(data.details_sum_total - data.deduction) | number}}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>
